import {Injectable} from '@angular/core';
import {EventInfo, eventInfoWithVariant, VenueType} from '../../models/event-info';
import {cobrands} from '../../../assets/ts/cobrand';
import {Four51Service} from '../../four51.service';
import {HttpClient} from '@angular/common/http';
import {fontStyles} from '../../../assets/ts/font-styles';

@Injectable({
  providedIn: 'root'
})
export class EmailInvitationService {

  constructor(private four51Service: Four51Service, private http: HttpClient) {}

  async refresh(variantToUpdate: any, eventInfo: EventInfo) {
    const data = {
      topicId: eventInfo.topicId,
      byoSelectedTopicId: eventInfo.byoSelectedTopicId,
      sessions: eventInfo.sessions,
      rsvpOnline: eventInfo.rsvpOnline,
      type: eventInfo.type
    };
    const variant: any = {
      ProductInteropID: 'MDVIP-InvitationEmail',
      Specs: {
        Address: {
          Value: eventInfo.Address
        },
        Address2: {
          Value: eventInfo.Address2
        },
        City: {
          Value: eventInfo.City
        },
        event_date_text: {
          Value: eventInfo.eventDateText
        },
        facebook: {
          Value: eventInfo.facebook
        },
        fullName: {
          Value: eventInfo.fullName
        },
        header_image: {
          Value: eventInfo.header_image
        },
        intro: {
          Value: eventInfo.intro
        },
        LastName: {
          Value: eventInfo.LastName
        },
        long_description_html: {
          Value: eventInfo.long_description_html
        },
        patient_guests: {
          Value: eventInfo.patient_guests
        },
        Phone: {
          Value: eventInfo.Phone
        },
        rsvp_message: {
          Value: eventInfo.rsvp_message
        },
        rsvp_phone: {
          Value: eventInfo.rsvp_phone
        },
        rsvpOnline: {
          Value: eventInfo.rsvpOnline
        },
        speaker: {
          Value: eventInfo.speaker
        },
        Specialty: {
          Value: eventInfo.Specialty
        },
        State: {
          Value: eventInfo.State
        },
        twitter: {
          Value: eventInfo.twitter
        },
        venue_address_1: {
          Value: eventInfo.venue_address_1
        },
        venue_address_2: {
          Value: eventInfo.venue_address_2
        },
        venue_city: {
          Value: eventInfo.venue_city
        },
        venue_name: {
          Value: eventInfo.venue_name
        },
        venue_state: {
          Value: eventInfo.venue_state
        },
        venue_zip: {
          Value: eventInfo.venue_zip
        },
        Website: {
          Value: eventInfo.Website
        },
        Zip: {
          Value: eventInfo.Zip
        },
        venueType: {
          Value: (eventInfo.venueType) 
        },
        webinarUrl: {
          Value: eventInfo.webinarUrl
        },
        additionalInformation: {
          Value: eventInfo.additionalInformation
        },
        rsvpByDate: {
          Value: eventInfo.rsvpByDate
        },
        data: {
          Value: JSON.stringify(data)
        }
      }
    };
    if (variantToUpdate) {
      variant.InteropID = variantToUpdate.InteropID;
    }
    const response = await this.four51Service.createVariant(variant).toPromise();
    return response.body;
  }

  async getHtml(variant, eventInfo?: EventInfo) {
    if (!eventInfo) {
      eventInfo = eventInfoWithVariant(variant);
    }
    let emailFile;
    let isYochTopic = false;
    switch (eventInfo.type) {
      case 'yoch': {
        emailFile = 'email-yoch.html';
        isYochTopic = true;
        break;
      }
      default: {
        emailFile = 'email.html';
        break;
      }
    }
    const htmlEmailTemplate = await this.http.get('./assets/html/' + emailFile, {responseType: 'text'}).toPromise();
    let htmlEmail = htmlEmailTemplate;
    htmlEmail = htmlEmail.replace(/\[\[fullName\]\]/g, eventInfo.fullName);
    // copy fitting
    let intro_size = 36;
    let intro = eventInfo.intro;
    const introText = intro.replace(/<[^>]*>/g, '');
    if (introText.length > 130) {
      // 24 characters = 6 px
      const charsOver = introText.length - 130;
      const px = Math.ceil(charsOver / 4);
      intro_size = 36 - px;
    }
    intro = intro.replace(/(:\d\d)(\s)(AM|PM)/g, '$1&nbsp;$3');
    intro = intro.replace(/(January|February|March|April|May|June|July|August|September|October|November|December)(\s)(\d)/g,
      '$1&nbsp;$3');
    intro = intro.replace(/(\r\n|\n|\r)/g, '<br />');
    console.log(intro);
    htmlEmail = htmlEmail.replace('[[intro_size]]', intro_size.toString());
    htmlEmail = htmlEmail.replace('[[intro]]', intro);
    htmlEmail = htmlEmail.replace('[[header_image]]', 'https://info.mdvip.com/rs/002-CTP-164/images/' +
      eventInfo.header_image);
    htmlEmail = htmlEmail.replace('[[long_description]]', eventInfo.long_description_html
      .replace(/(<p>\[Column Break\]<\/p>|\[Column Break\])/g, '')
      .replace(/<p>(<span[^>]*>)?(&nbsp;|<br>)(<\/span>)?<\/p>/g, ''));
    for (const fontStyle of fontStyles) {
      const classRegExp = new RegExp('class="' + fontStyle.class + '"', 'g');
      let styleString = 'style="';
      if (fontStyle.colorName) {
        styleString += 'color: ' + fontStyle.color + '; ';
      }
      styleString += 'font-weight: ' + fontStyle.fontWeight + '; ';
      styleString += 'font-style: ' + fontStyle.fontStyle + '; "';
      htmlEmail = htmlEmail.replace(classRegExp, styleString);
    }

    const pOpen = '<tr><td align="left" style="font-size:0px;padding:0;word-break:break-word;">' +
      '<div style="font-family:\'Helvetica Neue\',Helvetica,Arial,sans-serif;font-size:13px;' +
      'line-height:1.625;text-align:left;color:#444444;">';
    const pOpenBold = '<tr><td align="left" style="font-size:0px;padding:0;word-break:break-word;">' +
      '<div style="font-family:\'Helvetica Neue\',Helvetica,Arial,sans-serif;font-size:15px;font-weight:bold;' +
      'line-height:1.4;text-align:left;color:#444444;">';
    const pClose = '</div></td></tr>';
    let closing = '';
    if (eventInfo.speaker && eventInfo.speaker.length > 0) {
      closing += pOpen + '<span style="font-weight:bold;line-height: 1em;">Speaker:</span> ' + eventInfo.speaker +
        '<br />&nbsp;' + pClose;
    }
    closing += pOpen + 'Healthy regards,' + pClose;
    closing += pOpenBold + eventInfo.fullName + pClose;
    closing += pOpen + eventInfo.Specialty + pClose;
    // console.log('finding cobrand for: ' + eventInfo.physicianId);
    const cobrandFilter = cobrands.filter(x => x.physicianId === eventInfo.physicianId);
    let cobrand;
    if (cobrandFilter.length > 0) {
      closing += pOpen;
      cobrand = cobrandFilter[0];
      switch (cobrand.cobrand) {
        case 'Heritage Medical Associates':
          closing += '<img src="http://info.mdvip.com/rs/002-CTP-164/images/heritage-logo_green_eblast.png" ' +
            'alt="Heritage Medical Associates" />';
          break;
        case 'Mountain View Medical Group, P.C.':
          closing += '<img src="http://info.mdvip.com/rs/002-CTP-164/images/MountainView_cobrand-text_email.png" ' +
            'alt="Mountain View Medical Group, P.C." />';
          break;
        case 'Nashville Medical Group, An Affiliate of Baptist Hospital':
          closing += '<img src="http://info.mdvip.com/rs/002-CTP-164/images/NashvilleMedicalGroup_logo_eblast.png" ' +
            'alt=Nashville Medical Group, An Affiliate of Baptist Hospital" />';
          break;
        case 'North Shore Physicians Group':
          closing += '<img src="http://info.mdvip.com/rs/002-CTP-164/images/NSPG-Logo_rgb.png" ' +
            'alt="North Shore Physicians Group" />';
          break;
        case 'Piedmont Physicians':
          closing += '<img src="http://info.mdvip.com/rs/002-CTP-164/images/Piedmont_cobrand-text_eblast.png" ' +
            'alt="Piedmont Physicians" />';
          break;
        default:
          closing += '<i>' + cobrand.cobrand + '</i>';
          break;
      }
      closing += pClose;
    }

    htmlEmail = htmlEmail.replace('[[closing]]', closing);
    htmlEmail = htmlEmail.replace('[[event_date_text]]', eventInfo.eventDateText.replace(/\n/g,  '<br>'));

    let address = '';
    let color;
    if (isYochTopic) {
      color = '#B60027';
    } else {
      color = '#DE6400'; // #B60027
    }

    const openTagsText = '<tr><td align="left" class="center-on-mobile pl-25-mobile" ' +
      'style="font-size:0px;padding:10px 25px;padding-left:15px;word-break:break-word;">' +
      '<div style="font-family:\'Helvetica Neue\',Helvetica,Arial,sans-serif;font-size:13px;font-weight:400;' +
      'line-height:1.615;text-align:left;color:#444444;">';
    const openTagsUrl = '<tr><td align="left" class="center-on-mobile pl-25-mobile long-url" ' +
      'style="font-size:0px;padding:10px 25px;padding-left:15px;word-break:break-word;">' +
      '<div style="word-break: break-all; font-family: \'Helvetica Neue\',Helvetica,Arial,sans-serif; font-size: 13px;' +
      'font-weight: bold; line-height: normal; text-align: left; color: ' + color + ';">';
    const closeTags = '</div></td></tr>';

    switch (eventInfo.venueType) {
      case VenueType.Physical: {
        address += openTagsText;
        if (eventInfo.venue_name.length > 0) {
          address += eventInfo.venue_name + '<br />';
        }
        address += eventInfo.venue_address_1 + '<br />';
        if (eventInfo.venue_address_2.length > 0) {
          address += eventInfo.venue_address_2 + '<br />';
        }
        address += eventInfo.venue_city +
          ((eventInfo.venue_city && eventInfo.venue_state) ? ', ' : '') +
          eventInfo.venue_state +
          ((eventInfo.venue_city || eventInfo.venue_state) ? ' ' : '') +
          eventInfo.venue_zip;
        address += closeTags;
        break;
      }
      case VenueType.Virtual: {
        if (eventInfo.venue_name.length > 0) {
          address += openTagsText;
          address += eventInfo.venue_name;
          address += closeTags;
        }
        address += openTagsUrl;
        address += '<a class="orange-link" href="' + eventInfo.webinarUrl + '" style="color: ' + color + ';">';
        address += eventInfo.webinarUrl;
        address += '</a>';
        address += closeTags;
        if (eventInfo.additionalInformation.length > 0) {
          address += openTagsText;
          address += eventInfo.additionalInformation.replace(/\n/g,  '<br>');
          address += closeTags;
        }
        break;
      }
      case VenueType.Combined: {
        address += openTagsText;
        address += 'Join us in person or online';
        address += closeTags;
        address += openTagsText;
        address += '<span style="color: #DE6400; font-weight: bold; text-transform: uppercase;">In Person:</span><br>';
        if (eventInfo.venue_name.length > 0) {
          address += eventInfo.venue_name + '<br />';
        }
        address += eventInfo.venue_address_1 + '<br />';
        if (eventInfo.venue_address_2.length > 0) {
          address += eventInfo.venue_address_2 + '<br />';
        }
        address += eventInfo.venue_city +
          ((eventInfo.venue_city && eventInfo.venue_state) ? ', ' : '') +
          eventInfo.venue_state +
          ((eventInfo.venue_city || eventInfo.venue_state) ? ' ' : '') +
          eventInfo.venue_zip;
        address += closeTags;
        
        address += openTagsText;
        address += '<span style="font-weight: bold; color:#DE6400;">RSVP by calling ' + eventInfo.rsvp_phone + '</span>';
        address += closeTags;

        address += openTagsText;
        address += '<span style="color: #DE6400; font-weight: bold; text-transform: uppercase;">Online Webinar:</span><br />';
        address += '<a href="' + eventInfo.webinarUrl + '" style="color: #444444; text-decoration: none; font-weight: normal;">';
        address += eventInfo.webinarUrl;
        address += '</a>';
        address += closeTags;
        break;
      }
    }
    htmlEmail = htmlEmail.replace('[[address]]', address);

    let rsvp_by_date = '';
    if (eventInfo.rsvpByDate) {
      rsvp_by_date += openTagsText;
      rsvp_by_date += '<span style="color:#DE6400;">' + eventInfo.rsvpByDate + '</span>';
      rsvp_by_date += closeTags;
    }
    htmlEmail = htmlEmail.replace('[[rsvp_by_date]]', rsvp_by_date);

    let rsvp_phone;
    let rsvp;
    // let td_fwd_style = '';
    let ctaImage;
    let shareYourStoryImage;
    let learnMoreImage;
    if (isYochTopic) {
      ctaImage = 'RSVP-Online_red.png';
      shareYourStoryImage = 'share-your-story_red.png';
      learnMoreImage = 'Learn-More-Icon.png';
    } else {
      ctaImage = 'rsvp-CTA.png'; // RSVP-Online_red.png
      shareYourStoryImage = 'btn_ShareYourStory_300.png'; // share-your-story_red.png
      learnMoreImage = 'btn-learn-benefits-v2.png'; // Learn-More-Icon.png
    } 
    if (eventInfo.rsvpOnline && eventInfo.venueType !== VenueType.Virtual) {
      rsvp_phone = '<a href="http://info.mdvip.com/{{my.ProgramName}}_Registration.html?utm_campaign=ma-event&amp;utm_medium=email&amp;' +
        'ID={{lead.MDVIPID}}" target="_blank"><span style="color: ' + color + ';"><u>RSVP online</u></span></a> or call <font color="' +
        color + '">' + eventInfo.rsvp_phone + '</font>';
      rsvp = '<a href="http://info.mdvip.com/{{my.ProgramName}}_Registration.html?utm_campaign=ma-event&amp;utm_medium=email&amp;' +
        'ID={{lead.MDVIPID}}" target="_blank"><img height="auto" src="http://info.mdvip.com/rs/002-CTP-164/images/' + ctaImage + '" ' +
        'alt="RSVP" ' +
        'style="border:0;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;" ' +
        'width="300"></a>';
    } else {
      rsvp_phone = eventInfo.rsvp_message.replace(' received via email invite', '');
      const practiceStatus = eventInfo.practiceStatus;
      if (practiceStatus === 'Post-Open') {
        rsvp = '<a href="http://www.mdvip.com/share-your-story?id={{company.PhysicianID:default=}}" target="_blank">' +
          '<img height="auto" src="https://info.mdvip.com/rs/002-CTP-164/images/' + shareYourStoryImage + '" ' +
          'alt="Share Your Story" ' +
          'style="border:0;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;" ' +
          'width="300"></a>';
      } else {
        rsvp = '<a href="http://www.mdvip.com/what-is-mdvip/our-model" target="_blank" style="color:#664f3f;">' +
          '<img height="auto" src="https://info.mdvip.com/rs/002-CTP-164/images/' + learnMoreImage + '" ' +
          'alt="Learn more about MDVIP Benefits" ' +
          'style="border:0;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;" ' +
          'width="300"></a>';
        // td_fwd_style = 'height:142px; width:300px; ';
      }
    }
    if (eventInfo.venueType === VenueType.Combined) {
      rsvp_phone = '';
    } else {
      rsvp_phone = `<tr>
        <td align="left" class="center-on-mobile pl-25-mobile" style="font-size:0px;padding:10px 25px;padding-left:15px;word-break:break-word;">
          <div style="font-family:'Helvetica Neue',Helvetica,Arial,sans-serif;font-size:13px;font-weight:400;line-height:1.615;text-align:left;color:#DE6400;">${rsvp_phone}</div>
        </td>
      </tr>`;
    }
    htmlEmail = htmlEmail.replace('[[rsvp_phone]]', rsvp_phone);
    htmlEmail = htmlEmail.replace('[[rsvp]]', rsvp);
    // htmlEmail = htmlEmail.replace('[[td_fwd_style]]', td_fwd_style);

    const website_href = 'http://' + ((eventInfo.Website.startsWith('www.')) ? '' : 'www.') + eventInfo.Website;
    htmlEmail = htmlEmail.replace('[[website_href]]', website_href);
    htmlEmail = htmlEmail.replace('[[website]]', eventInfo.Website);
    htmlEmail = htmlEmail.replace('[[phone]]', eventInfo.Phone);
    htmlEmail = htmlEmail.replace('[[twitter]]', 'https://' + eventInfo.twitter);
    htmlEmail = htmlEmail.replace('[[facebook]]', 'https://' + eventInfo.facebook);
    let footer = '';
    if (cobrand) {
      footer += '<span style="color: #cbc3b6; font-style: italic; font-weight: bold;">' + cobrand.cobrand + '</span><br />';
    }
    footer += eventInfo.Address;
    if (eventInfo.Address2.length > 0) {
      footer += ', ' + eventInfo.Address2;
    }
    footer += '<br />';
    footer += eventInfo.City + ', ' + eventInfo.State + ' ' + eventInfo.Zip;
    footer += '<a href="http://www.mdvip.com/" target="_blank" style="color:#ffffff; font-weight:bold; text-decoration:none;"></a>';
    if (!cobrand) {
      footer += '<br />';
    }
    htmlEmail = htmlEmail.replace('[[footer]]', footer);

    let patient_guest_src;
    let patient_guest_alt;
    if (isYochTopic) {
      patient_guest_src = 'https://info.mdvip.com/rs/002-CTP-164/images/Red-Box_link-YOCH-1.jpg'
      patient_guest_alt = 'About MDVIP’s Year of Cardiovascular Health';
    } else if (eventInfo.patient_guests === 'guests.pdf') {
      patient_guest_src = 'https://info.mdvip.com/rs/002-CTP-164/images/guests.png';
      patient_guest_alt = 'Guests are welcome. We encourage you to invite your family and friends.';
    } else {
      patient_guest_src = 'https://info.mdvip.com/rs/002-CTP-164/images/patients-reserve.png';
      patient_guest_alt = 'Reserve your space today.';
    }
    htmlEmail = htmlEmail.replace('[[patient_guest_src]]', patient_guest_src);
    htmlEmail = htmlEmail.replace('[[patient_guest_alt]]', patient_guest_alt);
    return htmlEmail;
  }
}
