import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {SpecService} from './spec.service';

@Component({
  selector: 'app-spec',
  template: `
      <div class="spec-wrapper">
          <form #specForm="ngForm" name="specForm" id="specForm" (ngSubmit)="onSpecFormSubmit(specForm)" novalidate>
              <ng-container *ngFor="let specKeyValue of specs | keyvalue">
                  <app-spec-field [submitted]="specForm.submitted" [specKeyValue]="specKeyValue"></app-spec-field>
              </ng-container>
              <button type="submit" class="btn btn-primary"><i class="fa fa-refresh"></i> Apply/Refresh</button>
          </form>
      </div>
  `,
  styles: [`
  .spec-wrapper {
    height: 100%;
    overflow-y: scroll;
    padding: 1.25rem;
  }
  `],
})

export class SpecComponent implements OnInit {
  @Input() specs: any;
  @Input() isEditing: boolean;
  @Output() specsChange = new EventEmitter();

  @ViewChild('specForm', {static: false}) specForm: NgForm;

  constructor(private changeDetector: ChangeDetectorRef, private specService: SpecService) {}

  ngOnInit(): void {
    if (!this.isEditing) {
      this.specs = this.specService.fillInUserData(this.specs);
      this.changeDetector.detectChanges();
      this.specsChange.emit(this.specForm.valid);
    }
  }

  onSpecFormSubmit(specForm) {
    this.specsChange.emit(specForm.valid);
  }
}
