import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {NgbAccordion, NgbDate, NgbDateParserFormatter, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NgbDateCustomParserFormatter} from '../dateformat';
import {MaskPipe} from 'ngx-mask';
import {Four51Service} from '../four51.service';
import * as moment from 'moment-timezone';
import {states} from '../../assets/ts/states';
import {headerImages, HeaderImage} from '../../assets/ts/header-images';
import {EventInfo, VenueType} from '../models/event-info';
import {HttpClient} from '@angular/common/http';
import Quill from 'quill';
import {fontStyles} from '../../assets/ts/font-styles';


@Component({
  selector: 'app-event-info',
  templateUrl: './event-info.component.html',
  styleUrls: ['./event-info.component.scss'],
  providers: [
    {provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter},
    MaskPipe
  ]
})

export class EventInfoComponent implements OnInit {

  public get VenueType() {
    return VenueType; 
  }

  public _eventInfo: EventInfo;
  @Input()
  set eventInfo(eventInfo: EventInfo) {
    if (eventInfo) {
      this.initEventInfo(eventInfo);
    }
  }
  @Input() hiddenFields: string[];
  @Output() eventInfoChange = new EventEmitter();

  private physicianIdSearch: string;
  private shouldShowPhysicianSearch = false;
  private shouldShowPhysicianNotFound = false;
  private isProfileLoading = false;

  private topics: any;
  private byoTopics: any;

  private numberOfSessions = 1;
  // private eventDate: any;
  private shouldShowLeadTimeWarning = false;
  private shouldShowEventTimeWarning = false;

  private shouldFirePhoneChangeEvent = true;
  private states = states;

  private headerImages = headerImages;
  private selectedHeaderImage: HeaderImage;

  private introTemplate;
  private isIntroEditable;
  private editorInstance;
  private minDate: NgbDate;

  private nextStep: string;

  private disabledFields: string[];
  private topic: any = {editable_fields: []};
  private canSubmit = false;

  @ViewChild('validationModal', { static: true }) private validationModal;
  @ViewChild('acc', { static: false}) accordion: NgbAccordion;
  @ViewChild('eventInfoWrapper', {static: true}) private eventInfoWrapper;
  private validationErrors: string[];
  private isApprover = true;
  private displayedFonts: any[];
  private timeZone: string = moment.tz.guess();

  public rsvpByDateObj: NgbDate;

  public virtualRsvpMethod;

  constructor(private modalService: NgbModal, private four51Service: Four51Service, private maskPipe: MaskPipe,
              private http: HttpClient) {
    this.disabledFields = [];
  }

  ngOnInit(): void {
    if (this.isApprover) {
      this.displayedFonts = fontStyles;
    } else {
      this.displayedFonts = fontStyles.filter(x => !x.approverOnly);
    }
    const Font = Quill.import('formats/font');
    Font.whitelist = this.displayedFonts.map(x => x.class.replace('ql-font-', ''));
    Quill.register(Font, true);
    /*const FontAttributor = Quill.import('attributors/class/font');
    FontAttributor.whitelist = ['davidbowie']; // this.displayedFonts.map(x => x.class.replace('ql-font-', ''));
    Quill.register(FontAttributor, true);*/
  }

  async initEventInfo(eventInfo) {
    if (!this.topics) { // lazy load
      const res = await this.http.get('./assets/json/topics.json').toPromise();
      this.topics = res;
    }
    this.shouldFirePhoneChangeEvent = false;
    this._eventInfo = eventInfo;
    this.topic = this.topics.filter(x =>  x.id === eventInfo.topicId)[0];
    this.clearUndoHistory();
    if (this.topic.topics) {
      this.byoTopics = this.topics.filter( x => this.topic.topics.includes(x.id) );
    } else {
      this.byoTopics = null;
    }
    // const byoSelectedTopicId = this._eventInfo.byoSelectedTopicId
    if (this._eventInfo.byoSelectedTopicId === 0) {
      this.introTemplate = this.topic.intro;
    } else {
      const byoTopic = this.topics.filter(x =>  x.id === this._eventInfo.byoSelectedTopicId)[0];
      this.introTemplate = byoTopic.intro;
    }
    this.isIntroEditable = this.topic.editable_fields.includes('intro');
    this.selectedHeaderImage = this.headerImages.filter( x => x.file === this._eventInfo.header_image)[0];
    this.numberOfSessions = this._eventInfo.sessions.length;
    this.virtualRsvpMethod = (this._eventInfo.rsvp_message.includes(this._eventInfo.rsvp_phone)) ? 'phone' : 'url';

    const rsvpByDateString = this._eventInfo.rsvpByDate.replace('RSVP by ', '');
    if (rsvpByDateString) {
      let rsvpByDateMoment = moment(rsvpByDateString, 'dddd, MMMM D');
      if (!rsvpByDateMoment.isValid()) { // if date is in following year
        rsvpByDateMoment = moment(rsvpByDateString + ' ' + (moment().year() + 1), 'dddd, MMMM D YYYY');
      }
      this.rsvpByDateObj = new NgbDate(rsvpByDateMoment.year(), rsvpByDateMoment.month() + 1, rsvpByDateMoment.date());
    }
  }

  onEventInfoFormSubmit(f) {
    if (f.valid) {
      // send form submit event
    } else {
      this.validationErrors = Object.keys(f.controls)
        .filter(fieldName => f.controls[fieldName].errors)
        .map(fieldName => {
          fieldName = fieldName.replace( /([A-Z])/g, ' $1' );
          return fieldName.charAt(0).toUpperCase() + fieldName.slice(1);
        });
      this.modalService.open(this.validationModal);
    }
  }

  refresh() {
    this._eventInfo.intro = this.getIntro();
    this.eventInfoChange.emit(this._eventInfo);
  }

  panelChanged(e) {
    // send panel changed event to parent to scroll
    this.eventInfoWrapper.nativeElement.scrollTop = 0;
  }

  isHidden(fieldName): boolean {
    return this.hiddenFields.includes(fieldName);
  }

  isDisabled(fieldName): boolean {
    return this.disabledFields.includes(fieldName);
  }

  onEventDateSelect(e) {
    const eventDateMoment = moment(this.ngbDateToString(this._eventInfo.sessions[0].eventDate), 'YYYY-MM-DD');
    const leadTime = eventDateMoment.diff(moment(), 'days');
    this.shouldShowLeadTimeWarning = (leadTime < 14);
    this.updateEventDateText();
    if (this.isIntroEditable) {
      this.resetIntro();
    }
  }

  async getPhysicianProfle() {
    this.shouldShowPhysicianNotFound = false;
    this.isProfileLoading = true;
    const profile = await this.four51Service.getPhysicianProfile(this.physicianIdSearch)
      .toPromise();
    // console.log(profile);
    this.isProfileLoading = false;
    if (profile.Status) {
      // old version
      if (profile.Status !== 'Error') {
        const physicianDetails = profile.PhysicianDetails[0];
        const physicianId = physicianDetails.PhysicianID__c;
        const fullname = physicianDetails.Name;
        let address1 = null;
        let address2 = null;
        if (physicianDetails.BillingStreet) {
          const address = physicianDetails.BillingStreet.split('\r\n');
          address1 = address[0];
          if (address.length > 1) {
            address2 = address[1];
          }
        }
        let practiceStatus = null;
        if (physicianDetails.Practice_Status__c) {
          practiceStatus = physicianDetails.Practice_Status__c.toLowerCase().split(' ').map(word => {
            return word.charAt(0).toUpperCase() + word.slice(1);
          }).join('-');
        }
        let pdmEmail = null;
        let ptmEmail = null;
        if (profile.AccountTeam) {
          for (const accountTeam of profile.AccountTeam) {
            if (accountTeam.Role) {
              if (accountTeam.Role === 'PDM') {
                pdmEmail = (accountTeam.Email) ? accountTeam.Email : null;
              } else if (accountTeam.Role === 'PTM') {
                ptmEmail = (accountTeam.Email) ? accountTeam.Email : null;
              }
            }
          }
        }
        let staffEmail = null;
        if (profile.StaffTeam) {
          for (const staffTeam of profile.StaffTeam) {
            if (staffTeam.Email) {
              staffEmail = staffTeam.Email;
              break; // only use the first one
            }
          }
        }
        /** practice fields **/
        this._eventInfo.fullName = fullname;
        this._eventInfo.LastName = physicianDetails.Last_Name__c || '';
        this._eventInfo.Specialty = physicianDetails.Specialty__c || '';
        this._eventInfo.Address = address1;
        this._eventInfo.Address2 = address2;
        this._eventInfo.City = physicianDetails.BillingCity || '';
        this._eventInfo.State = physicianDetails.BillingState || '';
        this._eventInfo.Zip = physicianDetails.BillingPostalCode || '';
        this._eventInfo.Phone = this.maskPipe.transform(physicianDetails.Phone || '', '000.000.0000');
        this._eventInfo.fax = this.maskPipe.transform(physicianDetails.Fax || '', '000.000.0000');
        this._eventInfo.email = physicianDetails.Contact_Email__c || '';
        this._eventInfo.staffEmail = staffEmail;
        this._eventInfo.Website = physicianDetails.Website || '';
        this._eventInfo.facebook = (physicianDetails.Facebook_Url__c) ? this.tldOnly(physicianDetails.Facebook_Url__c) : 'facebook.com/mdvip';
        this._eventInfo.twitter = (physicianDetails.Twitter_Url__c) ? this.tldOnly(physicianDetails.Twitter_Url__c) : 'twitter.com/mdvip';
        /** end practice fields **/
  
        /** data fields **/
        this._eventInfo.physicianId = physicianId;
        this._eventInfo.memberServiceState = (physicianDetails.Member_Service_State__c) ?
          physicianDetails.Member_Service_State__c.toUpperCase() : '';
        this._eventInfo.membershipTier = physicianDetails.Membership_Tier__c || '';
        this._eventInfo.pdmEmail = pdmEmail;
        this._eventInfo.physicianStatusType = physicianDetails.Physician_Status_Type__c || '';
        this._eventInfo.practiceStatus = practiceStatus;
        this._eventInfo.ptmEmail = ptmEmail;
        this._eventInfo.relationshipStatus = physicianDetails.MDVIP_Relationship_Status__c || '';
        /** end data fields **/
      } else {
        this.shouldShowPhysicianNotFound = true;
      }
    } else if (profile.StatusCode) {
      // new version
      if (profile.StatusCode === '200') {
        const physicianDetails = profile.PhysicianDetails;
        const physicianId = physicianDetails.PhysicianId;
        const fullname = physicianDetails.Name;
        let address1 = null;
        let address2 = null;
        if (physicianDetails.Billingstreet) {
          const address = physicianDetails.Billingstreet.split('\r\n');
          address1 = address[0];
          if (address.length > 1) {
            address2 = address[1];
          }
        }
        let practiceStatus = null;
        if (physicianDetails.PracticeStatus) {
          practiceStatus = physicianDetails.PracticeStatus.toLowerCase().split(' ').map(word => {
            return word.charAt(0).toUpperCase() + word.slice(1);
          }).join('-');
        }
        let pdmEmail = null;
        let ptmEmail = null;
        if (profile.AccountTeam) {
          for (const accountTeam of profile.AccountTeam) {
            if (accountTeam.Role) {
              if (accountTeam.Role === 'PDM') {
                pdmEmail = (accountTeam.Email) ? accountTeam.Email : null;
              } else if (accountTeam.Role === 'PTM') {
                ptmEmail = (accountTeam.Email) ? accountTeam.Email : null;
              }
            }
          }
        }
        let staffEmail = null;
        if (profile.StaffTeam) {
          for (const staffTeam of profile.StaffTeam) {
            if (staffTeam.Email) {
              staffEmail = staffTeam.Email;
              break; // only use the first one
            }
          }
        }
        /** practice fields **/
        this.eventInfo.fullName = fullname;
        this.eventInfo.LastName = physicianDetails.LastName || '';
        this.eventInfo.Specialty = physicianDetails.Specialty || '';
        this.eventInfo.Address = address1;
        this.eventInfo.Address2 = address2;
        this.eventInfo.City = physicianDetails.BillingCity || '';
        this.eventInfo.State = physicianDetails.BillingState || '';
        this.eventInfo.Zip = physicianDetails.Billingpostalcode || '';
        this.eventInfo.Phone = this.maskPipe.transform(physicianDetails.Phone || '', '000.000.0000');
        this.eventInfo.fax = this.maskPipe.transform(physicianDetails.Fax || '', '000.000.0000');
        this.eventInfo.email = physicianDetails.ContactEmail || '';
        this.eventInfo.staffEmail = staffEmail;
        this.eventInfo.Website = physicianDetails.Website || '';
        this.eventInfo.facebook = (physicianDetails.FacebookUrl) ? this.tldOnly(physicianDetails.FacebookUrl) : 'facebook.com/mdvip';
        this.eventInfo.twitter = (physicianDetails.TwitterUrl) ? this.tldOnly(physicianDetails.TwitterUrl) : 'twitter.com/mdvip';
        /** end practice fields **/
  
        /** data fields **/
        this.eventInfo.physicianId = physicianId;
        this.eventInfo.memberServiceState = (physicianDetails.MemberServiceState) ?
          physicianDetails.MemberServiceState.toUpperCase() : '';
        this.eventInfo.membershipTier = physicianDetails.MembershipTier || '';
        this.eventInfo.pdmEmail = pdmEmail;
        this.eventInfo.physicianStatusType = physicianDetails.PhysicianStatusType || '';
        this.eventInfo.practiceStatus = practiceStatus;
        this.eventInfo.ptmEmail = ptmEmail;
        this.eventInfo.relationshipStatus = physicianDetails.MDVIPRelationshipStatus || '';
        /** end data fields **/
      } else {
        this.shouldShowPhysicianNotFound = true;
      }
    }
    
  }

  tldOnly(url) {
    return url.toLowerCase()
      .replace('www.', '')
      .replace('http://', '')
      .replace('https://', '');
  }

  numberOfSessionsChanged(e) {
    console.log(this.numberOfSessions);
    if (this.numberOfSessions > 1) {
      this._eventInfo.sessions[0].includeEventEndTime = true;
    }
    if (this._eventInfo.sessions.length > this.numberOfSessions) {
      // length 3, sessions is 2, pop 1
      for (let i = 0; i <= (this._eventInfo.sessions.length - this.numberOfSessions); i++) {
        this._eventInfo.sessions.pop();
      }
    }
    if (this.numberOfSessions > this._eventInfo.sessions.length) {
      for (let i = 0; i <= (this.numberOfSessions - this._eventInfo.sessions.length); i++) {
        this._eventInfo.sessions.push({
          eventDate: this._eventInfo.sessions[0].eventDate,
          eventStartTime: {
            hour: 1,
            minute: 0,
            hhMm: '',
            amPm: 'AM',
            moment: moment().hour(1).minute(0),
            timeZone: this.timeZone
          },
          includeEventEndTime: true,
          eventEndTime: {
            hour: 2,
            minute: 0,
            hhMm: '',
            amPm: 'AM',
            moment: moment().hour(2).minute(0),
            timeZone: this.timeZone,
            isUserSet: false
          }
        });
      }
    }
    this.updateEventDateText();
  }

  eventTimeModelChanged(eventTime) {
    // append leading zero if first digit is not zero or one
    if (eventTime.hhMm.length === 1) {
      const hour = parseInt(eventTime.hhMm.substring(0, 1), 10);
      if (!isNaN(hour)) {
        if (hour > 1) {
          eventTime.hhMm = '0'.concat(eventTime.hhMm);
        }
      }
    }
  }

  eventTimeChanged(session, isStartTime) {
    let eventTime;
    if (isStartTime) {
      eventTime = session.eventStartTime;
    } else {
      console.log('end time changed');
      session.eventEndTime.isUserSet = true;
      eventTime = session.eventEndTime;
    }
    this.parseEventTime(eventTime);
    if (isStartTime && !session.eventEndTime.isUserSet) {
      this.setEventEndTime(session);
    }
    this.updateEventDateText();
    if (this.isIntroEditable) {
      this.resetIntro();
    }
  }

  amPmClicked(session, isStartTime) {
    let eventTime;
    if (isStartTime) {
      eventTime = session.eventStartTime;
    } else {
      session.eventEndTime.isUserSet = true;
      eventTime = session.eventEndTime;
    }
    eventTime.amPm = (eventTime.amPm === 'AM') ? 'PM' : 'AM';
    this.parseEventTime(eventTime);
    if (isStartTime && !session.eventEndTime.isUserSet) {
      session.eventEndTime.amPm = eventTime.amPm;
      this.parseEventTime(session.eventEndTime);
    }
    this.updateEventDateText();
    if (this.isIntroEditable) {
      this.resetIntro();
    }
  }

  parseEventTime(eventTime) {
    const hhMmArray = eventTime.hhMm.split(':');
    let hours = 1;
    let minutes = 0;
    let m = moment().hours(1).minutes(0);
    let stringShouldChange = false;
    if (hhMmArray.length > 1) {
      let parsedHours = parseInt(hhMmArray[0], 10);
      let parsedMinutes = parseInt(hhMmArray[1], 10);
      if (!isNaN(parsedHours) && !isNaN(parsedMinutes))  {
        if (parsedMinutes > 59) {
          stringShouldChange = true;
          parsedMinutes = 59;
        }
        if (parsedHours > 12) {
          stringShouldChange = true;
        } else {
          if (parsedHours === 12) {
            if (eventTime.amPm === 'AM') {
              stringShouldChange = true;
              parsedHours = 0;
            }
          } else { // parsedHours < 12
            if (parsedHours === 0) {
              stringShouldChange = true;
            } else {
              if (eventTime.amPm === 'PM') {
                parsedHours += 12;
              }
            }
          }
        }
        hours = parsedHours;
        minutes = parsedMinutes;
        m = moment().hours(hours).minutes(minutes).seconds(0);
      } else {
        eventTime.hhMm = '';
      }
    } else {
      eventTime.hhMm = '';
    }
    eventTime.hour = hours;
    eventTime.minute = minutes;
    eventTime.moment = m;
    eventTime.timeZone = this.timeZone;
    if (stringShouldChange) {
      eventTime.hhMm = m.format('hh:mm');
      eventTime.amPm = m.format('A');
    }
  }

  updateEventDateText() {
    const eventDateMoment = moment(this.ngbDateToString(this._eventInfo.sessions[0].eventDate), 'YYYY-MM-DD');
    this._eventInfo.eventDateText = eventDateMoment.format('dddd, MMMM D');
    if (this.numberOfSessions > 1) {
      if (this.numberOfSessions === 2) {
        this._eventInfo.eventDateText += '\nTwo Sessions';
      }
      if (this.numberOfSessions === 3) {
        this._eventInfo.eventDateText += '\nThree Sessions';
      }
    }
    let shouldShowEventTimeWarning = false;
    for (const session of this._eventInfo.sessions) {
      // check dates for warning
      if (session.includeEventEndTime) {
        const eventEndTimeMoment = session.eventEndTime.moment.clone();
        if (session.eventStartTime.moment.isAfter(eventEndTimeMoment)) {
          eventEndTimeMoment.add(1, 'days');
        }
        const sessionLength = eventEndTimeMoment.diff(session.eventStartTime.moment, 'hours', true);
        if (Math.abs(Math.round(sessionLength)) > 12 ) {
          shouldShowEventTimeWarning = true;
        }
      }

      let eventTimeText = session.eventStartTime.moment.format('h:mm A');
      if (session.includeEventEndTime) {
        eventTimeText += ' – ' + session.eventEndTime.moment.format('h:mm A');
      }
      this._eventInfo.eventDateText += '\n' + eventTimeText;
    }
    this.shouldShowEventTimeWarning = shouldShowEventTimeWarning;
  }

  eventDateTextChanged(e) {
    const maxLines = 5;
    const lines = this._eventInfo.eventDateText.split('\n');
    if (lines.length > maxLines) {
      const newLines = lines.slice(0, maxLines);
      const newText = newLines.join('\n');
      (e.target as HTMLTextAreaElement).value = newText;
    }
  }

  /** intro functions **/
  resetIntro() {
    console.log('resetting intro');
    let intro = this.introTemplate.replace(/<[^>]*>/g, '');
    intro = this.fillIntroPlaceholders(intro);
    this._eventInfo.intro = intro;
  }

  getIntro(): string {
    let intro;
    if (this.isIntroEditable && this._eventInfo.intro) { // in case asset does not have intro
      intro = this._eventInfo.intro;
    } else {
      intro = this.introTemplate;
    }
    intro = this.fillIntroPlaceholders(intro);
    return intro;
  }

  fillIntroPlaceholders(intro): string {
    const eventTimeZone = this._eventInfo.sessions[0].eventStartTime.timeZone;
    const eventDateMoment = moment(this.ngbDateToString(this._eventInfo.sessions[0].eventDate), 'YYYY-MM-DD');
    intro = intro.replace('[event_start_time]', this._eventInfo.sessions[0].eventStartTime.moment.tz(eventTimeZone)
      .format('h:mm A'));
    intro = intro.replace('[event_date]', eventDateMoment.format('MMMM D'));
    intro = intro.replace('[event_day]', eventDateMoment.format('dddd'));
    return intro;
  }

  includeEventEndTimeChange(session) {
    if (session.includeEventEndTime) {
      this.setEventEndTime(session);
    } else {
      session.eventEndTime = {
        hour: 1,
        minute: 0,
        hhMm: '01:00',
        amPm: 'AM',
        moment: moment().hour(1).minute(0),
        timeZone: this.timeZone
      };
    }
    this.updateEventDateText();
  }

  setEventEndTime(session) {
    session.eventEndTime.moment = session.eventStartTime.moment.clone().add(1, 'hours');
    session.eventEndTime.timeZone = this.timeZone;
    session.eventEndTime.hour = session.eventEndTime.moment.hour();
    session.eventEndTime.minute = session.eventEndTime.moment.minute();
    session.eventEndTime.hhMm = session.eventEndTime.moment.format('hh:mm');
    session.eventEndTime.amPm = session.eventEndTime.moment.format('A');
  }

  ngbDateToString(object: NgbDate) {
    return (object) ? object.year + '-' + object.month + '-' + object.day : '';
  }

  onRsvpPhoneChange($event) {
    // console.log('rsvp phone change');
    if (this.shouldFirePhoneChangeEvent) {
      this.setRsvpMessage();
    } else {
      this.shouldFirePhoneChangeEvent = true;
    }
  }

  onVenueTypeChange(e) {
    // this.eventInfo.venue_name = '';
    this._eventInfo.venue_address_1 = '';
    this._eventInfo.venue_address_2 = '';
    this._eventInfo.venue_city = '';
    this._eventInfo.venue_state = '';
    this._eventInfo.venue_zip = '';
    this._eventInfo.webinarUrl = '';
    this._eventInfo.additionalInformation = '';
    this._eventInfo.venue_name = (this._eventInfo.venueType === VenueType.Virtual) ? 'AN ONLINE WEBINAR' : '';
    this.virtualRsvpMethod = (this._eventInfo.venueType === VenueType.Combined) ? 'phone' : 'url';
    this.setRsvpMessage();
  }

  onVirtualRsvpMethodChange() {
    this.setRsvpMessage();
  }

  setRsvpMessage() {
    if (this._eventInfo.venueType === VenueType.Virtual) {
      switch (this.virtualRsvpMethod) {
        case 'url': {
          this._eventInfo.rsvp_message = 'RSVP by clicking on the webinar URL received via email invite';
          break;
        }
        case 'phone': {
          this._eventInfo.rsvp_message = 'RSVP by calling ' + this._eventInfo.rsvp_phone;
          break;
        }
      }
    } else {
      // this.rsvp_message = 'RSVP by calling the office at ' + this.maskPipe.transform(this.rsvp_phone, '000.000.0000');
      this._eventInfo.rsvp_message = 'RSVP by calling the office at ' + this._eventInfo.rsvp_phone;
    }
  }

  onRsvpDateSelect(e) {
    if (this.rsvpByDateObj) {
      const rsvpByDateMoment = moment(this.ngbDateToString(this.rsvpByDateObj), 'YYYY-MM-DD');
      this._eventInfo.rsvpByDate = `RSVP by ${rsvpByDateMoment.format('dddd, MMMM D')}`;
    } else {
      this._eventInfo.rsvpByDate = ''
    }
  }

  onRsvpDateClear() {
    this.rsvpByDateObj = null;
    this.onRsvpDateSelect(null);
  }

  topicChanged(e) {
    const byoTopic = this.topics.filter(x =>  x.id === this._eventInfo.byoSelectedTopicId)[0];
    this._eventInfo.long_description_html = byoTopic.long_description;
    this.clearUndoHistory();
    this._eventInfo.title = byoTopic.title;
    this._eventInfo.brief_description = byoTopic.brief_description;
    this.introTemplate = byoTopic.intro;
    this.resetIntro(); // isIntroEditable will always be true if topicChanged is called
    this._eventInfo.header_image = byoTopic.header_image;
    this.selectedHeaderImage = this.headerImages.filter( x => x.file === this._eventInfo.header_image)[0];
  }

  /* quill */
  contentChanged(e) {

  }

  editorCreated(e) {
    this.editorInstance = e;
    this.clearUndoHistory();
  }

  insertColumnBreak() {
    let cursorPosition;
    if (this.editorInstance.getSelection()) {
      cursorPosition = this.editorInstance.getSelection().index;
    } else {
      cursorPosition = 0;
    }
    this.editorInstance.insertText(cursorPosition, '\n[Column Break]', 'user'); // user makes sure update event occurs
    cursorPosition += 15;
    this.editorInstance.setSelection(cursorPosition, 0, 'user');
    this.editorInstance.formatLine(cursorPosition, 0, 'list', false, 'user');
  }

  applyStyle(style) {
    console.log(style);
    const cursorPosition = this.editorInstance.getSelection().index;
    const selectionEnd = this.editorInstance.getSelection().length;
  }

  undo() {
    this.editorInstance.history.undo();
  }

  clearUndoHistory() {
    if (this.editorInstance) {
      setTimeout(() => {this.editorInstance.history.clear(); }, 0);
    }
  }

  headerImageClicked(headerImage) {
    this.selectedHeaderImage = headerImage;
    this._eventInfo.header_image = this.selectedHeaderImage.file;
  }

  nextStepClicked(nextStep) {
    // send next step clicked event
  }
}
