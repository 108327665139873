const navBarData = [
  {
    id: 1,
    name: 'Events & Event-Related Collateral',
    image: '1-0-eventsandMarketingMaterials.jpg',
    roles: ['regular', 'plus', 'womens-health'],
    link: '',
    isCollapsed: true,
    subcategories: [
      {
        id: 2,
        name: 'Events',
        image: '1-1-events.jpg',
        roles: ['regular', 'plus', 'womens-health'],
        link: '/events',
        subcategories: [],
        items: []
      },
      {
        id: 4,
        name: 'Event-Related Collateral',
        image: '1-3-eventRelatedCollateral.jpg',
        roles: ['regular', 'plus', 'womens-health'],
        link: '',
        subcategories: [],
        items: [
          {
            id: '',
            name: 'Event Waiver',
            description: '',
            image: '4-2-eventWaiver.jpg',
            roles: ['regular', 'plus', 'womens-health'],
            type: 'file',
            info: 'eventwaiver.pdf',
            preview: 'eventwaiver.pdf'
          },
          {
            id: '',
            name: 'Generic Voice Shot Script',
            description: '',
            image: '4-3-genericVoiceShot.jpg',
            roles: ['regular', 'plus', 'womens-health'],
            type: 'file',
            info: 'EventVoiceShotScript_NonPreferredVendor_Nov2022.pdf',
            preview: 'EventVoiceShotScript_NonPreferredVendor_Nov2022.pdf'
          },
          /*{
            id: '',
            name: 'Attendance Cards (1 pack = 50)',
            description: '',
            image: '4-4-attendanceCards.jpg',
            roles: ['regular', 'plus', 'womens-health'],
            type: 'product',
            info: 'C64164FE-9DD1-49CC-8BA3-5246DD623BC6', // static
            preview: ''
          },*/
          {
            id: '',
            name: 'Heart Disease/Stroke Handout',
            description: '',
            image: '4-5-heartDiseaseStrokeHandout.jpg',
            roles: ['regular', 'plus', 'womens-health'],
            type: 'product',
            info: '0DDDF4AD-998C-4567-A56D-5E11EB7909E2', // variable (OK)
            preview: ''
          },
          {
            id: '',
            name: 'Let’s Beat Skin Cancer Handout',
            description: '',
            image: '4-6-letsBeatSkinCancerHandout.jpg',
            roles: ['regular', 'plus', 'womens-health'],
            type: 'product',
            info: '5BF22332-B7B9-4869-AD45-9524B84B27B7', // static
            preview: ''
          },
          {
            id: '',
            name: 'MDVIP Connect Flyer',
            description: '',
            image: '4-7-connectFlyer.jpg',
            roles: ['regular', 'plus', 'womens-health'],
            type: 'product',
            info: '33804B82-6667-4901-AD3B-DC9332A409AE', // static
            preview: ''
          },
          /*{
            id: '',
            name: 'Connect App Brochure', // No item number yet
            description: '',
            image: '4-8-connectAppBrochure.jpg',
            roles: ['regular', 'plus', 'womens-health'],
            type: 'product',
            info: ''
          },*/
          {
            id: '',
            name: 'Name Badge Labels (3.5" x 2.5")',
            description: '',
            image: '4-9-nameBadgeLabels.jpg',
            roles: ['regular', 'plus', 'womens-health'],
            type: 'product',
            info: '1120CBD2-639A-4746-B69C-7DA7396A4D4B' // static
          },
          /*{
            id: '',
            name: 'Save on Prescript/Meds Handout',
            description: '',
            image: '4-10-saveOnPrescriptionsHandout.jpg',
            roles: ['regular', 'plus', 'womens-health'],
            type: 'product',
            info: '05391E6F-C6EF-49B9-881F-AD357FCB5BC5', // static
            preview: ''
          },*/
          {
            id: '',
            name: 'Longevity Handout',
            description: '',
            image: '4-11-longevityHandout.jpg',
            roles: ['regular', 'plus', 'womens-health'],
            type: 'product',
            info: 'MD51359-POD', // static
            preview: ''
          },
          {
            id: '',
            name: 'Gut Health Handout',
            description: '',
            image: '4-12-gutHealthHandout.jpg',
            roles: ['regular', 'plus', 'womens-health'],
            type: 'product',
            info: 'MD64664-POD', // static
            preview: ''
          }
        ]
      }
    ],
    items: []
  },
  {
    id: 5,
    name: 'Wellness Program Materials',
    image: '5-0-wellnessProgramMaterials.jpg',
    roles: ['regular'],
    link: '',
    subcategories: [],
    items: [
      {
        id: '',
        name: 'Wellness Program Folder', // custom order limit
        description: '', // Temporarily out of stock, will be available the week of the 11/08/21.
        image: '5-1-wpFolder.jpg',
        roles: ['regular'],
        type: 'product',
        info: '0912CA32-9266-4B02-B4E0-CBBFF3BCF608', // static
        preview: ''
      },
      {
        id: '',
        name: 'Pre-Wellness Checklist',
        description: '',
        image: '5-2-mdvip_standard_prewellnessChecklist_1220.jpg',
        roles: ['regular'],
        type: 'product',
        info: '82E756E2-5D8B-441B-B281-473853AFE714', // variable (OK)
        preview: ''
      },
      {
        id: '',
        name: 'Annual Wellness Reminder Postcard',
        description: '',
        image: '5-3-wellnessPrograms_reminderPostcard.jpg',
        roles: ['regular'],
        type: 'product',
        info: '83D31E03-F16E-4FE2-923A-59FFE07C2A8F', // variable
        preview: ''
      }
    ]
  },
  {
    id: 6,
    name: 'Wellness Program Plus Materials',
    image: '5-0-wellnessProgramMaterials.jpg',
    roles: ['plus'],
    link: '',
    subcategories: [],
    items: [
      {
        id: '',
        name: 'Wellness Program Plus Folder', // TODO Custom Order Limits
        description: '',
        image: '6-1-wppFolder.jpg',
        roles: ['plus'],
        type: 'product',
        info: '992CA18D-BA2E-47C9-8C2E-4B1F3EAC38AF', // static
        preview: ''
      },
      {
        id: '',
        name: 'Pre-Wellness Plus Checklist', // TODO - Not in Four51?
        description: '',
        image: '6-2-mdvip_wpp_prewellnessChecklist_1220.jpg',
        roles: ['plus'],
        type: 'product',
        info: 'E93CACCD-C644-4FE8-AF48-23AA8654E09A', // variable
        preview: ''
      },
      {
        id: '',
        name: 'Annual Wellness Reminder Postcard',
        description: '',
        image: '5-3-wellnessPrograms_reminderPostcard.jpg',
        roles: ['plus'],
        type: 'product',
        info: '83D31E03-F16E-4FE2-923A-59FFE07C2A8F', // variable
        preview: ''
      }
    ]
  },
  {
    id: 10,
    name: 'Women\'s Health Wellness Program Materials',
    image: '10-0-womensHealthWellness.jpg',
    roles: ['womens-health'],
    link: '',
    subcategories: [],
    items: [
      {
        id: '',
        name: 'Women\'s Health Pre-Wellness Checklist',
        description: '',
        image: '10-2-mdvip_whp_prewellnessChecklist_1220.jpg',
        roles: ['womens-health'],
        type: 'product',
        info: '7921B000-B0AB-4AAE-8B5E-E252292595DD',
        preview: ''
      },
      {
        id: '',
        name: 'Annual Wellness Reminder Postcard',
        description: '',
        image: '5-3-wellnessPrograms_reminderPostcard.jpg',
        roles: ['womens-health'],
        type: 'product',
        info: '83D31E03-F16E-4FE2-923A-59FFE07C2A8F',
        preview: ''
      },
      /* Peter Palacio, MD (6282) cannot see */
      {
        id: '',
        name: 'Women’s Health Program Referral Cards',
        description: '',
        image: '10-3-whp_referralcards.jpg',
        roles: ['womens-health'],
        type: 'product',
        info: 'MD64612'
      }
    ]
  },
  {
    id: 11,
    name: 'Wellness Program Materials',
    description: '',
    image: '5-0-wellnessProgramMaterials.jpg',
    roles: ['ascension-medical-group'],
    link: '',
    subcategories: [],
    items: [
      {
        id: '',
        name: 'AMG Annual Wellness Folder', 
        description: '',
        image: '11-1-AMG-Wellness-Folder.jpg',
        roles: ['ascension-medical-group'],
        type: 'product',
        info: 'MD62557', // static
        preview: ''
      },
      {
        id: '',
        name: 'Annual Wellness Reminder Postcard',
        description: '',
        image: '5-3-wellnessPrograms_reminderPostcard.jpg',
        roles: ['ascension-medical-group'],
        type: 'product',
        info: '83D31E03-F16E-4FE2-923A-59FFE07C2A8F',
        preview: ''
      },
      {
        id: '',
        name: 'AMG Gait Speed Floor Decal', 
        description: '',
        image: '11-2-AMG-gait-speed.jpg',
        roles: ['ascension-medical-group'],
        type: 'product',
        info: 'MD64594-PP', // static
        preview: ''
      },
      {
        id: '',
        name: 'Pre-Wellness Checklist',
        description: '',
        image: '5-2-mdvip_standard_prewellnessChecklist_1220.jpg',
        roles: ['ascension-medical-group'],
        type: 'product',
        info: '82E756E2-5D8B-441B-B281-473853AFE714', // variable (OK)
        preview: ''
      },
      {
        id: '',
        name: 'MDVIP Connect Flyer',
        description: '',
        image: '4-7-connectFlyer.jpg',
        roles: ['ascension-medical-group'],
        type: 'product',
        info: '33804B82-6667-4901-AD3B-DC9332A409AE', // static
        preview: ''
      },
      {
        id: '',
        name: 'MedJet Brochure',
        description: '',
        image: '11-3-medjet.jpg',
        roles: ['ascension-medical-group'],
        type: 'product',
        info: 'MD64448-PPK', // static
        preview: ''
      }
    ]
  },
  {
    id: 7,
    name: 'Member Retention Materials',
    image: '7-0-memberRetentionMaterials.jpg',
    roles: ['regular', 'plus', 'womens-health', 'ascension-medical-group'],
    link: '',
    subcategories: [],
    items: [
      {
        id: '',
        name: 'Benefits of Membership Bifold Brochure',
        description: '',
        image: '7-1-bomBrochure.jpg',
        roles: ['regular', 'plus', 'womens-health', 'ascension-medical-group'],
        type: 'product',
        info: '3712EA8C-9AE2-4C9E-8CD3-F41624578651', // static
        preview: ''
      },
      {
        id: '',
        name: 'Acrylic Holder for BOM',
        description: '',
        image: '7-2-acrylic-holder.jpg',
        roles: ['ascension-medical-group'],
        type: 'product',
        info: 'MD62517', // static
        preview: ''
      },
      /*{
        id: '',
        name: 'Travel Advantages Flyer', // custom order limit
        description: '',
        image: '7-2-travelAdvantagesFlyer.jpg',
        roles: ['regular', 'plus', 'womens-health'],
        type: 'product',
        info: '76704AAC-DCE0-43B7-90B6-507CB902C2B7', // static
        preview: ''
      },*/
      /*{
        id: '',
        name: 'Connect Guide',
        description: '',
        image: '7-3-connectGuide.jpg',
        roles: ['regular', 'plus', 'womens-health'],
        type: 'product',
        info: '13DC89CC-6614-4181-BE11-726684D353E3', // static
        preview: ''
      },*/
      {
        id: '',
        name: 'Connect Flyer',
        description: '',
        image: '7-4-connectFlyer.jpg',
        roles: ['regular', 'plus', 'womens-health'],
        type: 'product',
        info: '33804B82-6667-4901-AD3B-DC9332A409AE', // static
        preview: ''
      },
      /*{
        id: '',
        name: 'Connect App Brochure', // No item number yet
        description: '',
        image: '7-5-connectAppBrochure.jpg',
        roles: ['regular', 'plus', 'womens-health'],
        type: 'product',
        info: ''
      },
      {
        id: '',
        name: 'Benefits of Membership Flyer', // Not printed yet
        description: '',
        image: '600x400.png',
        roles: ['regular', 'plus', 'womens-health'],
        type: 'product',
        info: ''
      },*/
      {
        id: '',
        name: 'Birthday Cards',
        description: '',
        image: '7-7-birthdayCard2023.jpg',
        roles: ['regular', 'plus', 'womens-health'],
        type: 'product',
        info: 'MD64659-PP',
        preview: ''
      },
      /*{
        id: '',
        name: 'Holiday Cards',
        description: '',
        image: '7-8-holidayCard2020.jpg',
        roles: ['regular', 'plus', 'womens-health'],
        type: 'product',
        info: '209DAF94-5177-4D6D-B958-C0A680C9020E',
        preview: ''
      },
      {
        id: '',
        name: 'Holiday Cards', // Holiday Cards - 2021
        description: '',
        image: '7-9-holidayCard2021.jpg',
        roles: ['regular', 'plus', 'womens-health'],
        type: 'product',
        info: 'FE9B1E6C-C534-4E9A-A2BD-FB569424D421',
        preview: ''
      },*/
      {
        id: '',
        name: 'Holiday Cards: Wonderland', // Holiday Cards - 2022
        description: '',
        image: '7-8-holidayCard2022.jpg',
        roles: ['regular', 'plus', 'womens-health'],
        type: 'product',
        info: 'MD64595',
        preview: ''
      },
      {
        id: '',
        name: 'Holiday Cards: Festive', // Holiday Cards - 2023
        description: '',
        image: '7-8-holidayCard2023.jpg',
        roles: ['regular', 'plus', 'womens-health'],
        type: 'product',
        info: 'MD64667-PP',
        preview: ''
      }
    ]
  },
  {
    id: 8,
    name: 'Practice Promotion Materials',
    image: '8-0-practicePromotionMaterials.jpg',
    roles: ['regular', 'plus', 'womens-health'],
    link: '',
    subcategories: [],
    items: [
      {
        id: '',
        name: 'Referral Cards',
        description: '',
        image: '8-1-referralCards.jpg',
        roles: ['regular', 'plus'],
        type: 'products',
        info: [
          {
            Name: 'With Appointment Card',
            ID: 'A2C21292-3572-44B7-8CEB-F6929DB254FF'
          }, {
            Name: 'Without Appointment Card',
            ID: '49D4F31D-1A5B-435F-9094-070063F47B26'
          }/*, {
            Name: 'Without Appointment Card Kit',
            ID: 'MD52410'
          }*/
        ]
      },
      {
        id: '',
        name: 'Community Prospect Trifold',
        description: '',
        image: '8-3-communityProspectTrifold.jpg',
        roles: ['regular', 'plus', 'womens-health'],
        type: 'product',
        info: 'AE13B4AA-EAE3-41D9-859C-00D766CEF508'
      },
      /*{
        id: '',
        name: 'Community Prospect Trifold (Spanish)', // Removed Per Aimee
        description: '',
        image: '600x400.png',
        roles: ['regular', 'plus', 'womens-health'],
        link: ''
      },*/
      {
        id: '',
        name: 'Personalized Banners', // TODO
        description: '',
        image: '8-5-personalizedBanners.jpg',
        roles: ['regular', 'plus', 'womens-health'],
        type: 'link',
        info: ['/banners'],
        action: 'Order Now'
      },
      {
        id: '',
        name: 'Referral Card Kit (w/o Appointment Card)',
        description: '',
        image: '8-2-referralCardKit.jpg',
        roles: ['regular', 'plus'],
        type: 'product',
        info: 'MD52410'
      },
      /*{
        id: '',
        name: 'Ambassador Club Poster (11x17)', // Removed Per Aimee
        description: '',
        image: '600x400.png',
        roles: ['regular', 'plus', 'womens-health'],
        link: ''
      },
      {
        id: '',
        name: 'Ambassador Club Flyer', // Removed Per Aimee
        description: '',
        image: '600x400.png',
        roles: ['regular', 'plus', 'womens-health'],
        link: ''
      },
      {
        id: '',
        name: 'Ambassador Cards', // Removed Per Aimee
        description: '',
        image: '600x400.png',
        roles: ['regular', 'plus', 'womens-health'],
        link: ''
      },*/
      {
        id: '',
        name: 'Membership Giveaway (Community Auction Events Only)',
        description: '',
        image: '8-2-membershipGiveaway.jpg',
        roles: ['regular'],
        type: 'link',
        info: ['/membership-giveaway'],
        action: 'Order Now'
        // MDVIP_MCertificate F.52425, F.52424 Personalized Gift Tag, Kitted Bags
        // A678D8E2-AD56-4B10-BB5B-08142796E227'
      }/*,
      {
        id: '',
        name: 'Facebook/Google Review Request Cards',
        description: 'Choose this only if you have an active MDVIP Facebook account.',
        image: '8-6-ReferralReviewCard.jpg',
        roles: ['regular', 'plus', 'womens-health'],
        type: 'product',
        info: 'MD-64552'
      },
      {
        id: '',
        name: 'Google Only Review Request Cards',
        description: 'Choose this if you do not use your MDVIP Facebook account.',
        image: '8-7-ReferralReviewCardGoogle.jpg',
        roles: ['regular', 'plus', 'womens-health'],
        type: 'product',
        info: 'MD-64553'
      }*/
      ,{
        id: '',
        name: 'Patient Reviews Cards (Pack of 50)',
        description: '',
        image: '8-6-PatientReviewsCard-2023.jpg',
        roles: ['regular', 'plus', 'womens-health'],
        type: 'product',
        info: 'MD64660-PP'
      }
    ]
  },
  {
    id: 9,
    name: 'PowerPoint Library',
    image: '4-1-powerPointLibrary.jpg',
    roles: ['regular', 'plus', 'womens-health'],
    link: ['/powerpoint-library'],
    action: 'View Library',
    subcategories: [],
    items: []
  }
];

export { navBarData };
