import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {MdvipUser} from '../models/mdvip-user';
import {MdvipUserService} from '../mdvip-user.service';
import {Subscription} from 'rxjs';
import {Four51Service} from '../four51.service';
import {NgbDate, NgbDateParserFormatter, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {SpecService} from '../spec.service';
import * as moment from 'moment';
import {NgbDateCustomParserFormatter} from '../dateformat';
import {AddressService} from '../address.service';
import {MailerMessage, MailerService} from '../mailer.service';
import {MailTemplateService, MailTemplateValue} from '../mail-template.service';
import {CookieService} from 'ngx-cookie-service';
import {environment} from '../../environments/environment';

@Component({
  selector: 'app-membership-giveaway',
  templateUrl: './membership-giveaway.component.html',
  styleUrls: ['./membership-giveaway.component.scss'],
  providers: [
    {provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter}
  ]
})

export class MembershipGiveawayComponent implements OnInit, OnDestroy  {
  private variant;
  private subscription: Subscription;
  private mdvipUser: MdvipUser;
  private imageUrl;
  private proofUrl;
  private isWaiting: boolean;
  public isComplete = false;
  private hidden = ['V02Purpose', 'V00Event_Name', 'Date', 'Date10', 'Date30', 'data'];
  private minEventDate: NgbDate;
  private minDeliverDate: NgbDate;
  private eventDate: NgbDate;
  private deliverDate: NgbDate;
  private productId;
  private orderNumber;
  private shipAddress: any;
  private comments: string;
  constructor(private mdvipUserService: MdvipUserService, private four51Service: Four51Service, private modalService: NgbModal,
              private route: ActivatedRoute, private router: Router, private specService: SpecService,
              private addressService: AddressService, private mailerService: MailerService,
              private mailTemplateService: MailTemplateService, private cookieService: CookieService) {}
  async ngOnInit() {
    this.subscription = new Subscription();
    const userSubscription = this.mdvipUserService.getMdvipUser().subscribe((user: MdvipUser) => {
      this.mdvipUser = user;
      if (user) {
        this.getShipAddress();
      }
    });
    this.subscription.add(userSubscription);
    const paramMapSubscription = this.route.paramMap.subscribe((params: ParamMap) => {
      const step = params.get('step');
      const complete = params.get('complete');
      if (complete) {
        this.isComplete = true;
      } else {
        this.isComplete = false;
      }
    });
    this.subscription.add(paramMapSubscription);

    const minEventDateMoment = moment().add(2, 'weeks');
    this.minEventDate = new NgbDate(minEventDateMoment.year(), minEventDateMoment.month() + 1, minEventDateMoment.date());
    const minDeliverDateMoment = moment();
    let businessDays = 7;
    do {
      minDeliverDateMoment.add(1, 'days');
      if (minDeliverDateMoment.isoWeekday() < 6) {
        businessDays--;
      }
    } while (businessDays > 0);
    this.minDeliverDate = new NgbDate(minDeliverDateMoment.year(), minDeliverDateMoment.month() + 1, minDeliverDateMoment.date());
    this.productId = 'A678D8E2-AD56-4B10-BB5B-08142796E227';
    const product: any = await this.four51Service.getProduct(this.productId).toPromise();
    console.log(product.Specs);
    this.variant = {
      ProductInteropID: this.productId,
      Specs: this.specService.fillInUserData(product.Specs)
    };
    this.updateVariant();
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  async updateVariant() {
    this.isWaiting = true;
    this.variant.Specs.Date.Value = moment(this.ngbDateToString(this.eventDate), 'YYYY-MM-DD').format('MM/DD/YYYY');
    this.variant.Specs.Date10.Value = moment(this.ngbDateToString(this.deliverDate), 'YYYY-MM-DD').format('MM/DD/YYYY');
    this.variant.Specs.Date30.Value = moment(this.ngbDateToString(this.eventDate), 'YYYY-MM-DD').add(30, 'days')
      .format('MMMM D, YYYY');
    this.variant.Specs.V02Purpose.Value = 'Auction';
    const data = {
      comments: this.comments,
      physicianId: this.getCustomFieldValue('physicianId'),
      email: this.mdvipUser.Email,
      pdmEmail: this.getCustomFieldValue('pdmEmail'),
      ptmEmail: this.getCustomFieldValue('ptmEmail'),
      staffEmail: this.getCustomFieldValue('staffEmail'),
      practiceStatus: this.getCustomFieldValue('practiceStatus'),
      relationshipStatus: this.getCustomFieldValue('relationshipStatus'),
      physicianStatusType: this.getCustomFieldValue('physicianStatusType'),
      channel: this.cookieService.get('mdvip.channel')
    }
    this.variant.Specs.data.Value = JSON.stringify(data);
    const response = await this.four51Service.createVariant(this.variant).toPromise();
    this.variant = response.body;
    this.imageUrl = this.variant.PreviewUrl + '?r=' + Math.random();
    this.proofUrl = this.variant.ProofUrl + '?r=' + Math.random();
  }

  onPreviewLoaded() {
    this.isWaiting = false;
  }

  onPreviewError(e) {
    console.log(e);
    this.isWaiting = false;
  }

  async getShipAddress() {
    // TODO refactor into address service
    const response: any = await this.four51Service.getAddresses('shipping').toPromise();
    const shippingAddresses = response.List;
    const address = this.getCustomFieldValue('Address');
    const city = this.getCustomFieldValue('City');
    if (address && city) { // physician user has address saved
      this.shipAddress = await this.addressService.findOrCreateShippingAddress(
        shippingAddresses,
        this.getCustomFieldValue('fullname'),
        address,
        this.getCustomFieldValue('Address2'),
        city,
        this.getCustomFieldValue('State'),
        this.getCustomFieldValue('Zip'),
        'US',
        this.mdvipUser.Phone,
        this.mdvipUser.FirstName,
        this.mdvipUser.LastName);
    } else {
      const addressMatchingName = shippingAddresses
        .find(x => x.FirstName === this.mdvipUser.FirstName && x.LastName === this.mdvipUser.LastName);
      if (addressMatchingName) {
        this.shipAddress = addressMatchingName;
      } else {
        this.shipAddress = {
          CompanyName: '',
          Street1: '',
          Street2: '',
          City: '',
          State: '',
          Zip: '',
          Country: 'US',
          Phone: '',
          FirstName: this.mdvipUser.FirstName,
          LastName: this.mdvipUser.LastName,
        };
      }
    }
  }

  getCustomFieldValue(name): string {
    const field = this.mdvipUser.CustomFields.filter(x => x.Name === name);
    if (field.length > 0) {
      const returnString = field[0].Value || '';
      return returnString;
    } else {
      return '';
    }
  }

  async onMembershipGiveawayFormSubmit(membershipGiveawayForm: NgForm) {
    if (membershipGiveawayForm.valid) {
      await this.updateVariant();
      let order: any = {
        Type: 'Standard'
      };
      console.log(this.variant);
      const lineItems: any = [
        {
          Product: {InteropID: this.productId},
          Quantity: 1,
          Variant: {InteropID: this.variant.InteropID},
          ShipAddressID: this.shipAddress.ID
        },
        {
          Product: {InteropID: 'E95E0413-3AA1-4B6D-B404-CACF3DDF0862'},
          Quantity: 1,
          ShipAddressID: this.shipAddress.ID
        }
      ];
      order.LineItems = lineItems;
      const response = await this.four51Service.putOrder(order).toPromise();
      order = response.body;
      this.router.navigate(['/membership-giveaway', {complete: true}]);
      this.orderNumber = response.body.ExternalID;
      console.log(order);

      /*
       * MG Submitted Internal Email
       */
      const mgSubmittedInternalEmail: MailerMessage = new MailerMessage();
      mgSubmittedInternalEmail.Subject = 'FOR REVIEW: Auction Gift ' + this.getCustomFieldValue('fullName') + ' ' +
        this.variant.Specs.Date.Value;
      mgSubmittedInternalEmail.Bcc = environment.emailBcc;
      if (!environment.production || environment.isTest) {
        mgSubmittedInternalEmail.To = environment.emailTo;
        mgSubmittedInternalEmail.Subject = 'TESTING: ' + mgSubmittedInternalEmail.Subject;
      } else {
        mgSubmittedInternalEmail.To = ['Marketing Support Site <MarketingSupportSite@mdvip.com>'];
        mgSubmittedInternalEmail.To.push('pdmadmin@mdvip.com');
      }
      mgSubmittedInternalEmail.From = 'Marketing Support Site <MarketingSupportSite@mdvip.com>';
      mgSubmittedInternalEmail.Body = 'This message requires an email client that supports HTML email.';
      const approval_url = window.location.origin + '/approval';
      const mailTemplateValues: MailTemplateValue[] = [
        {name: 'fullname', value: this.variant.Specs.V03Full_Name.Value},
        {name: 'event_name', value: this.variant.Specs.V00Event_Name.Value},
        {name: 'event_date', value: this.variant.Specs.Date.Value},
        {name: 'deliver_date', value: this.variant.Specs.Date10.Value},
        {name: 'comment', value: this.variant.Specs.Date30.Value},
        {name: 'approval_url', value: approval_url}
      ];
      mgSubmittedInternalEmail.Html = await this.mailTemplateService
        .replaceValues('./assets/html/mgSubmittedInternal.html', mailTemplateValues);
      this.mailerService.sendMessage(mgSubmittedInternalEmail);
    }
  }

  ngbDateToString(object: NgbDate) {
    return (object) ? object.year + '-' + object.month + '-' + object.day : '';
  }
}
