import {Component, Input, OnDestroy} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {states} from '../../assets/ts/states';
import {NgForm} from '@angular/forms';

@Component({
  selector: 'app-ship-to',
  templateUrl: './ship-to.component.html',
  styles: [`
      .close {
          outline: none
      }
  `]
})

export class ShipToComponent {
  @Input() shipAddress: any;
  private states = states;

  constructor(public activeModal: NgbActiveModal) {}

  save(f: NgForm) {
    if (f.valid) {
      this.activeModal.close(this.shipAddress);
    }
  }
}
