import {Injectable} from '@angular/core';
import {EventInfo} from '../../models/event-info';
import {cobrands} from '../../../assets/ts/cobrand';
import {NgbDate} from '@ng-bootstrap/ng-bootstrap';
import {Four51Service} from '../../four51.service';
import {fontStyles} from '../../../assets/ts/font-styles';

@Injectable({
  providedIn: 'root'
})
export class InvitationService {
  private long_description: string;
  constructor (private four51Service: Four51Service) {}
  async refresh(variantToUpdate: any, eventInfo: EventInfo) {
    this.htmlToPageflex(eventInfo.long_description_html);
    const softReturn = String.fromCharCode(8232);
    const data = {
      topicId: eventInfo.topicId,
      byoSelectedTopicId: eventInfo.byoSelectedTopicId,
      sessions: eventInfo.sessions,
      physicianId: eventInfo.physicianId,
      email: eventInfo.email,
      memberServiceState: eventInfo.memberServiceState,
      membershipTier: eventInfo.membershipTier,
      pdmEmail: eventInfo.pdmEmail,
      physicianStatusType: eventInfo.physicianStatusType,
      practiceStatus: eventInfo.practiceStatus,
      ptmEmail: eventInfo.ptmEmail,
      relationshipStatus: eventInfo.relationshipStatus,
      staffEmail: eventInfo.staffEmail,
      rsvpOnline: eventInfo.rsvpOnline,
      roomCapacity: eventInfo.roomCapacity,
      eligibleProspects: eventInfo.eligibleProspects,
      pastPatients: eventInfo.pastPatients,
      shippingAddressID: eventInfo.shippingAddressID,
      secondShipToAddress: eventInfo.secondShipToAddress,
      secondShippingAddressID: eventInfo.secondShippingAddressID,
      comments: eventInfo.comments,
      commentsName: eventInfo.commentsName,
      commentsPhone: eventInfo.commentsPhone,
      channel: eventInfo.channel,
      type: eventInfo.type
    };
    const cobrandFilter = cobrands.filter(x => x.physicianId === eventInfo.physicianId);
    let cobrand;
    let cobrandImage;
    if (cobrandFilter.length > 0) {
      cobrand = cobrandFilter[0];
      switch (cobrand.cobrand) {
        case 'Heritage Medical Associates':
          cobrandImage = 'Heritage logo_green_flyer.pdf';
          break;
        case 'Mountain View Medical Group, P.C.':
          cobrandImage = 'MountainView_cobrand text_flyer.pdf';
          break;
        case 'Nashville Medical Group, An Affiliate of Baptist Hospital':
          cobrandImage = 'NashvilleMedicalGroup_logo_flyer.pdf';
          break;
        case 'North Shore Physicians Group':
          cobrandImage = 'NSPG Logo_4C_flyer.pdf';
          break;
        case 'Piedmont Physicians':
          cobrandImage = 'Piedmont_cobrand text_flyer.pdf';
          break;
        default:
          cobrandImage = '';
          break;
      }
    }
    // console.log(cobrandImage);
    let pateint_guests = eventInfo.patient_guests;
    let productInteropID;
    switch (eventInfo.type) {
      case 'yoch': {
        productInteropID = 'MDVIP-InvitationYOCH';
        pateint_guests = 'Red-Box_YOCH.pdf';
        break;
      }
      default: {
        productInteropID = 'MDVIP-Invitation';
        break;
      }
    }
    const eventTimeZone = eventInfo.sessions[0].eventStartTime.timeZone;
    const variant: any = {
      ProductInteropID: productInteropID,
      Specs: {
        fullName: {
          Value: eventInfo.fullName
        },
        LastName: {
          Value: eventInfo.LastName
        },
        Specialty: {
          Value: eventInfo.Specialty
        },
        Address: {
          Value: eventInfo.Address
        },
        Address2: {
          Value: eventInfo.Address2
        },
        City: {
          Value: eventInfo.City
        },
        State: {
          Value: eventInfo.State
        },
        Zip: {
          Value: eventInfo.Zip
        },
        Phone: {
          Value: eventInfo.Phone
        },
        fax: {
          Value: eventInfo.fax
        },
        Website: {
          Value: eventInfo.Website
        },
        facebook: {
          Value: eventInfo.facebook
        },
        twitter: {
          Value: eventInfo.twitter
        },
        coBrandingInfo: {
          Value: cobrand
        },
        coBrandingImage: {
          Value: cobrandImage
        },
        logo: {
          Value: eventInfo.logo
        },
        title: {
          Value: eventInfo.title
        },
        intro: {
          Value: eventInfo.intro
        },
        header_image: {
          Value: eventInfo.header_image
        },
        long_description: {
          Value: this.long_description
        },
        long_description_html: {
          Value: eventInfo.long_description_html
        },
        brief_description: {
          Value: eventInfo.brief_description
        },
        speaker: {
          Value: eventInfo.speaker
        },
        speakervs: {
          Value: eventInfo.speakervs
        },
        patient_guests: {
          Value: pateint_guests
        },
        event_date: {
          Value: this.ngbDateToString(eventInfo.sessions[0].eventDate)
        },
        event_start_time: {
          Value: eventInfo.sessions[0].eventStartTime.moment.tz(eventTimeZone).format('HH:mm')
        },
        event_date_text: {
          Value: eventInfo.eventDateText.replace(/\n/g,  softReturn)
        },
        venue_name: {
          Value: eventInfo.venue_name
        },
        venue_address_1: {
          Value: eventInfo.venue_address_1
        },
        venue_address_2: {
          Value: eventInfo.venue_address_2
        },
        venue_city: {
          Value: eventInfo.venue_city
        },
        venue_state: {
          Value: eventInfo.venue_state
        },
        venue_zip: {
          Value: eventInfo.venue_zip
        },
        rsvp_phone: {
          Value: eventInfo.rsvp_phone
        },
        rsvp_message: {
          Value: eventInfo.rsvp_message
        },
        venueType: {
          Value: (eventInfo.venueType)
        },
        webinarUrl: {
          Value: eventInfo.webinarUrl
        },
        additionalInformation: {
          Value: eventInfo.additionalInformation.replace(/\n/g,  softReturn)
        },
        rsvpByDate: {
          Value:  eventInfo.rsvpByDate
        },
        data: {
          Value: JSON.stringify(data)
        }
      }
    };
    if (variantToUpdate) {
      variant.InteropID = variantToUpdate.InteropID;
    }
    const response = await this.four51Service.createVariant(variant).toPromise();
    return response.body;
  }
  ngbDateToString(object: NgbDate) {
    return (object) ? object.year + '-' + object.month + '-' + object.day : '';
  }

  htmlToPageflex(html: string) {
    const pfheader = '<?Pageflex pf_xfp_ver="1"?>';
    const column_break = String.fromCharCode(61447);
    this.long_description = pfheader;
    const domparser = new DOMParser();
    const doc = domparser.parseFromString('<div id="content">' + html + '</div>', 'text/html');
    const children = doc.getElementById('content').childNodes;
    for (let i = 0; i < children.length; i++) {
      this.processHtmlElement(children[i]);
    }
    // console.log(this.long_description);
    this.long_description = this.long_description.replace(/\[Column Break\]/g, column_break);
  }

  processHtmlElement(node) {
    // console.log(node.nodeName);
    let lineSpacing = '59959';
    if (node.innerHTML === '[Column Break]') {
      lineSpacing = '0';
    }
    const pfpara = '<PF_Para_Base auto_line_spacing="false" line_spacing="' + lineSpacing + '" rule_before_overprint="false" ' +
      'rule_after_overprint="false" hyph_level="0">';
    const pfparabullet = '<PF_Para_Base auto_line_spacing="false" line_spacing="59959" rule_before_overprint="false" ' +
      'rule_after_overprint="false" hyph_level="0" left_indent="31750" tab_list="x_position=31750 align=left">';
    const pfchar = '<_char font_name="/Proxima Nova Rg" text_color="MDVIP_BRONZE" font_size="37033">';
    const tab = String.fromCharCode(61446);
    const bullet = String.fromCharCode(8226);

    switch (node.nodeName) {
      case '#text':
        this.long_description += node.nodeValue;
        break;
      case 'P':
        this.long_description += pfpara + pfchar ;
        for (let i = 0; i < node.childNodes.length; i++) {
          this.processHtmlElement(node.childNodes[i]);
        }
        this.long_description += '</_char></PF_Para_Base>';
        break;
      case 'UL': case 'OL':
        for (let i = 0; i < node.childNodes.length; i++) {
          this.processHtmlElement(node.childNodes[i]);
        }
        break;
      case 'LI':
        let listCharacter;
        if (node.parentNode.nodeName === 'UL') {
          listCharacter = bullet;
        } else {
          let i = 1;
          let test = node;
          while ( (test = test.previousSibling) != null ) {
            i++;
          }
          listCharacter =  i + '.';
        }
        this.long_description += pfparabullet + pfchar ;
        // this.long_description += listCharacter + tab + node.innerHTML;
        this.long_description += listCharacter + tab;
        for (let i = 0; i < node.childNodes.length; i++) {
          this.processHtmlElement(node.childNodes[i]);
        }
        this.long_description += '</_char></PF_Para_Base>';
        break;
      case 'SPAN':
        const cls = node.getAttribute('class');
        if (cls) {
          if (cls) {
            const fontStyle = fontStyles.find(x => x.class === cls);
            if (fontStyle) {
              this.long_description += '<_char font_name="/' + fontStyle.pffont + '" ';
              if (fontStyle.colorName) {
                this.long_description += 'text_color="' + fontStyle.pfcolor + '" ';
              }
              this.long_description += 'font_size="37033" bold="' + fontStyle.pfbold + '" italic="' + fontStyle.pfitalic + '" >';
            }
          }
        }
        this.long_description += node.innerHTML;
        if (cls) {
          this.long_description += '</_char>';
        }

        break;
    }

  }
}
