import {Injectable, OnDestroy} from '@angular/core';
import {MaskPipe} from 'ngx-mask';
import {Subscription} from 'rxjs';
import {MdvipUser} from './models/mdvip-user';
import {MdvipUserService} from './mdvip-user.service';

@Injectable({
  providedIn: 'root'
})

export class SpecService implements OnDestroy {
  private mdvipUser: MdvipUser;
  private subscription: Subscription;
  constructor(private maskPipe: MaskPipe, private mdvipUserService: MdvipUserService) {
    this.subscription = new Subscription();
    const mdvipUserSubscription = this.mdvipUserService.getMdvipUser().subscribe((user: MdvipUser) => {
      this.mdvipUser = user;
    });
    this.subscription.add(mdvipUserSubscription);
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  public fillInUserData(specs: any): any {
    if (this.mdvipUser) {
      for (const key of Object.keys(specs)) {
        const identifier = this.getSpecLabel(specs[key]).toLowerCase().replace(/(\s|')/g, '');
        switch (identifier) {
          case 'fullname':
          case 'doctorsfullname': {
            specs[key].Value = this.getCustomFieldValue('fullName');
            break;
          }
          case 'lastname':
          case 'doctorslastname': {
            specs[key].Value = this.mdvipUser.LastName;
            break;
          }
          case 'specialty':
          case 'doctorsspecialty': {
            specs[key].Value = this.getCustomFieldValue('Specialty');
            break;
          }
          case 'address':
          case 'address1':
          case 'doctorsaddress1': {
            specs[key].Value = this.getCustomFieldValue('Address');
            break;
          }
          case 'address2':
          case 'doctorsaddress2': {
            specs[key].Value = this.getCustomFieldValue('Address2');
            break;
          }
          case 'city':
          case 'doctorscity': {
            specs[key].Value = this.getCustomFieldValue('City');
            break;
          }
          case 'state':
          case 'doctorsstate': {
            specs[key].Value = this.getCustomFieldValue('State');
            break;
          }
          case 'zip':
          case 'doctorszip': {
            specs[key].Value = this.getCustomFieldValue('Zip');
            break;
          }
          case 'phone':
          case 'doctorsphonenumber': {
            specs[key].Value = this.maskPipe.transform(this.mdvipUser.Phone, '000.000.0000');
            break;
          }
          case 'fax': {
            specs[key].Value = this.maskPipe.transform(this.getCustomFieldValue('fax'), '000.000.0000');
            break;
          }
          case 'website':
          case 'doctorswebpage': {
            specs[key].Value = this.getCustomFieldValue('Website');
            break;
          }
          case 'facebook': {
            specs[key].Value = this.getCustomFieldValue('facebook');
            break;
          }
          case 'doctorspicture': {
            specs[key].Value = 'pe' + this.mdvipUser.LastName +
              this.getCustomFieldValue('physicianId') + '.jpg';
            break;
          }
          case 'certifications': {
            specs[key].Value = this.replaceHtml(this.getCustomFieldValue('Certifications'));
            break;
          }
          case 'medicalschool': {
            specs[key].Value = this.replaceHtml(this.getCustomFieldValue('Medical School'));
            break;
          }
          case 'hospitalaffiliations': {
            specs[key].Value = this.replaceHtml(this.getCustomFieldValue('Hospital Affiliations'));
            break;
          }
        }
      }
    }
    return specs;
  }

  getCustomFieldValue(name): string {
    const field = this.mdvipUser.CustomFields.filter(x => x.Name === name);
    if (field.length > 0) {
      const returnString = field[0].Value || '';
      return returnString;
    } else {
      return '';
    }
  }

  getSpecLabel(spec: any) {
    if (spec.Label) {
      return spec.Label;
    } else {
      return '';
    }
  }

  replaceHtml(string: string): string {
    return string
      .replace(/<br\s?\/?>/g, '\n')
      .replace(/&#(\d+);/g, (match, dec) => {
        return String.fromCharCode(dec);
      });
  }

}
