import {Injectable} from '@angular/core';
import {EventInfo} from '../../models/event-info';
import {Four51Service} from '../../four51.service';

@Injectable({
  providedIn: 'root'
})
export class EventWaiverService {

  constructor(private four51Service: Four51Service) {}

  async refresh(variantToUpdate: any, eventInfo: EventInfo) {
    const data = {
      topicId: eventInfo.topicId,
      byoSelectedTopicId: eventInfo.byoSelectedTopicId,
      sessions: eventInfo.sessions
    };
    const variant: any = {
      ProductInteropID: 'MDVIP-EventWaiver',
      Specs: {
        fullName: {
          Value: eventInfo.fullName
        },
        data: {
          Value: JSON.stringify(data)
        }
      }
    };
    if (variantToUpdate) {
      variant.InteropID = variantToUpdate.InteropID;
    }
    const response = await this.four51Service.createVariant(variant).toPromise();
    return response.body;
  }

}
