import {Injectable} from '@angular/core';
import {EventInfo} from '../../models/event-info';
import {NgbDate} from '@ng-bootstrap/ng-bootstrap';
import {Four51Service} from '../../four51.service';
import * as moment from 'moment-timezone';

@Injectable({
  providedIn: 'root'
})
export class VoiceshotService {

  constructor(private four51Service: Four51Service) {}

  async refresh(variantToUpdate: any, eventInfo: EventInfo) {
    const eventDateMoment = moment(this.ngbDateToString(eventInfo.sessions[0].eventDate), 'YYYY-MM-DD');
    let eventDateText;
    let briefDescriptionText;
    if (eventInfo.sessions.length === 1 ) {
      eventDateText = eventDateMoment.format('dddd, MMMM D');
      briefDescriptionText = eventInfo.brief_description;
    } else {
      eventDateText = 'one of the sessions for our upcoming event. The first session is on ' + eventDateMoment.format('dddd, MMMM D');
      briefDescriptionText = 'For additional sessions offered, check your email invitation or call the office. At the event, ' +
        eventInfo.brief_description.substring(0, 1).toLowerCase() + eventInfo.brief_description.substring(1);
    }
    const data = {
      topicId: eventInfo.topicId,
      byoSelectedTopicId: eventInfo.byoSelectedTopicId,
      sessions: eventInfo.sessions,
      type: eventInfo.type
    };
    let productInteropID;
    switch (eventInfo.type) {
      case 'yoch': {
        productInteropID = 'MDVIP-VoiceShotYOCH';
        break;
      }
      default: {
        productInteropID = 'MDVIP-VoiceShot';
        break;
      }
    }
    const eventTimeZone = eventInfo.sessions[0].eventStartTime.timeZone;
    const variant: any = {
      ProductInteropID: productInteropID,
      Specs: {
        title: {
          Value: eventInfo.title
        },
        LastName: {
          Value: eventInfo.LastName
        },
        speakervs: {
          Value: eventInfo.speakervs
        },
        event_start_time: {
          Value: eventInfo.sessions[0].eventStartTime.moment.tz(eventTimeZone).format('h:mm A')
        },
        event_date_text: {
          Value: eventDateText
        },
        venue_name: {
          Value: eventInfo.venue_name
        },
        venue_address_1: {
          Value: eventInfo.venue_address_1
        },
        venue_address_2: {
          Value: eventInfo.venue_address_2
        },
        venue_city: {
          Value: eventInfo.venue_city
        },
        brief_description: {
          Value: eventInfo.brief_description
        },
        brief_description_text: {
          Value: briefDescriptionText
        },
        rsvp_phone: {
          Value: eventInfo.rsvp_phone
        },
        rsvp_message: {
          Value: eventInfo.rsvp_message
        },
        venueType: {
          Value: (eventInfo.venueType)
        },
        data: {
          Value: JSON.stringify(data)
        }
      }
    };
    if (variantToUpdate) {
      variant.InteropID = variantToUpdate.InteropID;
    }
    const response = await this.four51Service.createVariant(variant).toPromise();
    return response.body;
  }

  ngbDateToString(object: NgbDate) {
    return (object) ? object.year + '-' + object.month + '-' + object.day : '';
  }
}
