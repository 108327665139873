import {
  Component,
  Input, OnInit
} from '@angular/core';
import {NgbActiveModal, NgbDate, NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import {Four51Service} from '../four51.service';
import {CartService} from '../cart.service';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';
import * as moment from 'moment';
import {KnackService} from '../knack.service';
import {OrderLimitResponse, OrderLimitService} from '../order-limit.service';
import {NgForm} from '@angular/forms';
import {NgbDateCustomParserFormatter} from '../dateformat';

@Component({
  selector: 'app-pdf-modal-component',
  template: `
      <div class="modal-header">
          <h4 class="modal-title">Edit {{product.Name}}</h4>
          <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
              <span aria-hidden="true">&times;</span>
          </button>
      </div>
      <div class="modal-body modal-body-proof d-flex no-gutters" *ngIf="product.SpecCount > 0">
          <div class="col-lg-4">

              <div class="form-wrapper">
                <form *ngIf="variant" #membershipGiveawayForm="ngForm" name="membershipGiveawayForm" id="membershipGiveawayForm"
                      (ngSubmit)="onSpecFormSubmit(membershipGiveawayForm)" novalidate>
                    <app-spec-field [submitted]="membershipGiveawayForm.submitted"
                                    [specKeyValue]="{key: 'V00Event_Name', value: variant.Specs['V00Event_Name']}" ></app-spec-field>
                    <div class="form-group">
                        <label for="eventDate">Event Date</label>
                        <div class="input-group">
                            <input class="form-control" placeholder="mm/dd/yyyy"
                                   name="eventDate" id="eventDate" [(ngModel)]="eventDate" [firstDayOfWeek]="7"
                                   ngbDatepicker #ed="ngbDatepicker" #refEventDate="ngModel" requried [readonly]="true"
                                   [class.is-invalid]="refEventDate.errors && (refEventDate.dirty || membershipGiveawayForm.submitted)"
                            />
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary" (click)="ed.toggle()" type="button">
                                    <i class="fa fa-calendar"></i>
                                </button>
                            </div>
                        </div>
                        <small class="text-danger" *ngIf="refEventDate.errors && (refEventDate.dirty || membershipGiveawayForm.submitted)">
                            A value is required
                        </small>
                    </div>
                    <fieldset>
                        <legend>Personalization</legend>
                        <ng-container *ngFor="let specKeyValue of variant.Specs | keyvalue">
                            <app-spec-field *ngIf="!hidden.includes(specKeyValue.key)" [submitted]="membershipGiveawayForm.submitted"
                                            [specKeyValue]="specKeyValue" ></app-spec-field>
                        </ng-container>
                    </fieldset>
                    <button type="button" class="btn btn-primary mr-2" (click)="updateVariant()">
                        <i class="fa fa-refresh"></i> Apply/Refresh
                    </button>
                </form>
              </div>

          </div>
          <div class="col-lg-8">
              <div class="proof-wrapper" *ngIf="imageUrl">
                  <img [src]="imageUrl" (load)="onPreviewLoaded()" (error)="onPreviewError($event)" [alt]="product.Name +
                  'Preview Image'" />
                  <div class="activity d-flex" *ngIf="isWaiting">
                      <div class="spinner-border text-light m-auto" role="status" >
                          <span class="sr-only">Loading...</span>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div class="modal-footer">
              <button type="buton" class="btn btn-primary" (click)="activeModal.close()" [disabled]="!isProofed || isUpdating || isWaiting">
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="isUpdating"></span>
                  Done
              </button>
          <div *ngIf="errorMessage" class="alert alert-danger my-0" [innerHTML]="errorMessage"></div>
      </div>
  `,
  styleUrls: ['../modal.component.scss'],
  styles: [`
    .form-wrapper {
        height: 100%;
        overflow-y: scroll;
        padding: 1.25rem;
    }
    .proof-wrapper {
      height: 100%;
      overflow-y: scroll;
    }
    .activity {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(0,0,0,0.4);
      z-index: 99;
    }`
  ],
  providers: [
    {provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter}
  ]
})
export class MembershipGiveawayEditComponent implements OnInit {

  @Input() product: any;
  @Input() variant: any;
  public isProofed = true;
  private imageUrl;
  private proofUrl;
  public isWaiting: boolean;
  public isUpdating = false;
  public errorMessage: string;

  private hidden = ['V02Purpose', 'V00Event_Name', 'Date', 'Date10', 'Date30', 'data'];
  private eventDate: NgbDate;

  constructor(public activeModal: NgbActiveModal, private four51Service: Four51Service) {}

  ngOnInit(): void {
    this.isWaiting = true;
    this.imageUrl = this.variant.PreviewUrl + '?r=' + Math.random();
    const eventDateMoment: moment.Moment = moment(this.variant.Specs.Date.Value, 'MM/DD/YYYY');
    this.eventDate = new NgbDate(eventDateMoment.year(), eventDateMoment.month() + 1, eventDateMoment.date());
  }
  onPreviewLoaded() {
    this.isWaiting = false;
  }

  onPreviewError(e) {
    console.log(e);
    this.isWaiting = false;
  }

  onSpecFormSubmit(f: NgForm) {
    this.specsChange(f.valid);
  }

  specsChange(isFormValid: boolean) {
    this.isProofed = isFormValid;
    this.updateVariant();
  }

  async updateVariant() {
    this.isWaiting = true;
    this.variant.Specs.Date.Value = moment(this.ngbDateToString(this.eventDate), 'YYYY-MM-DD').format('MM/DD/YYYY');
    this.variant.Specs.Date30.Value = moment(this.ngbDateToString(this.eventDate), 'YYYY-MM-DD').add(30, 'days')
      .format('MMMM D, YYYY');
    const response = await this.four51Service.createVariant(this.variant).toPromise();
    this.variant = response.body;
    this.imageUrl = this.variant.PreviewUrl + '?r=' + Math.random();
  }

  ngbDateToString(object: NgbDate) {
    return (object) ? object.year + '-' + object.month + '-' + object.day : '';
  }
}
