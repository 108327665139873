import {Component, Input} from '@angular/core';
import {ControlContainer, NgForm} from '@angular/forms';
import {states} from '../assets/ts/states';

@Component({
  selector: 'app-ship-address-component',
  templateUrl: './ship-address.component.html',
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})

export class ShipAddressComponent {
  @Input() shipAddress: any;
  @Input() submitted: boolean;
  @Input() isNarrow: boolean;
  @Input() namePrefix = '';
  public states = states;
}
