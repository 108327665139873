import {Injectable, OnDestroy} from '@angular/core';
import {Four51Service} from './four51.service';
import {KnackService} from './knack.service';
import * as moment from 'moment';
import {MdvipUserService} from './mdvip-user.service';
import {MdvipUser} from './models/mdvip-user';
import {Subscription} from 'rxjs';
import {CartService} from './cart.service';

@Injectable({
  providedIn: 'root'
})
export class OrderLimitService implements OnDestroy {
  private mdvipUser: MdvipUser;
  private subscription: Subscription;
  private cart: any;
  constructor(private four51Service: Four51Service, private knackService: KnackService, private mdvipUserService: MdvipUserService,
              private cartService: CartService) {
    this.subscription = new Subscription();
    const mdvipUserSubscription = this.mdvipUserService.getMdvipUser().subscribe((user: MdvipUser) => {
      this.mdvipUser = user;
    });
    this.subscription.add(mdvipUserSubscription);
    const cartSubscription = this.cartService.cartObservable().subscribe(cart => {
      this.cart = cart;
    });
    this.subscription.add(cartSubscription);
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  async getOrderLimit(request: OrderLimitRequest): Promise<OrderLimitResponse> {
    const requestQuantity = +request.quantity; // force numeric value
    let canOrder = true;
    let message = '';
    // console.log(request.product);
    const statuses = ['AwaitingApproval', 'Open', 'Completed'];
    switch (request.product.InteropID) {
      case '76704AAC-DCE0-43B7-90B6-507CB902C2B7': {
        // Travel Advantages Flyer
        canOrder = true;
        message = '';
        // Only one order per seven day period
        // Limit removed 3/11 per Meli
        /* const endDate = moment();
        const startDate = moment().subtract(7, 'days');
        const response: any = await this.four51Service.getOrdersByDateRange(startDate, endDate,
          100).toPromise();
        const orders = response.List.filter(x => statuses.includes(x.Status));
        console.log(orders);
        for (const order of orders) {
          const resp: any = await this.four51Service.getOrderById(order.ID).toPromise();
          if (resp.LineItems.find(x => x.Product.InteropID === request.productID)) {
            canOrder = false;
            message = 'You are only allowed one order per seven day period. Please contact Order Support for any questions.';
            break;
          }
        }*/
        break;
      }
      case '0912CA32-9266-4B02-B4E0-CBBFF3BCF608':   // Wellness Program Folder
      case '992CA18D-BA2E-47C9-8C2E-4B1F3EAC38AF': // Wellness Program Plus Folder
      case '5E078256-4EBE-4E81-83DA-84B7C9FAF65C': { // Women's Health Program Folder
        canOrder = true;
        message = '';
        const endDate = moment();
        const quarterHistory = await this.getHistory(moment().startOf('quarter'), endDate, request.product.InteropID);
        if ((quarterHistory + requestQuantity) > 200) { // check the shorter query first
          canOrder = false;
          message = 'You have reached your limit of 200 folders per quarter. Please contact Order Support for any questions.';
        } else {
          const thisYear = endDate.year();
          const startDate = moment({month: 6, day: 1, year: thisYear} );
          if (startDate.isAfter(endDate)) {
            startDate.add(-1, 'year');
          }
          const four51history = await this.getHistory(startDate, endDate, request.product.InteropID);
          const totalMembers = (this.mdvipUser && this.mdvipUserService.getCustomFieldValue('totalMembers')) ?
            +this.mdvipUserService.getCustomFieldValue('totalMembers') : 1;
          const roundedMembers = Math.ceil(totalMembers / 50) * 50;
          console.log('four51history: ' + four51history + '; totalMembers: ' + totalMembers);
          if ((four51history + requestQuantity) > roundedMembers) {
            canOrder = false;
            message = 'You have reached your limit of ' + roundedMembers + ' folders. Please contact Order Support for any questions.';
          }
        }
        break;
      }
      case '82E756E2-5D8B-441B-B281-473853AFE714':   // Pre-Wellness Checklist
      case 'E93CACCD-C644-4FE8-AF48-23AA8654E09A': { // Pre-Wellness Plus Checklist
        canOrder = true;
        message = '';
        const endDate = moment();
        const thisYear = endDate.year();
        const startDate = moment({month: 6, day: 1, year: thisYear} );
        if (startDate.isAfter(endDate)) {
          startDate.add(-1, 'year');
        }
        const limit = 700;
        const history = await this.getHistory(startDate, endDate, request.product.InteropID);
        if (history + requestQuantity > limit) {
          canOrder = false;
          message = 'You have reached your limit of ' + limit + ' ' + request.product.Name +
            's.<br />Please contact Order Support for any questions.';
        }
        break;
      }
      case '83D31E03-F16E-4FE2-923A-59FFE07C2A8F': { // Wellness Appointment Postcard
        canOrder = true;
        message = '';
        const endDate = moment();
        const thisYear = endDate.year();
        const startDate = moment({month: 6, day: 1, year: thisYear} );
        if (startDate.isAfter(endDate)) {
          startDate.add(-1, 'year');
        }
        const limit = 300;
        const history = await this.getHistory(startDate, endDate, request.product.InteropID);
        if (history + requestQuantity > limit) {
          canOrder = false;
          message = 'You have reached your limit of ' + limit + ' ' + request.product.Name +
            's.<br />Please contact Order Support for any questions.';
        }
        break;
      }
      case 'MD64595': // 2022 Holiday Card
      case 'MD64659-PP': // 2023 Birthday Card
      case 'MD64667-PP': { // 2023 Holiday Card
        canOrder = true;
        message = '';
        const endDate = moment();
        const thisYear = endDate.year();
        const startDate = moment({month: 6, day: 1, year: thisYear} );
        if (startDate.isAfter(endDate)) {
          startDate.add(-1, 'year');
        }
        const four51history = await this.getHistory(startDate, endDate, request.product.InteropID);
        const totalMembers = (this.mdvipUser && this.mdvipUserService.getCustomFieldValue('totalMembers')) ?
          +this.mdvipUserService.getCustomFieldValue('totalMembers') : 1;
        const roundedMembers = Math.ceil(totalMembers / 100) * 100;
        console.log(`four51history: ${four51history}; totalMembers: ${totalMembers}`);
        if ((four51history + requestQuantity) > roundedMembers) {
          canOrder = false;
          message = 'You have reached your limit of ' + roundedMembers + ' cards. Please contact Order Support for any questions.';
        }
        break;
      }
      default: {
        canOrder = true;
        message = '';
        if (request.product.InventoryEnabled) {
          if (requestQuantity > request.product.QuantityAvailable) {
            canOrder = false;
            message = 'There are currently only ' + request.product.QuantityAvailable + ' ' + request.product.Name +
              's available.<br />Please contact Order Support for any questions.';
          }
        }
        break;
      }
    }
    return {
      canOrder: canOrder,
      message: message
    };
  }

  async getHistory(startDate: moment.Moment, endDate: moment.Moment, productID: string) {
    let ordered = 0;
    const orderStats: any = await this.four51Service.getOrderStats().toPromise();
    const orderCount = orderStats.reduce((sum, orderStat) => sum + orderStat.Count, 0);
    const lineItemSum = (sum, lineItem) => {
      if (lineItem.Product.InteropID === productID) {
        return sum + lineItem.Quantity;
      } else {
        return sum;
      }
    };
    if (orderCount > 0) {
      const statuses = ['AwaitingApproval', 'Open', 'Completed'];
      const response: any = await this.four51Service.getOrdersByDateRange(startDate, endDate,
        orderCount).toPromise();
      const orders: any = response.List.filter(x => statuses.includes(x.Status));
      await Promise.all(orders.map(async order => {
        order = await this.four51Service.getOrderById(order.ID).toPromise();
        ordered += order.LineItems.reduce(lineItemSum, 0);
      }));
    }
    if (this.cart) {
      ordered += this.cart.LineItems.reduce(lineItemSum, 0);
    }
    // console.log(ordered);
    return ordered;
  }

  async testing() {
    const orderStats: any = await this.four51Service.getOrderStats().toPromise();
    console.log(orderStats);
    const orders: any[] = [];
    const startDate = moment('2019-07-01', 'YYYY-MM-DD');
    const endDate = moment();
    for (const orderStat of orderStats) {
      if (orderStat.Count > 0 && (orderStat.Status === 'AwaitingApproval' || orderStat.Status === 'Open'
        || orderStat.Status === 'Completed')) {
        const response: any = await this.four51Service.getOrdersByDateRange(startDate, endDate,
          orderStat.Count, orderStat.Status).toPromise();
        orders.push(...response.List);
      }
    }
    console.log(orders);
    const practiceData: any = await this.knackService.getMemberCount('3740').toPromise();
    console.log(practiceData.patientCount);

  }

}
export interface OrderLimitResponse {
  canOrder: boolean;
  message: string;
}
export interface OrderLimitRequest {
  product: any;
  quantity: number;
}
