import {Component, OnInit} from '@angular/core';
import * as moment from 'moment';
import {Four51Service} from '../four51.service';
import {HttpClient} from '@angular/common/http';
import { Papa } from 'ngx-papaparse';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-crm-report-component',
  template: `
      <nav class="navbar navbar-dark navbar-expand">
          <div class="container">
              <div class="form-inline">
                  <div class="form-check mr-3">
                      <input class="form-check-input" type="checkbox" name="shouldFilter" [(ngModel)]="shouldFilter"
                             (change)="updateFilter()"/>
                      <label class="text-light" for="shouldFilter">Filter by Event Date</label>
                  </div>
              </div>
              <app-date-range-component *ngIf="shouldFilter" [fromMoment]="fromDate" [toMoment]="toDate"
                                        (dateRangeChange)="onDateRangeChange($event)"></app-date-range-component>
              <ul class="navbar-nav ml-auto">
                  <li class="nav-item">
                      <button class="btn btn-primary" type="button" (click)="downloadCsv()"><i class="fa fa-download"></i> CSV</button>
                  </li>
              </ul>
          </div>
      </nav>
      <div class="report-wrapper">
          <table class="table">
              <thead>
              <tr>
                  <th>PO#</th>
                  <th>Order #</th>
                  <th>Amount</th>
                  <th>Order Submit Date</th>
                  <th>Event Date</th>
                  <th>Lead Time</th>
                  <th>Date Approved</th>
                  <th>Physician Name</th>
                  <th>Topic Name</th>
                  <th>YOCH</th>
                  <th>BYOT</th>
                  <th>Event Title (For BYOT)</th>
                  <th>Auto-approved</th>
                  <th>PDM</th>
                  <th>PTM</th>
                  <th>Practice Status</th>
                  <th>Prospects</th>
                  <th>Past Patients</th>
                  <th>Month</th>
                  <th>Fiscal Year</th>
                  <th>platform</th>
                  <th>relationshipstatus</th>
                  <th>physicianstatustype</th>
                  <th>Physician ID</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let crmReportLine of crmReportLinesFiltered">
                  <td>{{crmReportLine.PoNumber}}</td>
                  <td class="text-nowrap">{{crmReportLine.OrderNumber}}</td>
                  <td>{{crmReportLine.Amount}}</td>
                  <td class="text-nowrap">{{crmReportLine.OrderSubmitDate | date: 'MM/dd/yyyy hh:mm a'}}</td>
                  <td>{{crmReportLine.EventDate | date: 'MM/dd/yyyy'}}</td>
                  <td>{{crmReportLine.LeadTime}}</td>
                  <td class="text-nowrap">{{crmReportLine.DateApproved | date: 'MM/dd/yyyy hh:mm a'}}</td>
                  <td>{{crmReportLine.PhysicianName}}</td>
                  <td>{{crmReportLine.TopicName}}</td>
                  <td>{{crmReportLine.YOCH}}</td>
                  <td>{{crmReportLine.BYOT}}</td>
                  <td>{{crmReportLine.EventTitle}}</td>
                  <td>{{crmReportLine.AutoApproved}}</td>
                  <td>{{crmReportLine.PDM}}</td>
                  <td>{{crmReportLine.PTM}}</td>
                  <td>{{crmReportLine.PracticeStatus}}</td>
                  <td>{{crmReportLine.Prospects}}</td>
                  <td>{{crmReportLine.PastPatients}}</td>
                  <td>{{crmReportLine.Month}}</td>
                  <td>{{crmReportLine.FiscalYear}}</td>
                  <td>{{crmReportLine.Platform}}</td>
                  <td>{{crmReportLine.RelationshipStatus}}</td>
                  <td>{{crmReportLine.PhysicianStatusType}}</td>
                  <td>{{crmReportLine.PhysicianID}}</td>
              </tr>
              </tbody>
          </table>
      </div>
      <div class="activity d-flex" *ngIf="isWaiting">
          <div class="spinner-border text-light m-auto" role="status" >
              <span class="sr-only">Loading...</span>
          </div>
          <p *ngIf="progress"><ngb-progressbar  type="secondary" [value]="progress"></ngb-progressbar></p>
      </div>
  `,
  styles: [`
    th {
        white-space: nowrap;
    }
    .report-wrapper {
        width: 100%;
        overflow-x: auto;
    }
    .activity {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0,0,0,0.4);
        z-index: 99;
    }
    .activity p {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 200px;
        height: 16px;
        margin: 40px 0 0 -100px;
    }
    .navbar-dark {
        background-color: #787878;
    }
  `]
})
export class CrmReportComponent implements OnInit {
  private crmReportLines: any[] = [];
  public crmReportLinesFiltered = this.crmReportLines;
  public shouldFilter = false;
  public isWaiting = false;
  private progress = 0;
  private currentRecord: number;
  private totalRecrords: number;
  private membershipGiveawayProductId = 'A678D8E2-AD56-4B10-BB5B-08142796E227';
  private fromDate: moment.Moment = moment();
  private toDate: moment.Moment = moment();
  private topics: any;

  constructor(private four51Service: Four51Service, private http: HttpClient, private papa: Papa) {}

  async ngOnInit() {
    this.isWaiting = true;
    // need topics to display original BYOT chosen topic before editing
    this.topics = await this.http.get('./assets/json/topics.json').toPromise();
    const orderStats: any = await this.four51Service.getOrderStats().toPromise();
    // console.log(orderStats);
    const orders: any[] = [];
    const startDate = moment('0001-01-01', 'YYYY-MM-DD');
    const endDate = startDate.clone();
    for (const orderStat of orderStats) {
      if (orderStat.Count > 0 && (orderStat.Status === 'Open'
        || orderStat.Status === 'Completed')) {
        for (let i = 1; i <= Math.ceil(orderStat.Count / 100); i++) {
          const response: any = await this.four51Service.getOrdersPaged(orderStat.Status, i, 100).toPromise();
          orders.push(...response.List);
        }
      }
    }
    this.totalRecrords = orders.length;
    this.currentRecord = 0;

    // sends too many requests too quickly
    /*await Promise.all(orders.map(result => {
      return this.getOrderDetail(result.ID);
    }));*/

    // wait for each one to finish works but takes long
    /*for (const order of orders) {
      await this.getOrderDetail(order.ID);
    }*/

    // wait 50 miliseconds between each request
    for (const order of orders) {
      this.getOrderDetail(order.ID);
      await new Promise(resolve => setTimeout(resolve, 50));
    }
    this.isWaiting = false;
  }

  async getOrderDetail(orderID) {
    const order: any = await this.four51Service.getOrderById(orderID).toPromise();
    let membershipGiveawayLineItem;
    const invitationLineItem = order.LineItems
      .find(x => (x.Product.InteropID === 'MDVIP-Invitation' || x.Product.InteropID === 'MDVIP-InvitationYOCH'));
    if (!invitationLineItem) {
      membershipGiveawayLineItem = order.LineItems
        .find(x => x.Product.InteropID === this.membershipGiveawayProductId);
    }
    if (invitationLineItem || membershipGiveawayLineItem) {
      const lineItem = invitationLineItem || membershipGiveawayLineItem;
      let specs;
      if (lineItem.Variant.Specs) {
        specs = lineItem.Variant.Specs;
      } else {
        specs = lineItem.Specs;
      }
      const dataText = (specs.data) ? specs.data.Value : '{}';
      let data;
      try {
        data = JSON.parse(dataText);
      } catch (e) {
        data = {};
      }
      const crmReportLine: any = {};
      crmReportLine.PoNumber = '';
      crmReportLine.OrderNumber = order.ExternalID;
      crmReportLine.Amount = order.Total;
      if (order.DateSubmittedForApproval) {
        // order was placed by regular user and needed approval
        crmReportLine.OrderSubmitDate = moment(order.DateSubmittedForApproval);
      } else {
        // order was placed by approver and did not need approval
        crmReportLine.OrderSubmitDate = moment(order.DateSubmitted);
      }
      crmReportLine.PDM = data.pdmEmail || 'N/A';
      crmReportLine.PTM = data.ptmEmail || 'N/A';
      crmReportLine.PracticeStatus = data.practiceStatus || '';
      crmReportLine.RelationshipStatus = data.relationshipStatus || '';
      crmReportLine.PhysicianStatusType = data.physicianStatusType || '';
      crmReportLine.PhysicianID = data.physicianId;
      crmReportLine.Platform = data.channel;
      crmReportLine.DateApproved = moment(order.DateSubmitted);
      if (invitationLineItem) {
        if (specs.event_date.Value.length > 0) {
          crmReportLine.EventDate = moment(specs.event_date.Value, 'YYYY-M-D');
        }
        crmReportLine.PhysicianName = specs.fullName.Value || '';
        if (data.byoSelectedTopicId) {
          crmReportLine.TopicName = this.topics.find(x => x.id === data.byoSelectedTopicId).title;
        } else {
          crmReportLine.TopicName = specs.title.Value || '';
        }
        crmReportLine.YOCH = (data.type === 'yoch') ? 'Yes' : '';
        crmReportLine.BYOT = (data.topicId === 10 || data.topicId === 37) ? 'Yes' : '';
        crmReportLine.EventTitle = (data.byoSelectedTopicId) ? specs.title.Value : '';
        crmReportLine.AutoApproved = (order.Approvals.length === 0) ? 'Yes' : 'No';
        crmReportLine.Prospects = this.yesNoOrEmpty(data.eligibleProspects);
        crmReportLine.PastPatients = this.yesNoOrEmpty(data.pastPatients);
      } else { // membershipGiveawayLineItem
        if (specs.Date.Value.length > 0) {
          crmReportLine.EventDate = moment(specs.Date.Value, 'MM/DD/YYYY');
        }
        crmReportLine.TopicName = 'Membership Giveaway';
        crmReportLine.PhysicianName = specs.V03Full_Name.Value;
      }
      crmReportLine.LeadTime = crmReportLine.EventDate.diff(crmReportLine.OrderSubmitDate, 'days');
      crmReportLine.Month = crmReportLine.EventDate.format('MMMM');
      let fiscalFrom = crmReportLine.EventDate.year();
      if (crmReportLine.EventDate.month() < 6) {
        fiscalFrom = fiscalFrom - 1;
      }
      crmReportLine.FiscalYear = fiscalFrom + '-' + (fiscalFrom + 1) ;
      this.crmReportLines.push(crmReportLine);
      if (crmReportLine.EventDate.isBefore(this.fromDate)) {
        this.fromDate = crmReportLine.EventDate;
      }
      if (crmReportLine.EventDate.isAfter(this.toDate)) {
        this.toDate = crmReportLine.EventDate;
      }
    }
    this.currentRecord ++;
    this.progress = Math.floor((this.currentRecord / this.totalRecrords) * 100);
  }
  yesNoOrEmpty(str: string): string {
    if (str) {
      switch (str.toLowerCase()) {
        case 'yes': {
          return 'Yes';
        }
        case 'no': {
          return 'No';
        }
      }
    }
    return '';
  }
  downloadCsv() {
    // console.log(this.crmReportLinesFiltered);
    const csv = this.papa.unparse(this.crmReportLinesFiltered
      .map(r => {
        const row = {...r};
        row.OrderSubmitDate = row.OrderSubmitDate.format('MM/DD/YYYY HH:mm A');
        row.EventDate = row.EventDate.format('MM/DD/YYYY');
        row.DateApproved = row.DateApproved.format('MM/DD/YYYY HH:mm A');
        return row;
      }), {
      quotes: true, // or array of booleans
      quoteChar: '"',
      escapeChar: '"',
      delimiter: ',',
      header: true,
      newline: '\r\n',
      skipEmptyLines: false, // or 'greedy',
      columns: ['PoNumber', 'OrderNumber', 'Amount', 'OrderSubmitDate', 'EventDate', 'LeadTime', 'DateApproved',
        'PhysicianName', 'TopicName', 'YOCH', 'BYOT', 'EventTitle', 'PDM', 'PTM', 'PracticeStatus', 'Prospects', 'PastPatients',
        'Month', 'FiscalYear', 'Platform', 'RelationshipStatus', 'PhysicianStatusType', 'PhysicianID'] // or array of strings
    });
    const blob = new Blob([csv], {type: 'text/csv' })
    FileSaver.saveAs(blob, 'crmReport.csv');
    // console.log(csv);
  }
  onDateRangeChange(e) {
    this.fromDate = e.from;
    this.toDate = e.to;
    this.updateFilter();
  }
  updateFilter() {
    if (this.shouldFilter) {
      if (this.fromDate.isValid() && this.toDate.isValid()) {
        this.crmReportLinesFiltered = this.crmReportLines
          .filter(x => x.EventDate.isBetween(this.fromDate, this.toDate, null, '[]'));
      }
    } else {
      this.crmReportLinesFiltered = [...this.crmReportLines];
    }
  }
}
