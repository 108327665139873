import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MailTemplateService {
  constructor(private http: HttpClient) {}
  public async replaceValues(templateUrl: string, mailTemplateValues: MailTemplateValue[]): Promise<string> {
    let template =  await this.http.get(templateUrl, {responseType: 'text'}).toPromise();
    for (const mailTemplateValue of mailTemplateValues) {
      template = this.replacePlaceholder(template, mailTemplateValue.name, mailTemplateValue.value);
    }
    return template;
  }
  private replacePlaceholder(text: string, placeholder: string, value: string): string {
    const regExp = new RegExp('\\[\\[' + placeholder + '\\]\\]', 'g');
    return text.replace(regExp, value);
  }
}
export interface MailTemplateValue {
  name: string;
  value: string;
}
