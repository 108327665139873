import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NgbCalendar, NgbDate, NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import {NgbDateCustomParserFormatter} from './dateformat';
import * as moment from 'moment';

@Component({
  selector: 'app-date-range-component',
  template: `
  <div class="form-inline">
  <div class="form-group hidden">
      <div class="input-group">
          <input name="datepicker"
                 class="form-control"
                 ngbDatepicker
                 #datepicker="ngbDatepicker"
                 [autoClose]="'outside'"
                 (dateSelect)="onDateSelection($event)"
                 [displayMonths]="2"
                 [dayTemplate]="t"
                 outsideDays="hidden"
                 [startDate]="fromDate">
          <ng-template #t let-date let-focused="focused">
    <span class="custom-day"
          [class.focused]="focused"
          [class.range]="isRange(date)"
          [class.faded]="isHovered(date) || isInside(date)"
          (mouseenter)="hoveredDate = date"
          (mouseleave)="hoveredDate = null">
      {{ date.day }}
    </span>
          </ng-template>
      </div>
  </div>
  <div class="form-group">
      <div class="input-group">
          <input #dpFromDate
                 class="form-control" placeholder="yyyy-mm-dd"
                 name="dpFromDate"
                 [value]="formatter.format(fromDate)"
                 (input)="fromDate = validateInput(fromDate, dpFromDate.value)">
          <div class="input-group-append">
              <button class="btn btn-outline-light" (click)="datepicker.toggle()"
                      type="button"><i class="fa fa-calendar"></i></button>
          </div>
      </div>
  </div>
  <div class="form-group ml-2">
      <div class="input-group">
          <input #dpToDate
                 class="form-control" placeholder="yyyy-mm-dd"
                 name="dpToDate"
                 [value]="formatter.format(toDate)"
                 (input)="toDate = validateInput(toDate, dpToDate.value)">
          <div class="input-group-append">
              <button class="btn btn-outline-light" (click)="datepicker.toggle()"
                      type="button"><i class="fa fa-calendar"></i></button>
          </div>
      </div>
  </div>
  </div>
  `,
  styles: [`
    .form-group.hidden {
      width: 0;
      margin: 0;
      border: none;
      padding: 0;
    }
    .custom-day {
      text-align: center;
      padding: 0.185rem 0.25rem;
      display: inline-block;
      height: 2rem;
      width: 2rem;
    }
    .custom-day.focused {
      background-color: #e6e6e6;
    }
    .custom-day.range, .custom-day:hover {
      background-color: #de6400;
      color: white;
    }
    .custom-day.faded {
      background-color: rgba(222, 100, 0, 0.5);
    }
  `],
  providers: [
    {provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter}
  ]
})
export class DateRangeComponent {
  hoveredDate: NgbDate;
  fromDate: NgbDate;
  toDate: NgbDate;
  @Input()
  set fromMoment(fromMoment: moment.Moment) {
    if (fromMoment.isValid()) {
      this.fromDate = new NgbDate(fromMoment.year(), fromMoment.month() + 1, fromMoment.date());
    }
  }
  @Input()
  set toMoment(toMoment: moment.Moment) {
    if (toMoment.isValid()) {
      this.toDate = new NgbDate(toMoment.year(), toMoment.month() + 1, toMoment.date());
    }
  }
  @Output() dateRangeChange = new EventEmitter();
  constructor(private calendar: NgbCalendar, public formatter: NgbDateParserFormatter) {}
  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
    console.log(this.toDate);
    this.dateRangeChange.emit({from: this.ngbDateToMoment(this.fromDate), to: this.ngbDateToMoment(this.toDate)});
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date) || this.isHovered(date);
  }

  validateInput(currentValue: NgbDate, input: string): NgbDate {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

  ngbDateToMoment(date: NgbDate): moment.Moment {
    return moment(this.formatter.format(date), 'MM/DD/YYYY');
  }
}
