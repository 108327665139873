import {
  Component,
  Input
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'pdf-modal-component',
  template: `
      <div class="modal-header">
          <h4 class="modal-title">{{title}}</h4>
          <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
              <span aria-hidden="true">&times;</span>
          </button>
      </div>
      <div class="modal-body">
          <pdf-viewer [page]="page" [show-all]="false" [src]="pdfSrc" [render-text]="true" [fit-to-page]="true" [zoom]="zoom"
                      (after-load-complete)="afterLoadComplete($event)" class="events-pdf-viewer"></pdf-viewer>
      </div>
      <div class="modal-footer">
          <div class="mr-auto">
              <a href="#" (click)="pageDown($event)"><i class="fa fa-arrow-left"></i></a>
              Page {{page}} of {{pages}}
              <a href="#" (click)="pageUp($event)"><i class="fa fa-arrow-right"></i></a>
          </div>
          <div class="ml-auto">
              <a (click)="zoomOut()"><i class="fa fa-search-minus fa-lg"></i></a>
              Zoom
              <a (click)="zoomIn()"><i class="fa fa-search-plus fa-lg"></i></a>
          </div>
      </div>
  `,
  styleUrls: ['./pdf-modal.component.scss']
})
export class PdfModalComponent {

  @Input() title: string;
  @Input() pdfSrc: string;
  public zoom = 1.0;
  public page = 1;
  public pages = 0;
  constructor(public activeModal: NgbActiveModal) {}

  zoomIn() {
    this.zoom = Math.round(this.zoom * 10 + 1) / 10;
  }

  zoomOut() {
    if (this.zoom > 0.5) {
      this.zoom = Math.round(this.zoom * 10 - 1) / 10;
    }
  }

  pageDown(e) {
    e.preventDefault();
    if (this.page > 1) {
      this.page -= 1;
    }
  }

  pageUp(e) {
    e.preventDefault();
    if (this.page < this.pages) {
      this.page += 1;
    }
  }

  afterLoadComplete(e) {
    const pages = e._pdfInfo.numPages;
    this.pages = pages;
  }
}

