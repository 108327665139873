import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CookieService} from 'ngx-cookie-service';
import {MdvipUserService} from '../mdvip-user.service';
import {MdvipUserCookie} from '../models/mdvip-user-cookie';
import {Four51Service} from '../four51.service';
import {Subscription} from 'rxjs';
import {MdvipUser} from '../models/mdvip-user';
import * as moment from 'moment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit, OnDestroy {

  public username;
  public password;
  private subscription: Subscription;

  constructor(private route: ActivatedRoute, private cookieService: CookieService, private mdvipUserService: MdvipUserService,
              private four51Service: Four51Service, protected router: Router) {}

  ngOnInit() {
    this.subscription = new Subscription();
    const mdvipUserSubscription = this.mdvipUserService.getMdvipUser().subscribe((user: MdvipUser) => {
      if (user) {
        if (this.route.snapshot.params.redirectUrl) {
          this.router.navigate([this.route.snapshot.params.redirectUrl], { replaceUrl: true });
        } else {
          this.router.navigate(['/home'], { replaceUrl: true });
        }
      }
    });
    this.subscription.add(mdvipUserSubscription);
    if (this.route.snapshot.queryParams.token) {
      this.cookieService.delete('mdvip.user');
      this.cookieService.delete('mdvip.channel');
      this.mdvipUserService.refreshUser(this.route.snapshot.queryParams.token);
    } /*else if (this.cookieService.check('mdvip.user')) {
      const mdvipUserCookie: MdvipUserCookie = JSON.parse(this.cookieService.get('mdvip.user'));
      this.mdvipUserService.refreshUser(mdvipUserCookie.Auth);
    }*/
    if (this.route.snapshot.queryParams.channel) {
      const future = moment().add(1 , 'days');
      this.cookieService.set('mdvip.channel', this.route.snapshot.queryParams.channel, future.toDate() );
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  login() {
    this.four51Service.logIn(this.username, this.password).subscribe(response => {
      const token = response.headers.get('www-authenticate');
      this.mdvipUserService.refreshUser(token);
      const future = moment().add(1 , 'days');
      this.cookieService.set('mdvip.channel', 'Login', future.toDate() );
    });
  }

}
