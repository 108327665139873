import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Observable, BehaviorSubject} from 'rxjs';
import {MdvipUser} from './models/mdvip-user';
import {CookieService} from 'ngx-cookie-service';
import {Four51Service} from './four51.service';
import {MdvipUserCookie} from './models/mdvip-user-cookie';
import * as moment from 'moment';
import {TokenService} from './token.service';

@Injectable({
  providedIn: 'root'
})
export class MdvipUserService {
  private mdvipUser: MdvipUser;
  private mdvipUserCookie: MdvipUserCookie;
  private mdvipUserSubject: BehaviorSubject<MdvipUser> = new BehaviorSubject(null);
  private lastLogoutTime = moment();
  public getMdvipUser(): Observable<MdvipUser> {
    return this.mdvipUserSubject.asObservable();
  }
  constructor(private cookieService: CookieService, protected four51Service: Four51Service, private router: Router,
              private tokenService: TokenService) {}

  public refreshUser(token) {
    const requestTime = moment();
    this.four51Service.getUser(token)
      .subscribe(user => {
        // don't allow template user
        if (user.Username === 'TemplateUserMDVIP') {
          this.logout(false);
        } else {
          if (requestTime.isAfter(this.lastLogoutTime)) { // in case a logout happened after this request
            this.mdvipUser = user;
            this.tokenService.token = token;
            // this.mdvipUserSubject.next(this.mdvipUser);
            this.mdvipUserCookie = new MdvipUserCookie();
            this.mdvipUserCookie.Username = this.mdvipUser.Username;
            this.mdvipUserCookie.InteropID = this.mdvipUser.InteropID;
            this.mdvipUserCookie.FirstName = this.mdvipUser.FirstName;
            this.mdvipUserCookie.LastName = this.mdvipUser.LastName;
            this.mdvipUserCookie.Email = this.mdvipUser.Email;
            this.mdvipUserCookie.Groups = this.mdvipUser.Groups;
            this.mdvipUserCookie.Auth = token;
            const future = moment().add(1 , 'days');
            this.cookieService.set('mdvip.user', JSON.stringify(this.mdvipUserCookie), future.toDate(), '/' );
            this.mdvipUserSubject.next(this.mdvipUser);
            // this.router.navigate(['/home']);
          }
        }
      }, error => {
        if (error.status === 401) {
          this.logout(true);
        } else {
          console.log('error getting user ' + error.message);
        }
      });
  }

  logout(shouldRedirectToLogin: boolean) {
    this.lastLogoutTime = moment();
    this.mdvipUser = null;
    console.log('killing cookie');
    this.cookieService.delete('mdvip.user', '/');
    this.cookieService.delete('mdvip.channel');
    if (this.cookieService.check('mdvip.user')) {
      console.log('but cookie still exists');
    }
    this.tokenService.token = null;
    this.mdvipUserSubject.next(this.mdvipUser);
    if (shouldRedirectToLogin) {
      this.router.navigate(['/login'], { replaceUrl: true });
    }
  }

  public getCustomFieldValue(name): string {
    const field = this.mdvipUser.CustomFields.filter(x => x.Name === name);
    if (field.length > 0) {
      return field[0].Value || '';
    } else {
      return '';
    }
  }

}
