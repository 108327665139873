import {ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { Papa } from 'ngx-papaparse';
import {Subscription} from 'rxjs';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {KeyValue} from '@angular/common';
import {Four51Service} from '../four51.service';
import {MdvipUserService} from '../mdvip-user.service';
import {environment} from '../../environments/environment';

@Component({
  selector: 'dinner-marketing-kit-component',
  templateUrl: './dinner-marketing-kit.component.html',
  styleUrls: ['./dinner-marketing-kit.component.scss']
})

export class DinnerMarketingKitComponent implements OnInit, OnDestroy {

  public token;
  private subscription: Subscription;
  private step = 1;
  private records: any[];
  private error;
  private csvFileName;
  private csvFileBase64;
  private page;

  private colPhysicianAccountName;
  private colFirstName;
  private colLastName;
  private colSuffix;
  private colAddress;
  private colCity;
  private colState;
  private colPhone;

  private showData = false;

  private pdfFiles: any[] = [];
  public isWaiting = false;
  private questionnaireVariant: any;
  private questionnaireImageUrl: string;

  private order: any;

  public environment = environment;

  constructor(private papa: Papa, private route: ActivatedRoute,  private router: Router, private http: HttpClient,
              private four51Service: Four51Service, private mdvipUserService: MdvipUserService) {}

  async ngOnInit() {
    this.mdvipUserService.logout(false);
    const token = this.route.snapshot.queryParams.token;
    if (token) {
      const user = await this.four51Service.getUser(token).toPromise();
      if (user.Username !== 'TemplateUserMDVIP') {
        this.token = token;
      }
    }
    this.subscription = new Subscription();
    const paramMapSubscription = this.route.paramMap.subscribe((params: ParamMap) => {
      const step = params.get('step');
      if (step) {
        this.step = +step;
      }
    });
    this.subscription.add(paramMapSubscription);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  goToStep(f, step: number) {
    let ok: boolean;
    if (f) {
      ok = f.valid;
    } else {
      ok = true;
    }
    if (ok) {
      this.router.navigate(['/dinnermarketingkit', {step: step}]);
      if (step === 3) {
        this.showQuestionnaireProof();
        this.showUploadProofs();
      }
    }
  }

  csvFileChange(event) {
    const fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      const file: File = fileList[0];
      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        const fileText = fileReader.result;
        this.csvFileBase64 = btoa(unescape(encodeURIComponent(fileText.toString())));
        this.csvFileName = file.name;
        this.papa.parse(fileText.toString(), {
          header: true,
          skipEmptyLines: true,
          complete: (result: any) => {
            console.log(result);
            if (result.data.length > 0) {
              this.records = result.data;
              this.setColumnDefaults();
            } else {
              this.error = 'This does not appear to be a valid CSV file.';
            }
          }
        });
      };
      fileReader.readAsText(file);
    }
  }

  setColumnDefaults() {
    this.colPhysicianAccountName = Object.keys(this.records[0]).indexOf('Physician Account Name');
    this.colFirstName = Object.keys(this.records[0]).indexOf('FirstName');
    this.colLastName = Object.keys(this.records[0]).indexOf('LastName');
    this.colSuffix = Object.keys(this.records[0]).indexOf('Suffix');
    this.colAddress = Object.keys(this.records[0]).indexOf('Address');
    this.colCity = Object.keys(this.records[0]).indexOf('City');
    this.colState = Object.keys(this.records[0]).indexOf('State');
    this.colPhone = Object.keys(this.records[0]).indexOf('Phone');
    this.page = 0;
  }

  originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return 0;
  }

  pdfFileChange(event) {
    const fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      const file: File = fileList[0];
      const fileReader = new FileReader();
      fileReader.onload = (e: any) => {
        this.pdfFiles.push(
          {
            fileName: file.name,
            fileBase64: e.target.result
          }
        );
        event.target.value = null;
      };
      fileReader.readAsDataURL(file);
    }
  }

  removePdfFile(pdfFile) {
    this.pdfFiles = this.pdfFiles.filter(x => x.fileName !== pdfFile.fileName);
  }

  async showQuestionnaireProof() {
    this.isWaiting = true;
    let uploadedFileId;
    if (!this.questionnaireVariant) {
      // upload file
      const productResp: any = await this.four51Service.getProduct('F50692KIT', this.token).toPromise();
      const fileID = productResp.Specs.V07Distribution_List.ID;
      const sourceID = productResp.Specs.V07Distribution_List.SourceID;
      const sourceType = 'Product';
      const fileObject = {
        Data: 'data:text/csv;base64,' + this.csvFileBase64,
        ID: fileID,
        Name: this.csvFileName,
        SourceID: sourceID,
        SourceType: sourceType
      };
      const fileResponse = await this.four51Service.uploadFile(fileObject, this.token).toPromise();
      console.log(fileResponse.body);
      uploadedFileId = fileResponse.body.ID;
    }
    const variant: any = {
      ProductInteropID: 'F50692KIT',
      Specs: {
        V00First_Name: {
          Value: this.records[this.page - 1][Object.keys(this.records[this.page - 1])[this.colFirstName]]
        },
        V01Last_Name: {
          Value: this.records[this.page - 1][Object.keys(this.records[this.page - 1])[this.colLastName]]
        },
        V02Suffix: {
          Value: this.records[this.page - 1][Object.keys(this.records[this.page - 1])[this.colSuffix]]
        },
        V03Phone: {
          Value: this.records[this.page - 1][Object.keys(this.records[this.page - 1])[this.colPhone]]
        },
        V04Address: {
          Value: this.records[this.page - 1][Object.keys(this.records[this.page - 1])[this.colAddress]]
        },
        V05City: {
          Value: this.records[this.page - 1][Object.keys(this.records[this.page - 1])[this.colCity]]
        },
        V06State: {
          Value: this.records[this.page - 1][Object.keys(this.records[this.page - 1])[this.colState]]
        },
        V07Distribution_List: {
          Value: uploadedFileId
        }
      }
    };
    if (this.questionnaireVariant) {
      variant.InteropID = this.questionnaireVariant.InteropID;
    }
    const response = await this.four51Service.createVariant(variant, this.token).toPromise();
    this.questionnaireVariant = response.body;
    this.refreshProof();
  }

  async showUploadProofs() {

    for (const pdfFile of this.pdfFiles) {
      if (!pdfFile.uploadedFileId) {
        // upload file
        const productResp: any = await this.four51Service.getProduct('F50688KIT', this.token).toPromise();
        const fileID = productResp.Specs.V01UploadFile.ID;
        const sourceID = productResp.Specs.V01UploadFile.SourceID;
        const sourceType = 'Product';
        const fileObject = {
          Data: pdfFile.fileBase64,
          ID: fileID,
          Name: pdfFile.fileName,
          SourceID: sourceID,
          SourceType: sourceType
        };
        const fileResponse = await this.four51Service.uploadFile(fileObject, this.token).toPromise();
        console.log(fileResponse.body);
        pdfFile.uploadedFileId = fileResponse.body.ID;
      }
      const variant: any = {
        ProductInteropID: 'F50688KIT',
        Specs: {
          V01UploadFile: {
            Value: pdfFile.uploadedFileId
          }
        }
      };
      if (pdfFile.variant) {
        variant.InteropID = pdfFile.variant.InteropID;
      }
      const response = await this.four51Service.createVariant(variant, this.token).toPromise();
      pdfFile.variant = response.body;
      pdfFile.imageUrl = pdfFile.variant.PreviewUrl + '?r=' + Math.random();
    }
  }

  onPreviewLoaded() {
    this.isWaiting = false;
  }

  onPreviewError(e) {
    console.log(e);
    this.isWaiting = false;
  }

  refreshProof() {
    this.questionnaireImageUrl = this.questionnaireVariant.PreviewUrl + '?r=' + Math.random();
  }

  async saveOrder() {
    const qty = this.records.length;
    if (!this.order) {
      this.order = {
        Type: 'Standard'
      };
    }
    const lineItems: any = [
      {Product: {InteropID: 'F50692KIT'}, Quantity: this.records.length, Variant: {InteropID: this.questionnaireVariant.InteropID}}
    ]; // Marketing Kit - Questionnaire (Personalized)
    for (const pdfFile of this.pdfFiles) {
      lineItems.push(
        {Product: {InteropID: 'F50688KIT'}, Quantity: 1, Variant: {InteropID: pdfFile.variant.InteropID}}
        );
    } // Marketing Kit - Event Detail (Upload)
    lineItems.push({Product: {InteropID: 'F50693KIT'}, Quantity: 5}); // Marketing Kit - Questionnaire (Static)
    lineItems.push({Product: {InteropID: 'F50689KIT'}, Quantity: 1}); // Marketing Kit - Post Event Summary and Physician Feedback (Static)
    lineItems.push({Product: {InteropID: 'F50694KIT'}, Quantity: 2}); // Marketing Kit - NDA/BAA(Static)
    lineItems.push({Product: {InteropID: 'F39412KIT'}, Quantity: this.records.length + 5}); // Dr. Folders for Marketing Dinner Kits
    lineItems.push({Product: {InteropID: 'MD64534KIT'}, Quantity: this.records.length + 5}); // MDVIP Pens for Marketing Dinner Kits
    lineItems.push({Product: {InteropID: 'F50697KIT'}, Quantity: 1}); // Marketing Kit - Manila File Folder
    this.order.LineItems = lineItems;
    const response = await this.four51Service.createOrUpdateOrder(this.order, this.token).toPromise();
    this.order = response.body;
    console.log(this.order.ID);
    // const resp = await this.four51Service.setCurrentOrder(this.order.ID, this.token).toPromise();
    // console.log('user currentorder updated');
  }

  async finish() {
    await this.saveOrder();
    this.goToStep(null, 4);
  }

}
