import {Component, Input} from '@angular/core';
import {states} from '../assets/ts/states';
import {ControlContainer, NgForm, FormsModule} from '@angular/forms';

@Component({
  selector: 'app-spec-field',
  templateUrl: './spec-field.component.html',
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})

export class SpecFieldComponent {
  @Input() specKeyValue: any;
  @Input() submitted;
  private states = states;

  getSpecLabel() {
    if (this.specKeyValue.value.Label) {
      return this.specKeyValue.value.Label;
    } else {
      return '';
    }
  }
  isHidden() {
    const hiddenFields = ['doctorspicture', 'practiceid'];
    if (hiddenFields.includes(this.getSpecLabel().toLowerCase().replace(/(\s|')/g, ''))) {
      return true;
    }
    return false;
  }
  isTextArea() {
    const textAreaFields = ['certifications', 'medicalschool', 'hospitalaffiliations'];
    if (textAreaFields.includes(this.getSpecLabel().toLowerCase().replace(/(\s|')/g, ''))) {
      return true;
    }
    return false;
  }
}
