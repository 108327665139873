import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {MdvipUser} from '../models/mdvip-user';
import {MdvipUserService} from '../mdvip-user.service';
import {Subscription} from 'rxjs';
import {Four51Service} from '../four51.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {ModalComponent} from '../modal.component';
import {AddressService} from '../address.service';
import {MailerMessage, MailerService} from '../mailer.service';
import {environment} from '../../environments/environment';
import {MailTemplateService, MailTemplateValue} from '../mail-template.service';
import {Guid} from 'guid-typescript';
import {CurrencyPipe} from '@angular/common';
import {PhysicianEmailAddrService} from '../physician-email-addr.service';

@Component({
  selector: 'app-bannners',
  templateUrl: './banners.component.html',
  styleUrls: ['./banners.component.scss']
})

export class BannersComponent implements OnInit, OnDestroy {
  @ViewChild('bannersForm', {static: false}) bannersForm: NgForm;
  private shouldShowBannerTypeMessage = false;
  private step = 1;
  private productID;
  private variant;
  private subscription: Subscription;
  private mdvipUser: MdvipUser;
  private imageUrl;
  private proofUrl;
  private isWaiting: boolean;
  private isPdf = true;
  public isComplete = false;
  public shipAddress: any;
  public billAddress: any;
  public isBillingSameAsShipping = true;
  public ccno;
  public ccexp;
  public cccvv;
  public order: any;
  private twoByFourProductID = '4E3DE69E-9BCD-4CC5-8558-79D03B58D8F5';
  private threeBySixProductID = '1AB6BD4C-A1BA-40FC-9FB5-79BBB3CEA1F4';
  public twoByFourDescription: string;
  public twoByFourPrice: number;
  public threeBySixDescription: string;
  public threeBySixPrice: number;
  public isWholePageWaiting = false;
  public errorMessage: string;
  @ViewChild('sidebar', { static: false}) sidebar: ElementRef;
  constructor(private mdvipUserService: MdvipUserService, private four51Service: Four51Service, private modalService: NgbModal,
              private route: ActivatedRoute, private router: Router, private addressService: AddressService,
              private mailTemplateService: MailTemplateService, private mailerService: MailerService, private currencyPipe: CurrencyPipe,
              private physicianEmailAddrService: PhysicianEmailAddrService) {}
  ngOnInit(): void {
    this.subscription = new Subscription();
    const userSubscription = this.mdvipUserService.getMdvipUser().subscribe((user: MdvipUser) => {
      this.mdvipUser = user;
      if (user) {
        this.getShipAddress();
      }
    });
    this.subscription.add(userSubscription);
    const paramMapSubscription = this.route.paramMap.subscribe((params: ParamMap) => {
      this.isWholePageWaiting = false;
      const step = params.get('step');
      const complete = params.get('complete');
      if (step) {
        this.step = +step;
      } else {
        this.step = 1;
      }
      if (complete) {
        this.isComplete = true;
      } else {
        this.isComplete = false;
      }
      if (this.sidebar) {
        this.sidebar.nativeElement.scrollTop = 0;
      }
    });
    this.subscription.add(paramMapSubscription);
    this.getProductInfo();
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  async getProductInfo() {
    let response: any;
    response = await this.four51Service.getProduct(this.twoByFourProductID).toPromise();
    this.twoByFourDescription = response.Name;
    this.twoByFourPrice = response.StandardPriceSchedule.PriceBreaks[0].Price;
    response = await this.four51Service.getProduct(this.threeBySixProductID).toPromise();
    this.threeBySixDescription = response.Name;
    this.threeBySixPrice = response.StandardPriceSchedule.PriceBreaks[0].Price;
  }
  async goToStep(step, bannersForm: NgForm) {
    if (step === 2) {
      if (this.bannersForm.value.bannerType !== '') {
        this.shouldShowBannerTypeMessage = false;
        if (this.bannersForm.value.bannerType === '2x4Pdf' || this.bannersForm.value.bannerType === '2x4Printed') {
          this.productID = this.twoByFourProductID;
        } else {
          this.productID = this.threeBySixProductID;
        }
        if (this.bannersForm.value.bannerType === '2x4Pdf' || this.bannersForm.value.bannerType === '3x6Pdf') {
          this.isPdf = true;
        } else {
          this.isPdf = false;
        }
        this.variant = {
          ProductInteropID: this.productID,
          Specs: {
            V00Headline: {Value: null},
            V01Name: {Value: this.getCustomFieldValue('fullName')},
            V02Website: {Value: this.getCustomFieldValue('Website')}
          }
        };
        this.updateVariant();
        this.router.navigate(['/banners', {step: 2}]);
      } else {
        this.shouldShowBannerTypeMessage = true;
      }
    }
    if (step === 3) {
      if (bannersForm.valid) {
        this.isWholePageWaiting = true;
        await this.updateVariant();
        const order: any = {
          Type: 'Standard'
        };
        this.shipAddress.IsCustEditable = true;
        this.shipAddress.IsShipping = true;
        this.shipAddress.AddressName = this.shipAddress.Street1 + ' ' + this.shipAddress.City;
        const updatedAddress: any = await this.four51Service.createAddress(this.shipAddress).toPromise();
        this.shipAddress = updatedAddress.body;
        const lineItems: any = [
          {
            Product: {InteropID: this.productID},
            Quantity: 1,
            Variant: {InteropID: this.variant.InteropID},
            ShipAddressID: this.shipAddress.ID
          }
        ];
        order.LineItems = lineItems;

        let response: any;

        // payment method
        order.PaymentMethod = 'CreditCard';
        order.PaymentMethodText = 'Credit Card';

        // create order
        response = await this.four51Service.createOrUpdateOrder(order).toPromise();
        this.order = response.body;

        response = await this.four51Service.getShippers(this.order.ID).toPromise();
        const matchingShipper = response.find( x => x.Name === 'Fedex 2 Day');
        let shipper;
        if (matchingShipper) {
          shipper = matchingShipper;
        }
        if (shipper) {
          this.order.Shipper = shipper;
          for (const lineItem of this.order.LineItems) {
            lineItem.ShipperID = shipper.ID;
            // lineItem.ShipperName = shipper.Name;
          }
        } else {
          console.log('no shipper');
        }

        // get shipping costs
        response = await this.four51Service.createOrUpdateOrder(this.order).toPromise();
        this.order = response.body;
        this.router.navigate(['/banners', {step: 3}]);
      }
    }
  }
  async updateVariant() {
    this.isWaiting = true;
    const response = await this.four51Service.createVariant(this.variant).toPromise();
    this.variant = response.body;
    this.imageUrl = this.variant.PreviewUrl + '?r=' + Math.random();
    this.proofUrl = this.variant.ProofUrl + '?r=' + Math.random();
  }
  getCustomFieldValue(name): string {
    const field = this.mdvipUser.CustomFields.filter(x => x.Name === name);
    if (field.length > 0) {
      const returnString = field[0].Value || '';
      return returnString;
    } else {
      return '';
    }
  }
  getSpecLabel(spec: any) {
    if (spec.Label) {
      return spec.Label;
    } else {
      return '';
    }
  }
  onPreviewLoaded() {
    this.isWaiting = false;
  }

  onPreviewError(e) {
    console.log(e);
    this.isWaiting = false;
  }

  async getShipAddress() {
    // TODO refactor into address service
    const response: any = await this.four51Service.getAddresses('shipping').toPromise();
    const shippingAddresses = response.List;
    const address = this.getCustomFieldValue('Address');
    const city = this.getCustomFieldValue('City');
    if (address && city) { // physician user has address saved
      this.shipAddress = await this.addressService.findOrCreateShippingAddress(
        shippingAddresses,
        this.getCustomFieldValue('fullname'),
        address,
        this.getCustomFieldValue('Address2'),
        city,
        this.getCustomFieldValue('State'),
        this.getCustomFieldValue('Zip'),
        'US',
        this.mdvipUser.Phone,
        this.mdvipUser.FirstName,
        this.mdvipUser.LastName);
    } else {
      const addressMatchingName = shippingAddresses
        .find(x => x.FirstName === this.mdvipUser.FirstName && x.LastName === this.mdvipUser.LastName);
      if (addressMatchingName) {
        this.shipAddress = addressMatchingName;
      } else {
        this.shipAddress = {
          CompanyName: '',
          Street1: '',
          Street2: '',
          City: '',
          State: '',
          Zip: '',
          Country: 'US',
          Phone: '',
          FirstName: this.mdvipUser.FirstName,
          LastName: this.mdvipUser.LastName,
        };
      }
    }
    this.setBillingAddress();
  }

  setBillingAddress() {
    if (this.shipAddress && this.isBillingSameAsShipping) {
      this.billAddress = Object.assign({}, this.shipAddress);
    } else {
      this.billAddress = {
        CompanyName: '',
        Street1: '',
        Street2: '',
        City: '',
        State: '',
        Zip: '',
        Country: 'US',
        Phone: '',
        FirstName: this.mdvipUser.FirstName,
        LastName: this.mdvipUser.LastName,
      };
    }
  }

  async onBannersFormSubmit(bannersForm: NgForm) {
    if (bannersForm.valid) {
      this.errorMessage = null;
      this.isWholePageWaiting = true;
      let response: any;

      // billing address
      if (this.isBillingSameAsShipping) {
        this.order.BillAddressID = this.shipAddress.ID;
      } else {
        response = await this.four51Service.getAddresses('billing').toPromise();
        const billingAddresses: any[] = response.List;
        const matchingBillingAddress = billingAddresses
          .find(x => x.FirstName === this.billAddress.FirstName
            && x.LastName === this.billAddress.LastName
            && x.CompanyName === this.billAddress.CompanyName
            && x.Street1 === this.billAddress.Street1
            && x.Street2 === this.billAddress.Street2
            && x.City === this.billAddress.City
            && x.State === this.billAddress.State
            && x.Zip === this.billAddress.Zip
            && x.Country === this.billAddress.Country
            && x.Phone === this.billAddress.Phone);
        if (matchingBillingAddress) {
          console.log('using existing billing address');
          this.order.BillAddressID = matchingBillingAddress.ID;
        } else {
          console.log('creating new billing address');
          this.billAddress.IsCustEditable = true;
          this.billAddress.IsShipping = false;
          this.billAddress.IsBilling = true;
          this.billAddress.AddressName =  this.billAddress.Street1 + ' ' + this.billAddress.City;
          const newBillingAddress: any = await this.four51Service.createAddress(this.billAddress).toPromise();
          this.order.BillAddressID = newBillingAddress.body.ID;
        }
      }

      // credit card
      let type;
      if (this.ccno.substr(0, 2) === '34' || this.ccno.substr(0, 2) === '37') {
        type = 'AmericanExpress';
      } else if  (this.ccno.substr(0, 1) === '4') {
        type = 'Visa';
      } else {
        const firstTwoDigits: number = +this.ccno.substr(0, 2);
        const firstFourDigits: number = +this.ccno.substr(0, 4);
        const firstSixDigits: number = +this.ccno.substr(0, 6);
        if ((firstFourDigits >= 2221 && firstFourDigits <= 2720) || (firstTwoDigits >= 51 && firstTwoDigits <= 55)) {
          type = 'MasterCard';
        } else if (firstFourDigits === 6011 ||
          (firstSixDigits >= 622126 && firstSixDigits <= 622925) ||
          (firstSixDigits >= 624000 && firstSixDigits <= 626999) ||
          (firstSixDigits >= 628200 && firstSixDigits <= 628899) ||
          firstTwoDigits === 64 ||
          firstTwoDigits === 65	) {
          type = 'Discover';
        }
      }
      this.order.CreditCard = {
        AccountNumber: this.ccno,
        CVN: this.cccvv,
        ExpirationDate: this.ccexp,
        Type: type
      };

      let isOk = false;
      try {
        response = await this.four51Service.putOrder(this.order).toPromise();
        this.order = response.body;
        isOk = true;
        // console.log(this.order);
      } catch (error) {
        // console.log(error);
        this.errorMessage = error.error.Message;
      }
      this.isWholePageWaiting = false;

      if (isOk) {
        this.isComplete = true;
        /*
         * Banner Submitted Doctor Email
         */
        const bannerSubmittedDoctorEmail: MailerMessage = new MailerMessage();
        bannerSubmittedDoctorEmail.Subject = this.getCustomFieldValue('fullName') + ', Order ' +
          this.order.ExternalID + ' has been received';
        bannerSubmittedDoctorEmail.Bcc = environment.emailBcc;
        if (!environment.production || environment.isTest) {
          bannerSubmittedDoctorEmail.To = environment.emailTo;
          bannerSubmittedDoctorEmail.Subject = 'TESTING: ' + bannerSubmittedDoctorEmail.Subject;
        } else {
          this.physicianEmailAddrService.setAddresses(bannerSubmittedDoctorEmail, this.mdvipUser.Email,
            this.getCustomFieldValue('staffEmail'), this.getCustomFieldValue('ptmEmail'),
            this.getCustomFieldValue('pdmEmail'), this.getCustomFieldValue('practiceStatus'),
            this.getCustomFieldValue('relationshipStatus'), this.getCustomFieldValue('physicianStatusType'));
        }
        bannerSubmittedDoctorEmail.From = 'Marketing Support Site <MarketingSupportSite@mdvip.com>';
        bannerSubmittedDoctorEmail.Body = 'This message requires an email client that supports HTML email.';
        let items = '';
        const itemOpen = '<tr><td valign="middle" ' +
          'style="width:0.1%; padding: 0 18px 0 0; font-size: 32px; mso-line-height-rule:exactly; mso-text-raise: -8px; ' +
          'line-height: 16px; font-family: Helvetica, Arial, sans-serif; color: #666666;">&bull;</td><td valign="top" ' +
          'style="font-size: 16px; line-height: 25px; font-family: Helvetica, Arial, sans-serif; color: #666666;">';
        const itemClose = '</td></tr>';
        for (const lineItem of response.body.LineItems) {
          items += itemOpen + lineItem.Quantity + ' ' + lineItem.Product.Name +
            ' ' + this.currencyPipe.transform(lineItem.LineTotal) + itemClose;
        }
        let ship_address = '';
        ship_address += this.shipAddress.FirstName + ' ' + this.shipAddress.LastName + '<br />';
        ship_address += this.shipAddress.CompanyName + '<br />';
        ship_address += this.shipAddress.Street1 + '<br />';
        ship_address += (this.shipAddress.Street2) ? this.shipAddress.Street2 + '<br />' : '';
        ship_address += this.shipAddress.City + ', ' + this.shipAddress.State + ' ' + this.shipAddress.Zip;
        const historyUrl = environment.mdvipConnectUrl;
        const mailTemplateValues: MailTemplateValue[] = [
          {name: 'items', value: items},
          {name: 'ship_address', value: ship_address},
          {name: 'order_number', value: this.order.ExternalID},
          {name: 'history_url', value: historyUrl},
          {name: 'subtotal', value: this.currencyPipe.transform(this.order.Subtotal)},
          {name: 'tax', value: this.currencyPipe.transform(this.order.TaxCost)},
          {name: 'shipping', value: this.currencyPipe.transform(this.order.ShippingCost)},
          {name: 'total', value: this.currencyPipe.transform(this.order.Total)},
        ];
        bannerSubmittedDoctorEmail.Html = await this.mailTemplateService
          .replaceValues('./assets/html/bannerSubmittedDoctor.html', mailTemplateValues);
        this.mailerService.sendMessageWithLogo(bannerSubmittedDoctorEmail);
      }
    }
  }

  download() {
    const w: any = window;
    const isIOS = (/iPad|iPhone|iPod/.test(navigator.platform) ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) &&
      !w.MSStream;
    let downloadingMessage;
    if (isIOS) {
      downloadingMessage = 'Your file is downloading. The file will appear in a new tab on your browser.';
    } else {
      downloadingMessage = 'Your file is downloading. Depending on your browser, it may appear in your status bar below or your ' +
        'default \"Downloads\" folder.';
    }
    const modalRef = this.modalService.open(ModalComponent);
    const modal: ModalComponent = modalRef.componentInstance;
    modal.title = 'Downloading';
    modal.body = downloadingMessage;
    modal.buttons = [{label: 'OK', isNeutral: true, action: ''}];
    if (isIOS) {
      window.open(this.proofUrl, '_blank');
    } else {
      window.location.replace(this.proofUrl);
    }
  }
}
