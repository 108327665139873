import {Injectable} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import {MdvipUserCookie} from './models/mdvip-user-cookie';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  public token: string;
  private mdvipUserCookie: MdvipUserCookie;
  constructor(private cookieService: CookieService) {
    if (this.cookieService.check('mdvip.user')) {
      this.mdvipUserCookie = JSON.parse(this.cookieService.get('mdvip.user'));
      this.token = this.mdvipUserCookie.Auth;
    }
  }
}
