import * as moment from 'moment';
import {NgbDate} from '@ng-bootstrap/ng-bootstrap';

export interface EventInfo {
  /*fullName = '';
  LastName = '';
  Specialty = '';
  address = '';
  address2 = '';
  city = '';
  state = '';
  zip = '';
  phone = '';
  fax = '';
  email = '';
  staffEmail = '';
  website = '';
  facebook = '';
  twitter = '';
  ** data fields **
  physicianId = '';
  memberServiceState = '';
  membershipTier = '';
  pdmEmail = '';
  physicianStatusType = '';
  practiceStatus = '';
  ptmEmail = '';
  relationshipStatus = '';
  rsvpOnline = false;
  roomCapacity = '';
  ** end data fields **
  speaker = '';
  speakervs = '';
  patient_guests = '';
  sessions: Session[] = [
    {
      eventDate: null,
      eventStartTime: {
        hour: 1,
        minute: 0,
        hhMm: '',
        amPm: 'AM',
        moment: moment().hour(1).minute(0)
      },
      includeEventEndTime: true,
      eventEndTime: {
        hour: 1,
        minute: 0,
        hhMm: '',
        amPm: 'AM',
        moment: moment().hour(1).minute(0),
        isUserSet: false
      }
    }
  ];
  eventDateText = '';
  venue_name = '';
  venue_address_1 = '';
  venue_address_2 = '';
  venue_city = '';
  venue_state = '';
  venue_zip = '';
  rsvp_phone = '';
  rsvp_message = '';
  topic: any = {editable_fields: []};
  byoSelectedTopicId = undefined;
  long_description_html = '';
  title = '';
  brief_description = '';
  introTemplate = '';
  intro = '';
  header_image = '';
   */
  fullName?: string;
  LastName?: string;
  Specialty?: string;
  Address?: string;
  Address2?: string;
  City?: string;
  State?: string;
  Zip?: string;
  Phone?: string;
  fax?: string;
  email?: string;
  staffEmail?: string;
  Website?: string;
  facebook?: string;
  twitter?: string;
  /** data fields **/
  physicianId?: string;
  memberServiceState?: string;
  membershipTier?: string;
  pdmEmail?: string;
  physicianStatusType?: string;
  practiceStatus?: string;
  ptmEmail?: string;
  relationshipStatus?: string;
  rsvpOnline?: boolean;
  roomCapacity?: string;
  /** end data fields **/
  speaker?: string;
  speakervs?: string;
  patient_guests?: string;
  sessions?: Session[];
  eventDateText?: string;
  venue_name?: string;
  venue_address_1?: string;
  venue_address_2?: string;
  venue_city?: string;
  venue_state?: string;
  venue_zip?: string;
  rsvp_phone?: string;
  rsvp_message?: string;
  venueType?: VenueType;
  webinarUrl?: string;
  additionalInformation?: string;
  rsvpByDate?: string;
  // topic: any;
  topicId?: number;
  type?: string;
  byoSelectedTopicId?: number;
  long_description_html?: string;
  title?: string;
  brief_description?: string;
  intro?: string;
  header_image?: string;
  logo?: string;
  channel?: string;
  eligibleProspects?: string;
  pastPatients?: string;
  shippingAddressID?: string;
  secondShipToAddress?: boolean;
  secondShippingAddressID?: string;
  comments?: string;
  commentsName?: string;
  commentsPhone?: string;
}

export enum VenueType {
  Physical = 'Physical',
  Virtual = 'Virtual',
  Combined = 'Combined'
}

export function eventInfoWithVariant(variant: any): EventInfo {
  const dataString = (variant.Specs.data) ? getVariantValue('data', variant) : '{}';
  const data = JSON.parse(dataString);
  let sessions;
  if (data.sessions) {
    sessions = [];
    for (const session of data.sessions) {
      sessions.push({
        eventDate: session.eventDate,
        eventStartTime: {
          hour: session.eventStartTime.hour,
          minute: session.eventStartTime.minute,
          hhMm: session.eventStartTime.hhMm,
          amPm: session.eventStartTime.amPm,
          moment: moment(session.eventStartTime.moment),
          timeZone: session.eventStartTime.timeZone
        },
        includeEventEndTime: session.includeEventEndTime,
        eventEndTime: {
          hour: session.eventEndTime.hour,
          minute: session.eventEndTime.minute,
          hhMm: session.eventEndTime.hhMm,
          amPm: session.eventEndTime.amPm,
          moment: moment(session.eventEndTime.moment),
          timeZone: session.eventStartTime.timeZone,
          isUserSet: session.eventEndTime.isUserSet || false
        }
      });
    }
  }
  const softReturn = String.fromCharCode(8232);
  const softReturnRegExp = new RegExp(softReturn, 'g');
  let eventDateText = getVariantValue('event_date_text', variant);
  eventDateText = eventDateText.replace(softReturnRegExp, '\n');
  let additionalInformation = getVariantValue('additionalInformation', variant);
  additionalInformation = additionalInformation.replace(softReturnRegExp, '\n');
  const eventInfo: EventInfo = {
    ...(variant.Specs.fullName) && {fullName: getVariantValue('fullName', variant)},
    ...(variant.Specs.LastName) && {LastName: getVariantValue('LastName', variant)},
    ...(variant.Specs.Specialty) && {Specialty: getVariantValue('Specialty', variant)},
    ...(variant.Specs.Address) && {Address: getVariantValue('Address', variant)},
    ...(variant.Specs.Address2) && {Address2: getVariantValue('Address2', variant)},
    ...(variant.Specs.City) && {City: getVariantValue('City', variant)},
    ...(variant.Specs.State) && {State: getVariantValue('State', variant)},
    ...(variant.Specs.Zip) && {Zip: getVariantValue('Zip', variant)},
    ...(variant.Specs.Phone) && {Phone: getVariantValue('Phone', variant)},
    ...(variant.Specs.fax) && {fax: getVariantValue('fax', variant)},
    ...(data.email) && {email: data.email},
    ...(data.staffEmail) && {staffEmail: data.staffEmail},
    ...(variant.Specs.Website) && {Website: getVariantValue('Website', variant)},
    ...(variant.Specs.facebook) && {facebook: getVariantValue('facebook', variant)},
    ...(variant.Specs.twitter) && {twitter: getVariantValue('twitter', variant)},
    ...(data.physicianId) && {physicianId: data.physicianId},
    ...(data.memberServiceState) && {memberServiceState: data.memberServiceState},
    ...(data.membershipTier) && {membershipTier: data.membershipTier},
    ...(data.pdmEmail) && {pdmEmail: data.pdmEmail},
    ...(data.physicianStatusType) && {physicianStatusType: data.physicianStatusType},
    ...(data.practiceStatus) && {practiceStatus: data.practiceStatus},
    ...(data.ptmEmail) && {ptmEmail: data.ptmEmail},
    ...(data.relationshipStatus) && {relationshipStatus: data.relationshipStatus},
    ...(data.topicId) && {topicId: data.topicId},
    ...(data.type) && {type: data.type},
    ...(data.byoSelectedTopicId !== undefined && data.byoSelectedTopicId !== null) && {byoSelectedTopicId: data.byoSelectedTopicId},
    ...(variant.Specs.long_description_html) && {long_description_html: getVariantValue('long_description_html', variant)},
    ...(variant.Specs.title) && {title: getVariantValue('title', variant)},
    ...(variant.Specs.brief_description) && {brief_description: getVariantValue('brief_description', variant)},
    ...(variant.Specs.intro) && {intro: getVariantValue('intro', variant)},
    ...(variant.Specs.header_image) && {header_image: getVariantValue('header_image', variant)},
    ...(variant.Specs.logo) && {logo: getVariantValue('logo', variant)},
    ...(variant.Specs.speaker) && {speaker: getVariantValue('speaker', variant)},
    ...(variant.Specs.speakervs) && {speakervs: getVariantValue('speakervs', variant)},
    ...(variant.Specs.patient_guests) && {patient_guests: getVariantValue('patient_guests', variant)},
    ...(sessions) && {sessions: sessions},
    ...(eventDateText) && {eventDateText: eventDateText},
    ...(variant.Specs.venue_name) && {venue_name: getVariantValue('venue_name', variant)},
    ...(variant.Specs.venue_address_1) && {venue_address_1: getVariantValue('venue_address_1', variant)},
    ...(variant.Specs.venue_address_2) && {venue_address_2: getVariantValue('venue_address_2', variant)},
    ...(variant.Specs.venue_city) && {venue_city: getVariantValue('venue_city', variant)},
    ...(variant.Specs.venue_state) && {venue_state: getVariantValue('venue_state', variant)},
    ...(variant.Specs.venue_zip) && {venue_zip: getVariantValue('venue_zip', variant)},
    ...(variant.Specs.rsvp_phone) && {rsvp_phone: getVariantValue('rsvp_phone', variant)},
    ...(variant.Specs.rsvp_message) && {rsvp_message: getVariantValue('rsvp_message', variant)},
     ...(variant.Specs.venueType) && {venueType:  getVariantValue('venueType', variant)},
    ...(variant.Specs.webinarUrl) && {webinarUrl:  getVariantValue('webinarUrl', variant)},
    ...(variant.Specs.additionalInformation) && {additionalInformation:  additionalInformation},
    ...(variant.Specs.rsvpByDate) && {rsvpByDate: getVariantValue('rsvpByDate', variant)},
    ...(data.roomCapacity) && {roomCapacity: data.roomCapacity},
    ...(data.hasOwnProperty('rsvpOnline')) && {rsvpOnline: data.rsvpOnline},
    ...(data.channel) && {channel: data.channel},
    ...(data.eligibleProspects) && {eligibleProspects: data.eligibleProspects},
    ...(data.pastPatients) && {pastPatients: data.pastPatients},
    ...(data.shippingAddressID) && {shippingAddressID: data.shippingAddressID},
    ...(data.hasOwnProperty('secondShipToAddress')) && {secondShipToAddress: data.secondShipToAddress},
    ...(data.secondShippingAddressID) && {secondShippingAddressID: data.secondShippingAddressID},
    ...(data.comments) && {comments: data.comments},
    ...(data.commentsName) && {commentsName: data.commentsName},
    ...(data.commentsPhone) && {commentsPhone: data.commentsPhone},
  };
  return eventInfo;
}

function getVariantValue(name: string, variant: any) {
  const field = variant.Specs[name];
  if (field) {
    const returnString = field.Value || '';
    return returnString ;
  } else {
    return '';
  }
}

interface Session {
  eventDate: NgbDate;
  eventStartTime: EventStartTime;
  includeEventEndTime: boolean;
  eventEndTime: EventEndTime;
}

interface EventStartTime {
  hour: number;
  minute: number;
  hhMm: string;
  amPm: string;
  moment: moment.Moment;
  timeZone: string;
}

interface EventEndTime extends EventStartTime {
  isUserSet: boolean;
}
