import {Component, OnDestroy, OnInit} from '@angular/core';
import {CartService} from '../cart.service';
import {Subscription} from 'rxjs';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {AddressService} from '../address.service';
import {Four51Service} from '../four51.service';
import {MdvipUserService} from '../mdvip-user.service';
import {MdvipUser} from '../models/mdvip-user';
import {MailerMessage, MailerService} from '../mailer.service';
import {MailTemplateService, MailTemplateValue} from '../mail-template.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ProductEditModalComponent} from '../product-edit-modal.component';
import {environment} from '../../environments/environment';
import {PhysicianEmailAddrService} from '../physician-email-addr.service';
import * as moment from 'moment-timezone';
import {OrderLimitResponse, OrderLimitService} from '../order-limit.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})

export class CartComponent implements OnInit, OnDestroy {
  private subscription: Subscription;
  private cart: any;
  private isUpdating = false;
  public isCheckout = false;
  public isComplete = false;
  private shipAddress;
  private mdvipUser: MdvipUser;
  private isOrderWaiting = false;
  private orderNumber: string;
  public needsApproval = false;
  constructor(private cartService: CartService, private route: ActivatedRoute, private router: Router,
              private addressService: AddressService, private four51Service: Four51Service,
              private mdvipUserService: MdvipUserService, private mailerService: MailerService,
              private mailTemplateService: MailTemplateService, private modalService: NgbModal,
              private physicianEmailAddrService: PhysicianEmailAddrService, private orderLimitService: OrderLimitService) {}

  ngOnInit(): void {
    this.subscription = new Subscription();
    const cartSubscription = this.cartService.cartObservable().subscribe(cart => {
      if (this.isUpdating) {
        this.isUpdating = false;
      }
      this.cart = cart;
      if (this.cart && this.cart.Approvals.length > 0) {
        this.needsApproval = true;
      }
      console.log(this.cart);
    });
    this.subscription.add(cartSubscription);
    const paramMapSubscription = this.route.paramMap.subscribe((params: ParamMap) => {
      const checkout = params.get('checkout');
      const complete = params.get('complete');
      if (checkout) {
        this.isCheckout = true;
      } else {
        this.isCheckout = false;
      }
      if (complete) {
        this.isComplete = true;
      } else {
        this.isComplete = false;
      }
    });
    this.subscription.add(paramMapSubscription);
    const userSubscription = this.mdvipUserService.getMdvipUser().subscribe((user: MdvipUser) => {
      this.mdvipUser = user;
      if (this.mdvipUser) {
        this.getShipAddress();
      }
    });
    this.subscription.add(userSubscription);
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  async selectLineItem(lineItem: any) {
    // const product: any = await this.four51Service.getProduct(lineItem.Product.).toPromise();
    console.log(lineItem);
    const options: any = { size: 'xl', scrollable: true,  windowClass : 'modal-proof', backdrop: 'static' };
    const modalRef = this.modalService.open(ProductEditModalComponent, options);
    modalRef.componentInstance.product = lineItem.Product;
    modalRef.componentInstance.variant = lineItem.Variant;
    modalRef.result.then(result => {
      console.log('checking cart to be safe');
      this.cartService.checkCart();
    }).catch(() => {
      // nothing to do
    });
  }

  async removeLineItem(lineItem: any) {
    this.cartService.removeFromCart(lineItem.ID);
  }

  async updateQuantities(cartForm) {
    if (cartForm.valid) {
      let shouldSave = true;
      this.isUpdating = true;
      for (const lineItem of this.cart.LineItems) {
        const resp: OrderLimitResponse = await this.orderLimitService.getOrderLimit(
          {product: lineItem.Product, quantity: lineItem.Quantity});
        if (!resp.canOrder) {
          // this.errorMessage = resp.message;
          shouldSave = false;
        }
      }
      if (shouldSave) {
        this.cartService.saveOrder(this.cart);
      } else {
        this.cartService.checkCart();
      }
    }
  }

  startCheckout() {
    this.cartService.checkCart();
    this.router.navigate(['/cart', {checkout: true}]);
  }

  async getShipAddress() {
    // TODO refactor into address service
    const response: any = await this.four51Service.getAddresses('shipping').toPromise();
    const shippingAddresses = response.List;
    const address = this.getCustomFieldValue('Address');
    const city = this.getCustomFieldValue('City');
    if (address && city) { // physician user has address saved
      this.shipAddress = await this.addressService.findOrCreateShippingAddress(
        shippingAddresses,
        this.getCustomFieldValue('fullname'),
        address,
        this.getCustomFieldValue('Address2'),
        city,
        this.getCustomFieldValue('State'),
        this.getCustomFieldValue('Zip'),
        'US',
        this.mdvipUser.Phone,
        this.mdvipUser.FirstName,
        this.mdvipUser.LastName);
    } else {
      const addressMatchingName = shippingAddresses
        .find(x => x.FirstName === this.mdvipUser.FirstName && x.LastName === this.mdvipUser.LastName);
      if (addressMatchingName) {
        this.shipAddress = addressMatchingName;
      } else {
        this.shipAddress = {
          CompanyName: '',
          Street1: '',
          Street2: '',
          City: '',
          State: '',
          Zip: '',
          Country: 'US',
          Phone: '',
          FirstName: this.mdvipUser.FirstName,
          LastName: this.mdvipUser.LastName,
        };
      }
    }
  }

  getCustomFieldValue(name): string {
    const field = this.mdvipUser.CustomFields.filter(x => x.Name === name);
    if (field.length > 0) {
      const returnString = field[0].Value || '';
      return returnString;
    } else {
      return '';
    }
  }

  async placeOrder(shipForm) {
    if (shipForm.valid) {
      this.isOrderWaiting = true;
      this.shipAddress.IsCustEditable = true;
      this.shipAddress.IsShipping = true;
      this.shipAddress.AddressName = this.shipAddress.Street1 + ' ' + this.shipAddress.City;
      const updatedAddress: any = await this.four51Service.createAddress(this.shipAddress).toPromise();
      this.shipAddress = updatedAddress.body;
      for (const lineItem of this.cart.LineItems) {
        lineItem.ShipAddressID = this.shipAddress.ID;
      }
      // need to save order to update ship address id before getting shippers
      await this.four51Service.createOrUpdateOrder(this.cart).toPromise();
      const shippers: any = await this.four51Service.getShippers(this.cart.ID).toPromise();
      const shipper = shippers.find(x => x.Name === 'FedEx Ground');
      if (shipper) {
        for (const lineItem of this.cart.LineItems) {
          lineItem.ShipperID = shipper.ID;
        }
      }
      // const response = {body: {ExternalID: 'TEST', LineItems: []}};
      if (this.needsApproval) {
        // put the user's email address in the comments so we can send confirmation when approved
        this.cart.Comments = this.mdvipUser.Email;
      }
      const response = await this.four51Service.putOrder(this.cart).toPromise();
      this.isOrderWaiting = false;
      this.router.navigate(['/cart', {complete: true}]);
      this.cartService.checkCart();
      this.orderNumber = response.body.ExternalID;
      const order = response.body;
      try {
        if (this.needsApproval) {
          /*
           * Order Submitted for Approval Email
           */
          const collateralSubmittedInternalEmail: MailerMessage = new MailerMessage();
          collateralSubmittedInternalEmail.Subject = this.getCustomFieldValue('fullName') + ', ' +
            'Order ' +  this.orderNumber + ' has been received';
          collateralSubmittedInternalEmail.Bcc = environment.emailBcc;
          if (!environment.production || environment.isTest) {
            collateralSubmittedInternalEmail.To = environment.emailTo;
            collateralSubmittedInternalEmail.Subject = 'TESTING: ' + collateralSubmittedInternalEmail.Subject;
          } else {
            collateralSubmittedInternalEmail.To = ['Marketing Support Site <MarketingSupportSite@mdvip.com>'];
            collateralSubmittedInternalEmail.To.push('pdmadmin@mdvip.com');
          }
          collateralSubmittedInternalEmail.From = 'Marketing Support Site <MarketingSupportSite@mdvip.com>';
          collateralSubmittedInternalEmail.Body = 'This message requires an email client that supports HTML email.';
          const approval_url = 'https://southeasternprinting.four51ordercloud.com/mdvip/order';
          let items = '';
          // const itemOpen = '<p>';
          // const itemClose = '</p>';
          const itemOpen = '<tr><td valign="top" ' +
            'style="width:0.1%; padding: 3px 18px 0 0; font-size: 32px; mso-line-height-rule:exactly; mso-text-raise: -8px; ' +
            'line-height: 16px; font-family: Helvetica, Arial, sans-serif; color: #666666;">&bull;</td><td valign="top" ' +
            'style="font-size: 16px; line-height: 25px; font-family: Helvetica, Arial, sans-serif; color: #666666;">';
          const itemClose = '</td></tr>';
          for (const lineItem of response.body.LineItems) {
            items += itemOpen + lineItem.Quantity + ' ' + lineItem.Product.Name + itemClose;
          }
          const collateralSubmittedInternalValues: MailTemplateValue[] = [
            {name: 'approval_url', value: approval_url},
            {name: 'date_submitted', value: moment().format('MM/DD/YYYY h:mm A')},
            {name: 'fullname', value: this.getCustomFieldValue('fullName')},
            {name: 'physician_id', value: this.getCustomFieldValue('physicianId')},
            {name: 'items', value: items}
          ];
          collateralSubmittedInternalEmail.Html = await this.mailTemplateService
            .replaceValues('./assets/html/collateralSubmittedInternal.html', collateralSubmittedInternalValues);
          this.mailerService.sendMessage(collateralSubmittedInternalEmail);

        } else {
          /*
           * Order Placed Doctor Email
           */
          const orderPlacedDoctorEmail: MailerMessage = new MailerMessage();
          orderPlacedDoctorEmail.Subject = this.getCustomFieldValue('fullName') + ', Order ' +  this.orderNumber + ' has been received';
          orderPlacedDoctorEmail.Bcc = environment.emailBcc;
          if (!environment.production || environment.isTest) {
            orderPlacedDoctorEmail.To = environment.emailTo;
            orderPlacedDoctorEmail.Subject = 'TESTING: ' + orderPlacedDoctorEmail.Subject;
          } else {
            this.physicianEmailAddrService.setAddresses(orderPlacedDoctorEmail, this.mdvipUser.Email,
              this.getCustomFieldValue('staffEmail'), this.getCustomFieldValue('ptmEmail'),
              this.getCustomFieldValue('pdmEmail'), this.getCustomFieldValue('practiceStatus'),
              this.getCustomFieldValue('relationshipStatus'), this.getCustomFieldValue('physicianStatusType'));
          }
          orderPlacedDoctorEmail.From = 'Marketing Support Site <MarketingSupportSite@mdvip.com>';
          orderPlacedDoctorEmail.Body = 'This message requires an email client that supports HTML email.';
          let items = '';
          const itemOpen = '<tr><td valign="middle" ' +
            'style="width:0.1%; padding: 0 18px 0 0; font-size: 32px; mso-line-height-rule:exactly; mso-text-raise: -8px; ' +
            'line-height: 16px; font-family: Helvetica, Arial, sans-serif; color: #666666;">&bull;</td><td valign="top" ' +
            'style="font-size: 16px; line-height: 25px; font-family: Helvetica, Arial, sans-serif; color: #666666;">';
          const itemClose = '</td></tr>';
          for (const lineItem of response.body.LineItems) {
            items += itemOpen + lineItem.Quantity + ' ' + lineItem.Product.Name + itemClose;
          }
          // const historyUrl = window.location.origin + '/history';
          const historyUrl = environment.mdvipConnectUrl;
          const mailTemplateValues: MailTemplateValue[] = [
            {name: 'order_number', value: this.orderNumber},
            {name: 'items', value: items},
            {name: 'history_url', value: historyUrl}
          ];
          orderPlacedDoctorEmail.Html = await this.mailTemplateService
            .replaceValues('./assets/html/orderPlacedDoctor.html', mailTemplateValues);
          this.mailerService.sendMessageWithLogo(orderPlacedDoctorEmail);
        }
      } catch (error) {
        let body = 'Order: ' + this.orderNumber + '\n' + error.message;
        let htmlBody = '<div>Order: ' + this.orderNumber + '</div></div><div>' + error.message + '</div>';
        if (this.needsApproval) {
          body += '\n' + 'Approval Needed';
          htmlBody += '<div>Approval Needed</div>';
        } else {
          body += '\n' + 'No Approval Needed';
          htmlBody += '<div>No Approval Needed</div>';
        }
        const errorEmail: MailerMessage = new MailerMessage();
        errorEmail.To = ['tom@artoftechnology.com', 'suzanne@seprint.com'];
        errorEmail.From = 'MarketingSupportSite@mdvip.com';
        errorEmail.Subject = 'Event Site Cart Email Error';
        errorEmail.Body = body;
        errorEmail.Html = htmlBody;
        this.mailerService.sendMessage(errorEmail);
      }
    }
  }
}


