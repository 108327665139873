import * as moment from 'moment';

export class OrderListItem {
  ID: string;
  ExternalID: string;
  StatusText: string;
  DateCreated: moment.Moment;
  DateSubmittedForApproval: moment.Moment;
  FromUserFirstName: string;
  FromUserLastName: string;
  EventDate: moment.Moment;
  DoctorFullName: string;
  TopicTitle: string;
  pdmEmail: string;
  shipTo: string;
  AutoApproved = false;
  isLoading: boolean;
}
