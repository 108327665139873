import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {MdvipUser} from '../models/mdvip-user';
import {Four51Service} from '../four51.service';
import {MdvipUserService} from '../mdvip-user.service';
import {Router} from '@angular/router';
import {CartService} from '../cart.service';
import {TokenService} from '../token.service';

@Component({
  selector: 'app-event-site',
  template: `
      <nav class="navbar navbar-dark navbar-expand-lg">
          <a class="navbar-brand" routerLink="/home" >
              <img src="assets/images/mdvip-white.png" alt="MDVIP" height="42px;"/>
              <img [src]="logoUrl" alt="logo" *ngIf="false" height="50px"/>
          </a>
          <ul class="navbar-nav ml-auto" *ngIf="mdvipUser">
              <li class="nav-item mx-lg-2" *ngIf="cartLines > 0"><a class="nav-link" routerLink="/cart">
                  <i class="fa fa-shopping-cart"></i> Cart ({{cartLines}})</a></li>
              <li class="nav-item mx-lg-2" *ngIf="isApprover">
                  <a class="nav-link" href="https://form.jotform.com/200555737800150" target="_blank">Image Upload</a></li>
              <li class="nav-item mx-lg-2" *ngIf="isApprover"><a class="nav-link" routerLink="/approval">Approval</a></li>
              <li class="nav-item mx-lg-2" *ngIf="isApprover"><a class="nav-link" routerLink="/crm-report">CRM Report</a></li>
              <li class="nav-item mx-lg-2"><a class="nav-link" routerLink="/history">Order History</a></li>
              <li class="nav-item mx-lg-2" ngbDropdown>
                  <a class="nav-link" style="cursor: pointer" ngbDropdownToggle id="userDropdown" role="button">
                      {{firstname}} {{lastname}}
                  </a>
                  <div ngbDropdownMenu aria-labelledby="userDropdown" class="dropdown-menu dropdown-menu-right">
                      <button class="dropdown-item" ngbDropdownItem (click)="logout($event)">Logout</button>
                  </div>
              </li>
          </ul>
      </nav>
      <router-outlet></router-outlet>
  `,
  styleUrls: ['./event-site.component.scss']
})
export class EventSiteComponent implements OnInit, OnDestroy {
  firstname = '';
  lastname = '';
  private subscription: Subscription;
  public mdvipUser: MdvipUser;
  private logoUrl = '';
  private isApprover = false;
  private cartLines = 0;
  // private mdvipUserCookie: MdvipUserCookie;
  constructor(private four51Service: Four51Service, private mdvipUserService: MdvipUserService,
              private router: Router, private cartService: CartService, private tokenService: TokenService) {}
  ngOnInit() {
    this.subscription = new Subscription();
    const mdvipUserSubscription = this.mdvipUserService.getMdvipUser().subscribe((user: MdvipUser) => {
      this.mdvipUser = user;
      // console.log('updated user');
      // console.log(user);
      if (user) {
        this.logoUrl = this.mdvipUser.Company['LogoUrl'];
        this.firstname = this.mdvipUser.FirstName;
        this.lastname = this.mdvipUser.LastName;
        this.isApprover = this.mdvipUser.Groups.filter(x => x.Name === 'Event Approvers').length > 0;
        // console.log(this.mdvipUser.Groups);
        this.cartService.checkCart();
      } else {
        // this.router.navigate(['/login']);
      }
    });
    this.subscription.add(mdvipUserSubscription);
    const cartSubscription = this.cartService.cartObservable().subscribe(cart => {
      if (cart) {
        this.cartLines = cart.LineItems.length;
      } else {
        this.cartLines = 0;
      }
    });
    this.subscription.add(cartSubscription);
    if (this.tokenService.token) {
      console.log(this.tokenService.token);
      this.mdvipUserService.refreshUser(this.tokenService.token);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  logout(e) {
    e.preventDefault();
    this.mdvipUserService.logout(true);
  }
}
