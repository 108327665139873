import {Injectable, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subscription, throwError} from 'rxjs';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse} from '@angular/common/http';
import {MdvipUser} from './models/mdvip-user';
import * as moment from 'moment';
import {TokenService} from './token.service';
import {catchError} from 'rxjs/operators';
import {environment} from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class Four51Service {
  // private four51Url = 'https://mdvipsfauthqa.artoftechnology.com/api/mdvip/';
  private four51Url = environment.apiBaseUrl + 'api/mdvip/';
  // private four51Url = 'http://localhost:3000/api/mdvip/';
  private subscription: Subscription;

  constructor (private http: HttpClient, private tokenService: TokenService) {}

  logInAnonymousUser(): Observable<HttpResponse<any>> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json'}),
      observe: 'response' as 'body'
    };
    const json = {
      Username: null,
      Password: null
    };
    return this.http
      .post<any>(this.four51Url + 'login/user', JSON.stringify(json), httpOptions);
  }

  logIn(username: string, password: string): Observable<HttpResponse<any>> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json'}),
      observe: 'response' as 'body'
    };
    const json = {
      Username: username,
      Password: password
    };
    return this.http
      .post<any>(this.four51Url + 'login/user', JSON.stringify(json), httpOptions);
  }

  getUser(token: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http
      .get(this.four51Url + 'user', httpOptions)
      .pipe(catchError(this.handleError));
  }

  createUser(user: MdvipUser): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.tokenService.token
      }),
      observe: 'response' as 'body'
    };
    return this.http
      .post<any>(this.four51Url + 'user', JSON.stringify(user), httpOptions);
  }

  setCurrentOrder(orderId, token?: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token || this.tokenService.token
      }),
      observe: 'response' as 'body'
    };
    return this.http
      .post<any>(this.four51Url + 'user/currentorder/' + orderId, JSON.stringify({id: orderId}), httpOptions);
  }

  getPhysicianProfile(physicianId): Observable<any> {
    // TODO this should be in a different service
    // const url = 'http://localhost:3000/';
    // const url = 'https://mdvipsfauthqa.artoftechnology.com/';
    const url = environment.apiBaseUrl;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.tokenService.token
      })
    };
    return this.http
      .get(url + 'getPhyicianProfile?id=' + physicianId, httpOptions);
  }

  createVariant(variant, token?: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token || this.tokenService.token
      }),
      observe: 'response' as 'body'
    };
    return this.http
      .post<any>(this.four51Url + 'variant', JSON.stringify(variant), httpOptions);
  }

  createOrUpdateOrder(order, token?: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': token || this.tokenService.token
      }),
      observe: 'response' as 'body'
    };
    return this.http
      .post<any>(this.four51Url + 'order', JSON.stringify(order), httpOptions);
  }

  getOrderStats() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.tokenService.token
      })
    };
    return this.http
      .get(this.four51Url + 'orderstats', httpOptions);
  }

  getOrders(status: string, count?: number, token?: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token || this.tokenService.token
      })
    };
    let endpoint;
    if (count) {
      endpoint = 'order?page=1&pagesize=' + count + '&Status=' + status;
    } else {
      endpoint = 'order?page=1&pagesize=100&Status=' + status;
    }
    // endpoint += '&DateRangeFrom=2022-04-19T00:00:00&DateRangeTo=2022-04-20T00:00:00';
    return this.http
      .get(this.four51Url + endpoint, httpOptions);
  }

  getOrdersPaged(status: string, page: number, count: number, token?: string) {
    // TODO merge with getOrders funtcion above
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token || this.tokenService.token
      })
    };
    const endpoint = 'order?page=' + page + '&pagesize=' + count + '&Status=' + status;
    return this.http
      .get(this.four51Url + endpoint, httpOptions);
  }

  getOrdersByDateRange(startDate: moment.Moment, endDate: moment.Moment, pagesize: number, status?: string, ) {
    startDate.startOf('day');
    endDate.endOf('day');
    const startDateString = startDate.format('YYYY-MM-DDThh:mm:ss');
    const endDateString = endDate.format('YYYY-MM-DDThh:mm:ss');
    let query = '?DateRangeFrom=' + startDateString + '&DateRangeTo=' + endDateString;
    if (status) {
      query += '&Status=' + status;
    }
    query += '&page=1&pagesize=' + pagesize;
    // console.log(query);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.tokenService.token
      })
    };
    return this.http
      .get(this.four51Url + 'order' + query, httpOptions);
  }

  getOrderById(orderId: string, token?: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token || this.tokenService.token
      })
    };
    return this.http
      .get(this.four51Url + 'order/' + orderId, httpOptions);
  }

  putOrder(order, token?: string): Observable<any> {
    if (!environment.production) {
      order.ExternalID = 'TEST-' + moment().format('YYYYMMDD-HHmmss');
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': token || this.tokenService.token
      }),
      observe: 'response' as 'body'
    };
    return this.http
      .put<any>(this.four51Url + 'order', JSON.stringify(order), httpOptions);
  }

  deleteOrder(orderId: string, lineItemId: string, token?: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token || this.tokenService.token
      })
    };
    return this.http
      .delete(this.four51Url + 'order/' + orderId + '/lineitem/' + lineItemId, httpOptions);
  }

  approveOrder(orderId: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.tokenService.token
      })
    };
    return this.http
      .put(this.four51Url + 'order/approve/' + orderId, null, httpOptions);
  }

  declineOrder(orderId: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.tokenService.token
      })
    };
    return this.http
      .put(this.four51Url + 'order/decline/' + orderId, null, httpOptions);
  }

  deleteVariant(variantId, productInteropId, token?: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token || this.tokenService.token
      })
    };
    return this.http
      .delete(this.four51Url + 'variant?InteropID=' + variantId + '&ProductInteropID=' + productInteropId, httpOptions);
  }

  getAddresses(type, token?: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token || this.tokenService.token
      })
    };
    return this.http
      .get(this.four51Url + 'address/' + type, httpOptions);
  }

  createAddress(address, token?: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token || this.tokenService.token
      }),
      observe: 'response' as 'body'
    };
    return this.http
      .post<any>(this.four51Url + 'address', JSON.stringify(address), httpOptions);
  }

  getShippers(orderId) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.tokenService.token
      })
    };
    return this.http
      .get(this.four51Url + 'shipper?id=' + orderId, httpOptions);
  }

  uploadFile(fileObject, token?: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': token || this.tokenService.token
      }),
      observe: 'response' as 'body'
    };
    return this.http
      .post<any>(this.four51Url + 'uploadfile', JSON.stringify(fileObject), httpOptions);
  }

  getProduct(productID: string, token?: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token || this.tokenService.token
      })
    };
    return this.http
      .get(this.four51Url + 'Products/' + productID, httpOptions);
  }

  getOrderShipments(orderId: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.tokenService.token
      })
    };
    return this.http
      .get(this.four51Url + 'order/' + orderId + '/shipments', httpOptions);
  }

  createCampaign(data: any) {
    const url = environment.apiBaseUrl;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.tokenService.token
      })
    };
    return this.http
      .post(url + 'createCampaign', data, httpOptions)
  }

  private handleError(error: HttpErrorResponse) {
    return throwError(error);
  }

}
