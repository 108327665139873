import {
  Component,
  Input, OnInit
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {Four51Service} from './four51.service';
import {CartService} from './cart.service';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';
import * as moment from 'moment';
import {KnackService} from './knack.service';
import {OrderLimitResponse, OrderLimitService} from './order-limit.service';

@Component({
  selector: 'app-pdf-modal-component',
  template: `
      <div class="modal-header">
          <h4 class="modal-title">Edit {{product.Name}}</h4>
          <div *ngIf="shouldShowFit" class="btn-group ml-auto" role="group" aria-label="Order Types">
              <button type="button" class="btn btn-outline-primary btn-sm" [class.active]="shouldFit"
                      (click)="shouldFit = true">Fit in Window</button>
              <button type="button" class="btn btn-outline-primary btn-sm" [class.active]="!shouldFit"
                      (click)="shouldFit = false">Max Size</button>
          </div>
          <button type="button" ngbAutofocus class="close" [class.ml-0]="shouldShowFit" aria-label="Close" (click)="activeModal.dismiss()">
              <span aria-hidden="true">&times;</span>
          </button>
      </div>
      <div class="modal-body modal-body-proof d-flex no-gutters" *ngIf="product.SpecCount > 0">
          <div class="col-lg-4">
              <app-spec [specs]="variant.Specs" [isEditing]="true"
                        (specsChange)="specsChange($event)"></app-spec>
          </div>
          <div class="col-lg-8">
              <div class="proof-wrapper" *ngIf="imageUrl">
                  <img [src]="imageUrl" [class.fit]="shouldFit" (load)="onPreviewLoaded($event)" (error)="onPreviewError($event)"
                       [alt]="product.Name + 'Preview Image'" />
                  <div class="activity d-flex" *ngIf="isWaiting">
                      <div class="spinner-border text-light m-auto" role="status" >
                          <span class="sr-only">Loading...</span>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div class="modal-footer">
              <button type="buton" class="btn btn-primary" (click)="activeModal.close()" [disabled]="!isProofed || isUpdating || isWaiting">
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="isUpdating"></span>
                  Done
              </button>
          <div *ngIf="errorMessage" class="alert alert-danger my-0" [innerHTML]="errorMessage"></div>
      </div>
  `,
  styleUrls: ['./modal.component.scss'],
  styles: [`
    .proof-wrapper {
      height: 100%;
      overflow-y: scroll;
    }
    .activity {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(0,0,0,0.4);
      z-index: 99;
    }
    .fit {
        max-height: 100%;
        max-width: 100%;
    }`
  ]
})
export class ProductEditModalComponent implements OnInit {

  @Input() product: any;
  @Input() variant: any;
  public isProofed = true;
  private imageUrl;
  private proofUrl;
  public isWaiting: boolean;
  public isUpdating = false;
  public errorMessage: string;
  public shouldFit = false;
  public shouldShowFit = false;

  constructor(public activeModal: NgbActiveModal, private four51Service: Four51Service) {}

  ngOnInit(): void {
    this.isWaiting = true;
    this.imageUrl = this.variant.PreviewUrl + '?r=' + Math.random();
  }
  onPreviewLoaded(e) {
    this.isWaiting = false;
    if (e.target.offsetWidth > 960) {
      this.shouldShowFit = true;
    } else {
      this.shouldShowFit = false;
    }
  }

  onPreviewError(e) {
    console.log(e);
    this.isWaiting = false;
    this.errorMessage = 'ATTENTION: We are unable to process this request due to an issue with the photo on file. ' +
      'Please email <a href=mailto:PhysicianPhotos@mdvip.com">PhysicianPhotos@mdvip.com</a> for assistance.';
  }

  specsChange(isFormValid: boolean) {
    this.isProofed = isFormValid;
    this.updateVariant();
  }

  async updateVariant() {
    this.isWaiting = true;
    const response = await this.four51Service.createVariant(this.variant).toPromise();
    this.variant = response.body;
    this.imageUrl = this.variant.PreviewUrl + '?r=' + Math.random();
  }
}
