// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  emailTo: ['tom@artoftechnology.com', 'Marketing Support Site <MarketingSupportSite@mdvip.com>', 'skupchun@seprint.com'],
  emailBcc: ['skupchun@seprint.com'],
  mdvipConnectUrl: 'https://support-mdvip-idp.cs20.force.com/',
  isTest: true,
  apiBaseUrl: 'https://mdvipsfauthsup.artoftechnology.com/', // 'https://mdvipsfauth.seprint.com/',
  storefrontUrl: 'https://teststore.four51.com/mdvip/'
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
