import {Injectable} from '@angular/core';
import {EventInfo} from '../../models/event-info';
import {NgbDate} from '@ng-bootstrap/ng-bootstrap';
import {Four51Service} from '../../four51.service';
import * as moment from 'moment-timezone';

@Injectable({
  providedIn: 'root'
})
export class FacebookService {

  constructor(private four51Service: Four51Service) {}

  async refresh(variantToUpdate: any, eventInfo: EventInfo) {
    const eventTimeZone = eventInfo.sessions[0].eventStartTime.timeZone;
    const eventDateMoment = moment(this.ngbDateToString(eventInfo.sessions[0].eventDate), 'YYYY-MM-DD');
    let eventDateText;
    if (eventInfo.sessions.length === 1 ) {
      eventDateText = eventDateMoment.format('dddd, MMMM D') + ', ' +
        eventInfo.sessions[0].eventStartTime.moment.tz(eventTimeZone).format('h:mm A');
    } else {
      eventDateText = 'The first Session is on ' + eventDateMoment.format('dddd, MMMM D') + ' at ' +
        eventInfo.sessions[0].eventStartTime.moment.tz(eventTimeZone).format('h:mm A');
    }
    const data = {
      topicId: eventInfo.topicId,
      byoSelectedTopicId: eventInfo.byoSelectedTopicId,
      sessions: eventInfo.sessions,
      type: eventInfo.type
    };
    let productInteropID;
    switch (eventInfo.type) {
      case 'yoch': {
        productInteropID = 'MDVIP-FacebookYOCH';
        break;
      }
      default: {
        productInteropID = 'MDVIP-Facebook';
        break;
      }
    }
    const variant: any = {
      ProductInteropID: productInteropID,
      Specs: {
        title: {
          Value: eventInfo.title
        },
        event_date_text: {
          Value: eventDateText
        },
        venue_name: {
          Value: eventInfo.venue_name
        },
        venue_address_1: {
          Value: eventInfo.venue_address_1
        },
        venue_address_2: {
          Value: eventInfo.venue_address_2
        },
        venue_city: {
          Value: eventInfo.venue_city
        },
        brief_description: {
          Value: eventInfo.brief_description
        },
        rsvp_phone: {
          Value: eventInfo.rsvp_phone
        },
        rsvp_message: {
          Value: eventInfo.rsvp_message
        },
        venueType: {
          Value: (eventInfo.venueType)
        },
        webinarUrl: {
          Value: eventInfo.webinarUrl
        },
        data: {
          Value: JSON.stringify(data)
        }
      }
    };
    if (variantToUpdate) {
      variant.InteropID = variantToUpdate.InteropID;
    }
    const response = await this.four51Service.createVariant(variant).toPromise();
    return response.body;
  }

  ngbDateToString(object: NgbDate) {
    return (object) ? object.year + '-' + object.month + '-' + object.day : '';
  }
}
