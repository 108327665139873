import {Component, OnDestroy, OnInit} from '@angular/core';
import { Papa } from 'ngx-papaparse';
import {Subscription} from 'rxjs';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {states} from '../../assets/ts/states';
import { NgbDateCustomParserFormatter} from '../dateformat';
import {NgbDate, NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import {KeyValue} from '@angular/common';
import {Four51Service} from '../four51.service';
import {MdvipUserService} from '../mdvip-user.service';
import {environment} from '../../environments/environment';
import {Guid} from 'guid-typescript';

@Component({
  selector: 'dinner-invite-component',
  templateUrl: './dinner-invite.component.html',
  styleUrls: ['./dinner-invite.component.scss'],
  providers: [
    {provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter}
  ]
})

export class DinnerInviteComponent implements OnInit, OnDestroy {
  public fileName;
  private fileBase64;
  public projectName = '';
  public records: any[];
  public step = 1;
  public error;
  private subscription: Subscription;

  public blanks1 = 25;
  public blanks2 = 25;
  public addresses: any;
  public address1: any = {};
  public address2: any = {};
  public states = states;

  public date: NgbDate = null;
  public minDate: NgbDate;
  public time: string;
  public amPm = 'AM';
  public venue;
  public street1;
  public street2;
  public city;
  public state = '';
  public zip;
  public rsvpFax;

  public variant: any;
  public variantBlank: any;
  public isWaiting = false;
  public imageUrl: string;

  public order: any;
  public token;

  public dropDate: NgbDate;
  public minDropDate: NgbDate;
  private isTesting = false;

  public environment = environment;
  public dinnerInviteDrafts: any[] = [];
  public code = '';

  public kits = [
    {
      index: 0,
      name: 'Fork Invitation',
      invitation: 'MD50203',
      envelope: 'MD50205',
      rsvp: 'MD50207',
      rsvpBlank: 'MD50207-BLANK'
    },
    {
      index: 1,
      name: 'Compass Plate Invitation',
      invitation: 'MD50204',
      envelope: 'MD50206',
      rsvp: 'MD50207',
      rsvpBlank: 'MD50207-BLANK'
    }
  ];
  public selectedKit: any = null;

  constructor(private papa: Papa, private route: ActivatedRoute,  private router: Router, private http: HttpClient,
              private four51Service: Four51Service, private mdvipUserService: MdvipUserService) {}

  async ngOnInit() {
    console.log('dinner invite logging out');
    this.mdvipUserService.logout(false);
    const token = this.route.snapshot.queryParams.token;
    if (token) {
      const user = await this.four51Service.getUser(token).toPromise();
      if (user.Username !== 'TemplateUserMDVIP') {
        this.token = token;
      }
    }
    this.subscription = new Subscription();
    const paramMapSubscription = this.route.paramMap.subscribe(async (params: ParamMap) => {
      const step = params.get('step');
      if (step) {
        this.step = +step;
      }
    });
    this.subscription.add(paramMapSubscription);
    const tomorrow = moment().add(1, 'days');
    this.minDate = new NgbDate(tomorrow.year(), tomorrow.month() + 1, tomorrow.date());
    const minDropDateMoment = moment();
    let businessDays = 4;
    do {
      minDropDateMoment.add(1, 'days');
      if (minDropDateMoment.isoWeekday() < 6) {
        businessDays--;
      }
    } while (businessDays > 0);
    this.minDropDate = new NgbDate(minDropDateMoment.year(), minDropDateMoment.month() + 1, minDropDateMoment.date());
    this.addresses = await this.http.get('./assets/json/dinner-invite-addresses.json').toPromise();
    this.address1 = Object.assign(this.address1, this.addresses[0]);
    this.address2 = Object.assign(this.address2, this.addresses[0]);
    if (this.isTesting) {
      this.fillTestValues();
    }
    const unsubmittedOrderResponse: any = await this.four51Service.getOrders('Unsubmitted', null, this.token).toPromise();
    this.dinnerInviteDrafts = unsubmittedOrderResponse.List.filter(x => x.ExternalID && x.ExternalID.startsWith('Dinner Invite - '));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  fileChange(event) {
    const fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      const file: File = fileList[0];
      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        this.variant = null;
        const fileText = fileReader.result;
        // this.fileBase64 = btoa(fileText.toString());
        this.fileBase64 = btoa(unescape(encodeURIComponent(fileText.toString())));
        this.fileName = file.name;
        this.papa.parse(fileText.toString(), {
          header: true,
          skipEmptyLines: true,
          complete: (result: any) => {
            console.log(result);
            if (result.data.length > 0) {
              this.records = result.data;
            } else {
              this.error = 'This does not appear to be a valid CSV file.';
            }
          }
        });
      };
      fileReader.readAsText(file);
    }
  }

  goToStep(f, step: number) {
    let ok: boolean;
    if (f) {
      ok = f.valid;
    } else {
      ok = true;
    }
    if (ok) {
      this.router.navigate(['/dinnerinvite', {step: step}]);
      if (step === 4) {
        this.showProof();
      }
    }
  }

  originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return 0;
  }

  selectAddress(addressNo, e) {
    const prop = 'address' + addressNo.toString();
    this[prop] = Object.assign(this[prop], this.addresses[e.target.selectedIndex]);
  }

  amPmClicked() {
    this.amPm = (this.amPm === 'AM') ? 'PM' : 'AM';
  }

  eventTimeModelChanged() {
    // append leading zero if first digit is not zero or one
    if (this.time.length === 1) {
      const hour: number = +this.time.substring(0, 1);
      if (!isNaN(hour)) {
        if (hour > 1) {
          this.time = '0'.concat(this.time);
        }
      }
    }
  }

  setCode() {
    this.code = this.ngbDateToMoment(this.date).format('M-D') + this.state + 'I';
  }

  async showProof() {
    this.isWaiting = true;
    let uploadedFileId;
    if (!this.variant) {
      // upload file
      const productResp: any = await this.four51Service.getProduct('MD64445', this.token).toPromise();
      const fileID = productResp.Specs.V14DistributionList.ID;
      const sourceID = productResp.Specs.V14DistributionList.SourceID;
      const sourceType = 'Product';
      const fileObject = {
        Data: 'data:text/csv;base64,' + this.fileBase64,
        ID: fileID,
        Name: this.fileName,
        SourceID: sourceID,
        SourceType: sourceType
      };
      const fileResponse = await this.four51Service.uploadFile(fileObject, this.token).toPromise();
      console.log(fileResponse.body);
      uploadedFileId = fileResponse.body.ID;
    }
    const dropDateText = (this.dropDate) ? this.ngbDateToMoment(this.dropDate).format('MM/DD/YYYY') : '';
    const variant: any = {
      ProductInteropID: this.selectedKit.rsvp,
      Specs: {
        ProjectName: {
          Value: this.projectName
        },
        V00Date: {
          Value: this.ngbDateToMoment(this.date).format('dddd, MMMM D, YYYY')
        },
        V00Date2: {
          Value: this.code
        },
        V01Time: {
          Value: ((this.time.substring(0, 1) === '0') ? this.time.substring(1) : this.time) + ' ' + this.amPm
        },
        V02Venue: {
          Value: this.venue
        },
        V02VenueAddress1: {
          Value: this.street1
        },
        V02VenueAddress2: {
          Value: this.street2
        },
        V02VenueCity: {
          Value: this.city
        },
        V02VenueState: {
          Value: this.state
        },
        V02VenueZip: {
          Value: this.zip
        },
        V03Fax: {
          Value: this.rsvpFax
        },
        DistributionList: {
          Value: uploadedFileId
        },
        DropDate: { 
          Value: dropDateText
        }
      }
    };
    if (this.variant) {
      variant.InteropID = this.variant.InteropID;
    }
    const response = await this.four51Service.createVariant(variant, this.token).toPromise();
    this.variant = response.body;
    this.refreshProof();
  }

  ngbDateToMoment(ngbDate: NgbDate): moment.Moment {
    const dateString = ngbDate.month + '/' + ngbDate.day + '/' + ngbDate.year;
    return moment(dateString, 'M/D/YYYY');
  }

  onPreviewLoaded() {
    this.isWaiting = false;
  }

  onPreviewError(e) {
    console.log(e);
    this.isWaiting = false;
  }

  refreshProof() {
    this.imageUrl = this.variant.PreviewUrl + '?r=' + Math.random();
  }

  async saveOrder(externalId: string) {
    if (!this.order) {
      const qty = this.records.length;
      this.order = {
        Type: 'Standard',
      };
      this.order.LineItems = [
        {
          Product: {
            InteropID: this.selectedKit.rsvp
          },
          Quantity: qty,
          Variant: {
            InteropID: this.variant.InteropID
          }
        },
        {
          Product: {
            InteropID: this.selectedKit.invitation
          },
          Quantity: qty
        },
        {
          Product: {
            InteropID: this.selectedKit.envelope
          },
          Quantity: qty
        }
      ];
    }
    this.order.ExternalID = externalId;
    this.variantBlank = null; // set null so we can test for existence and re-use
    if (this.blanks1 && this.address1.Title) {
      await this.addBlanks(1);
    }
    if (this.blanks2 && this.address2.Title) {
      await this.addBlanks(2);
    }
    const response: any = await this.four51Service.createOrUpdateOrder(this.order, this.token).toPromise();
    this.order = response.body;
    console.log(this.order);
    const resp = await this.four51Service.setCurrentOrder(this.order.ID, this.token).toPromise();
  }

  async saveForLater() {
    this.isWaiting = true;
    await this.saveOrder('Dinner Invite - ' + this.projectName + ' ' + this.ngbDateToMoment(this.date).format('YYYY-MM-DD'));
    this.isWaiting = false;
    this.goToStep(null, 6);
  }

  async completeDraft(orderId: string) {
    this.order = await this.four51Service.getOrderById(orderId, this.token).toPromise();
    const rsvpLineItem = this.order.LineItems.find(x => this.kits.map(x => x.rsvp).includes(x.Product.InteropID));
    console.log(rsvpLineItem);
    if (rsvpLineItem) {
      this.variant = rsvpLineItem.Variant;
      this.projectName = this.variant.Specs.ProjectName.Value;
    }
    this.goToStep(null, 5);
  }

  async deleteDraft(orderId: string) {
    const order: any = await this.four51Service.getOrderById(orderId, this.token).toPromise();
    let variant;
    for (const lineItem of order.LineItems) {
      await this.four51Service.deleteOrder(order.ID, lineItem.ID, this.token).toPromise();
      if (lineItem.Variant) {
        variant = lineItem.Variant;
      }
    }
    if (variant) {
      await this.four51Service.deleteVariant(variant.InteropID, variant.ProductInteropID, this.token).toPromise();
    }
    this.dinnerInviteDrafts = this.dinnerInviteDrafts.filter(x => x.ID !== order.ID);
  }

  async finish(f) {
    if (f.valid) {
      this.isWaiting = true;

      // save drop date in variant
      this.variant.Specs.DropDate.Value = this.ngbDateToMoment(this.dropDate).format('MM/DD/YYYY');
      const response = await this.four51Service.createVariant(this.variant, this.token).toPromise();
      this.variant = response.body;

      // save order and clear external id if it was a draft
      await this.saveOrder(null);
      // shipping address
      // TODO storefront users do not have access to this
      const shippingResponse: any = await this.four51Service.getAddresses('shipping', this.token).toPromise();
      const shippingAddress = shippingResponse.List.find(x => x.InteropID === 'MDVIP-USPS');
      if (shippingAddress) {
        for (const lineItem of this.order.LineItems) {
          if (!lineItem.ShipAddressID) {
            lineItem.ShipAddressID = shippingAddress.ID;
          }
        }
      }
      // billing address
      const billingResponse: any = await this.four51Service.getAddresses('billing', this.token).toPromise();
      const billingAddress = billingResponse.List.find(x => x.AddressName === 'MDVIP, LLC' && x.Zip === '33431');
      if (billingAddress) {
        this.order.BillAddressID = billingAddress.ID;
      }
      const orderResponse = await this.four51Service.putOrder(this.order, this.token).toPromise();
      this.order = orderResponse.body;
      this.isWaiting = false;
      this.goToStep(null, 7);
    }
  }

  async addBlanks(num: number) {
    const qty = this['blanks' + num.toFixed(0)];
    const address: any = this['address' + num.toFixed(0)];
    const spaceIndex = address.Title.indexOf(' ');
    let firstName;
    let lastName;
    if (spaceIndex >= 0) {
      firstName = address.Title.substring(0, spaceIndex - 1);
      lastName = address.Title.substring(spaceIndex + 1);
    } else {
      firstName = address.Title;
      lastName = address.Title;
    }
    const shippingResponse: any = await this.four51Service.getAddresses('shipping', this.token).toPromise();
    let shippingAddress = shippingResponse.List.find(x => x.Street1 === address.Address && x.City === address.City);
    if (!shippingAddress) {
      const shippingAddressResponse = await this.four51Service.createAddress({
        InteropID: Guid.raw(),
        AddressName: address.Address + ' ' + address.City,
        CompanyName: '',
        Street1: address.Address,
        Street2: '',
        City: address.City,
        State: address.ST,
        Zip: address.Zip,
        Country: 'US',
        Phone: address.CellPhone,
        IsCustEditable: true,
        FirstName: firstName,
        LastName: lastName,
        IsShipping: true,
        IsBilling: false
      }, this.token).toPromise();
      shippingAddress = shippingAddressResponse.body;
    }

    // variant for blank rsvp
    if (!this.variantBlank) {
      const specsCopy = JSON.parse(JSON.stringify(this.variant.Specs));
      delete specsCopy.DistributionList;
      const variantBlank: any = {
        ProductInteropID: this.selectedKit.rsvpBlank,
        Specs: specsCopy
      };
      if (this.variantBlank) {
        variantBlank.InteropID = this.variant.InteropID;
      }
      const response = await this.four51Service.createVariant(variantBlank, this.token).toPromise();
      this.variantBlank = response.body;
    }
    
    const rsvpLineItem = {
      Product: {
        InteropID: this.selectedKit.rsvpBlank
      },
      Quantity: qty,
      Variant: {
        InteropID: this.variantBlank.InteropID
      },
      ShipAddressID: shippingAddress.ID
    };
    const invitationLineItem = {
      Product: {
        InteropID: this.selectedKit.invitation
      },
      Quantity: qty,
      ShipAddressID: shippingAddress.ID
    };
    const envelopeLineItem = {
      Product: {
        InteropID: this.selectedKit.envelope
      },
      Quantity: qty,
      ShipAddressID: shippingAddress.ID
    };
    this.order.LineItems.push(rsvpLineItem);
    this.order.LineItems.push(invitationLineItem);
    this.order.LineItems.push(envelopeLineItem);
  }

  fillTestValues() {
    this.date = new NgbDate(2022, 6, 30);
    this.time = '07:00';
    this.amPm = 'PM';
    this.venue = 'The Grand Hotel';
    this.street1 = '89 Fall St';
    this.street2 = '';
    this.city = 'Bedford Falls';
    this.state = 'NY';
    this.zip = '13148';
    this.setCode();
  }

}

