import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html'
})

export class PopoverComponent {
  @Input() disabled: boolean;
}
