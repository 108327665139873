import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class KnackService {
  private knackUrl = 'https://api.knack.com/v1/pages/';
  private applicationId = '5e2a0e0e4150520015266115';
  private tables = {
    practice_member_counts: 'scene_6/views/view_8/'
  }
  constructor (private http: HttpClient) {}
  getMemberCount(practiceID: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'X-Knack-Application-Id': this.applicationId,
        'X-Knack-REST-API-KEY': 'knack'
      })
    };
    const filters = [
      {
        field: 'field_28',
        operator: 'is',
        value: practiceID
      }
    ];
    return this.http
      .get(this.knackUrl + this.tables.practice_member_counts + 'records?filters=' +
        encodeURIComponent(JSON.stringify(filters)), httpOptions)
      .pipe(map((resp: any) => ({
        patientCount: resp.records[0].field_33,
        folderHistory: resp.records[0].field_45
      })));
  }
}


