const fontStyles = [
  {
    class: 'ql-font-Regular',
    name: 'Regular',
    colorName: null,
    style: 'regular',
    label: 'Regular',
    color: null,
    fontWeight: 'normal',
    fontStyle: 'normal',
    pffont: 'Proxima Nova Rg',
    pfbold: 'false',
    pfitalic: 'false',
    pfcolor: null,
    approverOnly: false
  },
  {
    class: 'ql-font-Bold',
    name: 'Bold',
    colorName: null,
    style: 'bold',
    label: 'Bold',
    color: null,
    fontWeight: 'bold',
    fontStyle: 'normal',
    pffont: 'Proxima Nova Lt',
    pfbold: 'true',
    pfitalic: 'false',
    pfcolor: null,
    approverOnly: false
  },
  {
    class: 'ql-font-Italic',
    name: 'Italic',
    colorName: null,
    style: 'italic',
    label: 'Italic',
    color: null,
    fontWeight: 'normal',
    fontStyle: 'italic',
    pffont: 'Proxima Nova Rg',
    pfbold: 'false',
    pfitalic: 'true',
    pfcolor: null,
    approverOnly: false
  },
  {
    class: 'ql-font-BoldItalic',
    name: 'BoldItalic',
    colorName: null,
    style: 'bold italic',
    label: 'Bold Italic',
    color: null,
    fontWeight: 'bold',
    fontStyle: 'italic',
    pffont: 'Proxima Nova Lt',
    pfbold: 'true',
    pfitalic: 'true',
    pfcolor: null,
    approverOnly: false
  },
  {
    class: 'ql-font-BronzeRegular',
    name: 'BronzeRegular',
    colorName: 'bronze',
    style: 'regular',
    label: 'Bronze Regular',
    color: '#664f3f',
    fontWeight: 'normal',
    fontStyle: 'normal',
    pffont: 'Proxima Nova Rg',
    pfbold: 'false',
    pfitalic: 'false',
    pfcolor: 'MDVIP_BRONZE',
    approverOnly: true
  },
  {
    class: 'ql-font-BronzeBold',
    name: 'BronzeBold',
    colorName: 'bronze',
    style: 'bold',
    label: 'Bronze Bold',
    color: '#664f3f',
    fontWeight: 'bold',
    fontStyle: 'normal',
    pffont: 'Proxima Nova Lt',
    pfbold: 'true',
    pfitalic: 'false',
    pfcolor: 'MDVIP_BRONZE',
    approverOnly: true
  },
  {
    class: 'ql-font-BronzeItalic',
    name: 'BronzeItalic',
    colorName: 'bronze',
    style: 'italic',
    label: 'Bronze Italic',
    color: '#664f3f',
    fontWeight: 'normal',
    fontStyle: 'italic',
    pffont: 'Proxima Nova Rg',
    pfbold: 'false',
    pfitalic: 'true',
    pfcolor: 'MDVIP_BRONZE',
    approverOnly: true
  },
  {
    class: 'ql-font-BronzeBoldItalic',
    name: 'BronzeBoldItalic',
    colorName: 'bronze',
    style: 'bold italic',
    label: 'Bronze Bold Italic',
    color: '#664f3f',
    fontWeight: 'bold',
    fontStyle: 'italic',
    pffont: 'Proxima Nova Lt',
    pfbold: 'true',
    pfitalic: 'true',
    pfcolor: 'MDVIP_BRONZE',
    approverOnly: true
  },
  {
    class: 'ql-font-BlackRegular',
    name: 'BlackRegular',
    colorName: 'black',
    style: 'regular',
    label: 'Black Regular',
    color: '#000000',
    fontWeight: 'normal',
    fontStyle: 'normal',
    pffont: 'Proxima Nova Rg',
    pfbold: 'false',
    pfitalic: 'false',
    pfcolor: 'YOCH_BLACK',
    approverOnly: true
  },
  {
    class: 'ql-font-BlackBold',
    name: 'BlackBold',
    colorName: 'black',
    style: 'bold',
    label: 'Black Bold',
    color: '#000000',
    fontWeight: 'bold',
    fontStyle: 'normal',
    pffont: 'Proxima Nova Lt',
    pfbold: 'true',
    pfitalic: 'false',
    pfcolor: 'YOCH_BLACK',
    approverOnly: true
  },
  {
    class: 'ql-font-BlackItalic',
    name: 'BlackItalic',
    colorName: 'black',
    style: 'italic',
    label: 'Black Italic',
    color: '#000000',
    fontWeight: 'normal',
    fontStyle: 'italic',
    pffont: 'Proxima Nova Rg',
    pfbold: 'false',
    pfitalic: 'true',
    pfcolor: 'YOCH_BLACK',
    approverOnly: true
  },
  {
    class: 'ql-font-BlackBoldItalic',
    name: 'BlackBoldItalic',
    colorName: 'black',
    style: 'bold italic',
    label: 'Black Bold Italic',
    color: '#000000',
    fontWeight: 'bold',
    fontStyle: 'italic',
    pffont: 'Proxima Nova Lt',
    pfbold: 'true',
    pfitalic: 'true',
    pfcolor: 'YOCH_BLACK',
    approverOnly: true
  },
  {
    class: 'ql-font-OrangeRegular',
    name: 'OrangeRegular',
    colorName: 'orange',
    style: 'regular',
    label: 'Orange Regular',
    color: '#de6400',
    fontWeight: 'normal',
    fontStyle: 'normal',
    pffont: 'Proxima Nova Rg',
    pfbold: 'false',
    pfitalic: 'false',
    pfcolor: 'MDVIP_ORANGE',
    approverOnly: true
  },
  {
    class: 'ql-font-OrangeBold',
    name: 'OrangeBold',
    colorName: 'orange',
    style: 'bold',
    label: 'Orange Bold',
    color: '#de6400',
    fontWeight: 'bold',
    fontStyle: 'normal',
    pffont: 'Proxima Nova Lt',
    pfbold: 'true',
    pfitalic: 'false',
    pfcolor: 'MDVIP_ORANGE',
    approverOnly: true
  },
  {
    class: 'ql-font-OrangeItalic',
    name: 'OrangeItalic',
    colorName: 'orange',
    style: 'italic',
    label: 'Orange Italic',
    color: '#de6400',
    fontWeight: 'normal',
    fontStyle: 'italic',
    pffont: 'Proxima Nova Rg',
    pfbold: 'false',
    pfitalic: 'true',
    pfcolor: 'MDVIP_ORANGE',
    approverOnly: true
  },
  {
    class: 'ql-font-OrangeBoldItalic',
    name: 'OrangeBoldItalic',
    colorName: 'orange',
    style: 'bold italic',
    label: 'Orange Bold Italic',
    color: '#de6400',
    fontWeight: 'bold',
    fontStyle: 'italic',
    pffont: 'Proxima Nova Lt',
    pfbold: 'true',
    pfitalic: 'true',
    pfcolor: 'MDVIP_ORANGE',
    approverOnly: true
  },
  {
    class: 'ql-font-RedRegular',
    name: 'RedRegular',
    colorName: 'red',
    style: 'regular',
    label: 'Red Regular',
    color: '#b60027',
    fontWeight: 'normal',
    fontStyle: 'normal',
    pffont: 'Proxima Nova Rg',
    pfbold: 'false',
    pfitalic: 'false',
    pfcolor: 'YOCH_RED',
    approverOnly: true
  },
  {
    class: 'ql-font-RedBold',
    name: 'RedBold',
    colorName: 'red',
    style: 'bold',
    label: 'Red Bold',
    color: '#b60027',
    fontWeight: 'bold',
    fontStyle: 'normal',
    pffont: 'Proxima Nova Lt',
    pfbold: 'true',
    pfitalic: 'false',
    pfcolor: 'YOCH_RED',
    approverOnly: true
  },
  {
    class: 'ql-font-RedItalic',
    name: 'RedItalic',
    colorName: 'red',
    style: 'italic',
    label: 'Red Italic',
    color: '#b60027',
    fontWeight: 'normal',
    fontStyle: 'italic',
    pffont: 'Proxima Nova Rg',
    pfbold: 'false',
    pfitalic: 'true',
    pfcolor: 'YOCH_RED',
    approverOnly: true
  },
  {
    class: 'ql-font-RedBoldItalic',
    name: 'RedBoldItalic',
    colorName: 'red',
    style: 'bold italic',
    label: 'Red Bold Italic',
    color: '#b60027',
    fontWeight: 'bold',
    fontStyle: 'italic',
    pffont: 'Proxima Nova Lt',
    pfbold: 'true',
    pfitalic: 'true',
    pfcolor: 'YOCH_RED',
    approverOnly: true
  },
  {
    class: 'ql-font-EmailGrayRegular',
    name: 'EmailGrayRegular',
    colorName: 'email gray',
    style: 'regular',
    label: 'Email Gray Regular',
    color: '#444444',
    fontWeight: 'normal',
    fontStyle: 'normal',
    pffont: 'Proxima Nova Rg',
    pfbold: 'false',
    pfitalic: 'false',
    pfcolor: 'YOCH_BLACK',
    approverOnly: true
  },
  {
    class: 'ql-font-EmailGrayBold',
    name: 'EmailGrayBold',
    colorName: 'email gray',
    style: 'bold',
    label: 'Email Gray Bold',
    color: '#444444',
    fontWeight: 'bold',
    fontStyle: 'normal',
    pffont: 'Proxima Nova Lt',
    pfbold: 'true',
    pfitalic: 'false',
    pfcolor: 'YOCH_BLACK',
    approverOnly: true
  },
  {
    class: 'ql-font-EmailGrayItalic',
    name: 'EmailGrayItalic',
    colorName: 'email gray',
    style: 'italic',
    label: 'Email Gray Italic',
    color: '#444444',
    fontWeight: 'normal',
    fontStyle: 'italic',
    pffont: 'Proxima Nova Rg',
    pfbold: 'false',
    pfitalic: 'true',
    pfcolor: 'YOCH_BLACK',
    approverOnly: true
  },
  {
    class: 'ql-font-EmailGrayBoldItalic',
    name: 'EmailGrayBoldItalic',
    colorName: 'email gray',
    style: 'bold italic',
    label: 'Email Gray Bold Italic',
    color: '#444444',
    fontWeight: 'bold',
    fontStyle: 'italic',
    pffont: 'Proxima Nova Lt',
    pfbold: 'true',
    pfitalic: 'true',
    pfcolor: 'YOCH_BLACK',
    approverOnly: true
  }
];

export { fontStyles };

