import {Injectable, OnDestroy, OnInit} from '@angular/core';
import {Guid} from 'guid-typescript';
import {Four51Service} from './four51.service';

@Injectable({
  providedIn: 'root'
})
export class AddressService {
  constructor(private four51Service: Four51Service) {}

  async findOrCreateShippingAddress(shippingAddresses: any[], companyName: string, street1: string, street2: string, city: string,
                                    state: string, zip: string, country: string, phone: string, firstName: string, lastName: string)  {
    const matchingShippingAddresses = shippingAddresses
      .filter( x => x.Street1 === street1 && x.City === city);
    let shippingAddress;
    if (matchingShippingAddresses.length > 0) {
      shippingAddress = matchingShippingAddresses[0];
      if (shippingAddress.InteropID === null) { // create an interopID if the address does not have one
        shippingAddress.InteropID = Guid.raw();
        const updatedAddress: any = await this.four51Service.createAddress(shippingAddress).toPromise();
        shippingAddress = updatedAddress.body;
      }
    } else {
      // create new shipping address if needed
      const newAddress: any = await this.four51Service.createAddress({
        InteropID: Guid.raw(),
        AddressName: street1 + ' ' + city,
        CompanyName: companyName,
        Street1: street1,
        Street2: street2,
        City: city,
        State: state,
        Zip: zip,
        Country: 'US',
        Phone: phone,
        IsCustEditable: true,
        FirstName: firstName,
        LastName: lastName,
        IsShipping: true,
        IsBilling: false
      }).toPromise();
      shippingAddress = newAddress.body;
    }
    return shippingAddress;
  }
}
