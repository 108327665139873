import {
  Component,
  Input
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'modal-component',
  template: `
      <div class="modal-header">
          <h4 class="modal-title">{{title}}</h4>
          <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
              <span aria-hidden="true">&times;</span>
          </button>
      </div>
      <div class="modal-body" [innerHTML]="body | noSanitize">
      </div>
      <div class="modal-footer">
          <button *ngFor="let button of buttons" class="btn btn-primary" [class.btn-primary]="!button.isNeutral"
                  [class.btn-outline-dark]="button.isNeutral" type="button" (click)="onButtonClick(button)" >{{button.label}}</button>
      </div>
  `,
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent {

  @Input() title: string;
  @Input() body: string;
  @Input() buttons: Button[];
  constructor(public activeModal: NgbActiveModal) {}

  onButtonClick(button) {
    if (button.isNeutral) {
      this.activeModal.dismiss();
    } else {
      this.activeModal.close(button.action);
    }
  }
}

export interface Button {
  label: string;
  action: string;
  isNeutral: boolean;
}
