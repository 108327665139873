import {ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {EventInfo, eventInfoWithVariant} from '../../models/event-info';
import {Four51Service} from '../../four51.service';
import {MdvipUserCookie} from '../../models/mdvip-user-cookie';
import {CookieService} from 'ngx-cookie-service';
import {HttpClient} from '@angular/common/http';
import {EmailInvitationService} from './email-invitation.service';

@Component({
  selector: 'app-email-invitation',
  template: `
      <iframe #htmlEmailiFrame class="email-proof"></iframe>
      <div class="activity d-flex" *ngIf="isWaiting">
          <div class="spinner-border text-light m-auto" role="status" >
              <span class="sr-only">Loading...</span>
          </div>
      </div>
  `,
  styles: [`
    .email-proof {
        width: 100%;
        height: 100%;
        display: block;
        border: none;
        padding: 0;
        margin: 0;
    }
    .activity {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0,0,0,0.4);
        z-index: 99;
    }
  `]
})

export class EmailInvitationComponent implements OnInit {
  @Input() eventInfo: EventInfo;
  private _variant: any;
  @Input()
  set variant(variant: any) {
    this._variant = variant;
    this.eventInfoFromVariant();
    this.refresh();
  }
  @Output() eventInfoChange = new EventEmitter();
  @Output() hiddenFieldsChange = new EventEmitter();
  @Output() variantChange = new EventEmitter();

  public isWaiting: boolean;
  private mdvipUserCookie: MdvipUserCookie;
  private hiddenFields: string[];

  @ViewChild('htmlEmailiFrame', {static: false}) htmlEmailiFrame: ElementRef;
  constructor (private http: HttpClient, private changeDectector: ChangeDetectorRef,
               private four51Service: Four51Service, private cookieService: CookieService,
               private emailInvitationService: EmailInvitationService) {
    this.mdvipUserCookie = JSON.parse(this.cookieService.get('mdvip.user'));
  }

  ngOnInit(): void {
    this.hiddenFields = ['email', 'staffEmail', 'speakervs', 'patient_guests', 'roomCapacity', 'topic', 'title', 'brief_description' ];
    this.hiddenFieldsChange.emit(this.hiddenFields);
  }

  eventInfoFromVariant() {
    console.log('eventInfoFromVariant');
    this.eventInfo = eventInfoWithVariant(this._variant);
    this.eventInfoChange.emit(this.eventInfo);
  }

  async refresh() {
    if (this.eventInfo) {
      this.isWaiting = true;
      this._variant = await this.emailInvitationService.refresh(this._variant, this.eventInfo);
      this.variantChange.emit(this._variant);
      this.refreshProofs();
    }
  }

  async refreshProofs() {
    const htmlEmail = await this.emailInvitationService.getHtml(this._variant, this.eventInfo);
    this.changeDectector.detectChanges();
    const doc = this.htmlEmailiFrame.nativeElement.contentDocument || this.htmlEmailiFrame.nativeElement.contentWidnow;
    doc.open();
    doc.write(htmlEmail);
    doc.close();
    this.isWaiting = false;
  }
}


