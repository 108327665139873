import {NgModule, Injectable, OnDestroy} from '@angular/core';
import {CanActivate, RouterModule, Routes, Router, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {MdvipUser} from './models/mdvip-user';
import {Subscription} from 'rxjs';
import {MdvipUserService} from './mdvip-user.service';
import {HomeComponent} from './home/home.component';
import {LoginComponent} from './login/login.component';
import {EventsComponent} from './events/events.component';
import {HistoryComponent} from './history/history.component';
import {EventApprovalComponent} from './events/event-approval.component';
import {CookieService} from 'ngx-cookie-service';
import {MdvipUserCookie} from './models/mdvip-user-cookie';
import {DinnerInviteComponent} from './dinnerinvite/dinner-invite.component';
import {DinnerMarketingKitComponent} from './dinnermarketingkit/dinner-marketing-kit.component';
import {CartComponent} from './cart/cart.component';
import {PowerpointLibraryComponent} from './powerpoint-library/powerpoint-library.component';
import {TokenService} from './token.service';
import {BannersComponent} from './banners/banners.component';
import {MembershipGiveawayComponent} from './membership-giveaway/membership-giveaway.component';
import {CrmReportComponent} from './crm-report/crm-report.component';
import {EventSiteComponent} from './event-site/event-site.component';


@Injectable()
export class NoAuthGuard implements CanActivate { // read as "guard against no authentication"
  constructor(private router: Router, private tokenService: TokenService) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.tokenService.token) {
      return true;
    }
    this.router.navigate(['/login', {redirectUrl: state.url}]);
    return false;
  }
}

@Injectable()
export class NoApprovalGuard implements CanActivate, OnDestroy {
  private mdvipUserCookie: MdvipUserCookie;
  private subscription: Subscription;
  constructor(private router: Router, private mdvipUserService: MdvipUserService, private cookieService: CookieService) {
    this.refreshCookie();
    this.subscription = new Subscription();
    const userSubscription = this.mdvipUserService.getMdvipUser().subscribe(user => {
      this.refreshCookie();
    });
    this.subscription.add(userSubscription);
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  refreshCookie() {
    if (this.cookieService.check('mdvip.user')) {
      this.mdvipUserCookie = JSON.parse(this.cookieService.get('mdvip.user'));
    } else {
      this.mdvipUserCookie = null;
    }
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.mdvipUserCookie && this.mdvipUserCookie.Groups.filter(x => x.Name === 'Event Approvers').length > 0) {
      return true;
    }
    if (this.mdvipUserCookie) {
      this.router.navigate(['/home']);
    } else {
      this.router.navigate(['/login', {redirectUrl: state.url}]);
    }
    return false;
  }
}

/*const routes: Routes = [
  {path: '', redirectTo: '/login', pathMatch: 'full'},
  { path: 'home', component: HomeComponent, canActivate: [NoAuthGuard] },
  { path: 'home/:categoryid', component: HomeComponent, canActivate: [NoAuthGuard] },
  { path: 'events', component: EventsComponent, canActivate: [NoAuthGuard] },
  { path: 'login', component: LoginComponent},
  { path: 'history', component: HistoryComponent, canActivate: [NoAuthGuard]},
  { path: 'cart', component: CartComponent, canActivate: [NoAuthGuard]},
  { path: 'banners', component: BannersComponent, canActivate: [NoAuthGuard]},
  { path: 'membership-giveaway', component: MembershipGiveawayComponent, canActivate: [NoAuthGuard]},
  { path: 'powerpoint-library', component: PowerpointLibraryComponent, canActivate: [NoAuthGuard]},
  { path: 'approval', component: EventApprovalComponent, canActivate: [NoApprovalGuard]},
  { path: 'crm-report', component: CrmReportComponent, canActivate: [NoApprovalGuard]},
  { path: 'dinnerinvite', component: DinnerInviteComponent},
  { path: 'dinnermarketingkit', component: DinnerMarketingKitComponent},
  ];*/

const routes: Routes = [
  {path: '', redirectTo: '/login', pathMatch: 'full'},
  {path: '', component: EventSiteComponent,
    children: [
      { path: 'home', component: HomeComponent, canActivate: [NoAuthGuard] },
      { path: 'home/:categoryid', component: HomeComponent, canActivate: [NoAuthGuard] },
      { path: 'events', component: EventsComponent, canActivate: [NoAuthGuard] },
      { path: 'events/:type', component: EventsComponent, canActivate: [NoAuthGuard] },
      { path: 'login', component: LoginComponent},
      { path: 'history', component: HistoryComponent, canActivate: [NoAuthGuard]},
      { path: 'cart', component: CartComponent, canActivate: [NoAuthGuard]},
      { path: 'banners', component: BannersComponent, canActivate: [NoAuthGuard]},
      { path: 'membership-giveaway', component: MembershipGiveawayComponent, canActivate: [NoAuthGuard]},
      { path: 'powerpoint-library', component: PowerpointLibraryComponent, canActivate: [NoAuthGuard]},
      { path: 'approval', component: EventApprovalComponent, canActivate: [NoApprovalGuard]},
      { path: 'crm-report', component: CrmReportComponent, canActivate: [NoApprovalGuard]},
    ]
  },
  { path: 'dinnerinvite', component: DinnerInviteComponent},
  { path: 'dinnermarketingkit', component: DinnerMarketingKitComponent},
  { path: 'nameplate', loadChildren: () => import('./nameplate/nameplate.module').then(m => m.NameplateModule)}
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ],
  providers: [
    NoAuthGuard,
    NoApprovalGuard
  ]
})
export class AppRoutingModule {}
