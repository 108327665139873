import {
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  Input,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {EventInfo} from '../models/event-info';
import {InvitationComponent} from './assets/invitation.component';
import {EmailInvitationComponent} from './assets/email-invitation.component';
import {VoiceshotComponent} from './assets/voiceshot.component';
import {FacebookComponent} from './assets/facebook.component';
import {EventWaiverComponent} from './assets/event-waiver.component';
import {MailerMessage, MailerService} from '../mailer.service';
import {EmailInvitationService} from './assets/email-invitation.service';

@Component({
  selector: 'modal-component',
  template: `
      <div class="modal-header">
          <h4 class="modal-title">{{(_lineItem) ? _lineItem.Product.Name : ''}} Proof</h4>
          <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
              <span aria-hidden="true">&times;</span>
          </button>
      </div>
      <div class="modal-body modal-body-proof d-flex no-gutters">
          <div class="col-lg-4">
              <app-event-info [eventInfo]="eventInfo" (eventInfoChange)="eventInfoChange($event)" [hiddenFields]="hiddenFields">
              </app-event-info>
          </div>
          <div class="col-lg-8">
              <template #assetContainer></template>
          </div>
      </div>
      <div class="modal-footer">
          <button *ngIf="productID !== 'MDVIP-InvitationEmail'" class="btn btn-outline-primary" type="button" (click)="download()" >
              <i class="fa fa-download"></i> PDF</button>
          <div class="form-inline" *ngIf="productID === 'MDVIP-InvitationEmail'">
              <div *ngIf="shouldShowSentConfirmation" class="alert alert-success my-0">
                  Test Email Sent
              </div>
              <div class="form-group mx-2">
                  <label for="email" class="sr-only">Email</label>
                  <input type="email" class="form-control" id="Email" placeholder="email" [(ngModel)]="email">
              </div>
              <button class="btn btn-outline-primary" type="button" (click)="testEmail()">Send Test</button>
          </div>
          <button class="btn btn-primary" type="button" (click)="activeModal.dismiss()" >Done</button>
      </div>
  `,
  styleUrls: ['./asset-proof.component.scss']
})
export class AssetProofComponent {

  @ViewChild('assetContainer', { read: ViewContainerRef, static: true }) assetContainer: ViewContainerRef;
  // @Input() masterEventInfo: EventInfo;
  public eventInfo: EventInfo;
  public hiddenFields: string[] = [];
  private assetComponents: any;
  public _lineItem;
  private proofComponentRef: ComponentRef<any>;
  public productID;
  private email;
  private shouldShowSentConfirmation = false;
  @Input() set lineItem(lineItem) {
    this._lineItem = lineItem;
    this.productID = this._lineItem.Variant.ProductInteropID;
    const assetComponent: any = this.assetComponents[this.productID];
    if (assetComponent) {
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(assetComponent);
      this.proofComponentRef = this.assetContainer.createComponent(componentFactory);
      this.proofComponentRef.instance.eventInfoChange.subscribe(eventInfo => {
        this.eventInfo = eventInfo;
      });
      this.proofComponentRef.instance.hiddenFieldsChange.subscribe(hiddenFields => {
        this.hiddenFields = hiddenFields;
        this.changeDetector.detectChanges();
      });
      this.proofComponentRef.instance.variantChange.subscribe(variant => {
        this._lineItem.Variant = variant;
      });
      // this.proofComponentRef.instance.eventInfo = this.masterEventInfo;
      this.proofComponentRef.instance.variant = this._lineItem.Variant;
    } else {
      // show error
    }
  }

  constructor(public activeModal: NgbActiveModal, private componentFactoryResolver: ComponentFactoryResolver,
              private changeDetector: ChangeDetectorRef, private mailerService: MailerService,
              private emailInvitationService: EmailInvitationService) {
    this.assetComponents = {
      'MDVIP-Invitation': InvitationComponent,
      'MDVIP-InvitationYOCH': InvitationComponent,
      'MDVIP-InvitationEmail': EmailInvitationComponent,
      'MDVIP-VoiceShot': VoiceshotComponent,
      'MDVIP-VoiceShotYOCH': VoiceshotComponent,
      'MDVIP-Facebook': FacebookComponent,
      'MDVIP-FacebookYOCH': FacebookComponent,
      'MDVIP-EventWaiver': EventWaiverComponent
    };
  }

  eventInfoChange(e) {
    // this.proofComponentRef.instance.eventInfo = this.eventInfo;
    this.proofComponentRef.instance.refresh();
  }

  download() {
    const w: any = window;
    const isIOS = (/iPad|iPhone|iPod/.test(navigator.platform) ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) &&
      !w.MSStream;
    const url = this._lineItem.Variant.ProofUrl;
    if (isIOS) {
      window.open(url, '_blank');
    } else {
      window.location.replace(url);
    }
  }

  async testEmail() {
    if (this.email.length > 0) {
      const mailerMessage: MailerMessage = new MailerMessage();

      const isTest = true;
      mailerMessage.To = [this.email];
      mailerMessage.Subject = 'Event Invitation Email ' + this.eventInfo.fullName;
      mailerMessage.From = 'Marketing Support Site <MarketingSupportSite@mdvip.com>';
      mailerMessage.Body = 'This message requires an email client that supports HTML email.';
      mailerMessage.Html = await this.emailInvitationService.getHtml(this._lineItem.Variant);
      this.mailerService.sendMessage(mailerMessage);
      this.shouldShowSentConfirmation = true;
    }

  }


}
