import {Component, OnDestroy, OnInit} from '@angular/core';
import {Four51Service} from './four51.service';
import {MdvipUserService} from './mdvip-user.service';
import {Subscription} from 'rxjs';
import {MdvipUser} from './models/mdvip-user';
import {Router} from '@angular/router';
import {CartService} from './cart.service';
import {TokenService} from './token.service';
import {environment} from '../environments/environment';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
  styles: []
})
export class AppComponent implements OnInit, OnDestroy {
  firstname = '';
  lastname = '';
  private subscription: Subscription;
  public mdvipUser: MdvipUser;
  private logoUrl = '';
  private isApprover = false;
  private cartLines = 0;
  // private mdvipUserCookie: MdvipUserCookie;
  constructor(private four51Service: Four51Service, private mdvipUserService: MdvipUserService,
              private router: Router, private cartService: CartService, private tokenService: TokenService) {}
  ngOnInit() {
    this.subscription = new Subscription();
    const mdvipUserSubscription = this.mdvipUserService.getMdvipUser().subscribe((user: MdvipUser) => {
      this.mdvipUser = user;
      // console.log('updated user');
      // console.log(user);
      if (user) {
        this.logoUrl = this.mdvipUser.Company['LogoUrl'];
        this.firstname = this.mdvipUser.FirstName;
        this.lastname = this.mdvipUser.LastName;
        this.isApprover = this.mdvipUser.Groups.filter(x => x.Name === 'Event Approvers').length > 0;
        // console.log(this.mdvipUser.Groups);
        this.cartService.checkCart();
      } else {
        // this.router.navigate(['/login']);
      }
    });
    this.subscription.add(mdvipUserSubscription);
    const cartSubscription = this.cartService.cartObservable().subscribe(cart => {
      if (cart) {
        this.cartLines = cart.LineItems.length;
      } else {
        this.cartLines = 0;
      }
    });
    this.subscription.add(cartSubscription);
    if (this.tokenService.token) {
      console.log(this.tokenService.token);
      this.mdvipUserService.refreshUser(this.tokenService.token);
    }
  }

  brandClicked(e) {
    if (this.mdvipUser) {
      this.router.navigate(['/home']);
    } else {
      e.preventDefault();
      window.location.replace(environment.storefrontUrl);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  logout(e) {
    e.preventDefault();
    this.mdvipUserService.logout(true);
  }
}
