import {Component, OnDestroy, OnInit} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {PdfModalComponent} from '../pdf-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {saveAs} from 'file-saver';

@Component({
  selector: 'app-powerpoint-library',
  template: `
      <div class="container mt-3">
        <div class="row">
        <div class="col-12 col-lg-3">
          <app-menu></app-menu>
        </div>
        <div class="col-lg-9 ">
          <h3>PowerPoint Library</h3>
          <table class="table">
              <thead>
              <tr>
                  <th>Topic</th>
                  <th>Preview</th>
                  <th>PowerPoint File</th>
              </tr>
              </thead>
              <tbody>
              <ng-container *ngFor="let topic of powerpointTopics">
                  <tr *ngFor="let powerpoint of topic.powerpointfile; let i = index;">
                      <td [attr.rowspan]="topic.powerpointfile.length" *ngIf="i === 0" >
                          {{topic.title}}
                          <small *ngIf="topic.type === 'yoch'"> (Cardiovascular Health)</small>
                      </td>
                      <td>
                          <a href="#" class="text-center d-inline-block" (click)="showPreview($event, powerpoint + '.pdf',
                          'Powerpoint Preview')">
                              <div><i class="fa fa-file-o fa-lg"></i></div>
                              <div class="small">Powerpoint</div>
                          </a>
                      </td>
                      <td>
                          <button class="btn btn-primary btn-sm" (click)="downloadFile(powerpoint, 'pptx')"><i class="fa fa-download"></i>
                              Download {{powerpoint}}</button>
                      </td>
                  </tr>
              </ng-container>
              </tbody>
          </table>
        </div>
        </div>
      </div>
  `,
  styles: [`

  `]
})

export class PowerpointLibraryComponent implements OnInit, OnDestroy {
  public powerpointTopics: any;
  constructor(private http: HttpClient, private modalService: NgbModal) {}
  async ngOnInit() {
    const topics: any = await this.http.get('./assets/json/topics.json').toPromise();
    const excludedTopics = [39];
    this.powerpointTopics = topics.filter(x => x.powerpointfile.length > 0 && !excludedTopics.includes(x.id));
    this.powerpointTopics.push({
      title: 'Healthy Holidays Slideshow',
      powerpointfile: ['Healthy_Holidays_Slideshow'],
      type: 'general'
    });
    this.powerpointTopics.sort(function(a, b) {
      if (a.title < b.title) { return -1; }
      if (a.title > b.title) { return 1; }
      return 0;
    });
  }
  ngOnDestroy(): void {
  }

  showPreview(e, file, title) {
    e.preventDefault();
    const pdfSrc = '/assets/pdf/' + file;
    const modalRef = this.modalService.open(PdfModalComponent, { size: 'lg', backdrop: 'static', scrollable: true });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.pdfSrc = pdfSrc;
  }

  async downloadFile(name: string, type: string) {
    const url = './assets/' + type + '/' + name + '.' + type;
    const data = await this.http.get(url, {responseType: 'blob'}).toPromise();
    saveAs(new Blob([data], {type: 'application/' + type}), name + '.' + type);
  }

}
