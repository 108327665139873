import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Four51Service} from './four51.service';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  private cart: any;
  private cartSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  public cartObservable(): Observable<any> {
    return this.cartSubject.asObservable();
  }
  constructor(protected four51Service: Four51Service) {}
  async checkCart() {
    this.cart = null;
    const response: any = await this.four51Service.getOrders('Unsubmitted').toPromise();
    const orderIDs = response.List.map(result => result.ID);
    for (const orderID of orderIDs) {
      const orderResponse: any = await this.four51Service.getOrderById(orderID).toPromise();
      const lineItems = orderResponse.LineItems;
      const invitationLineItem = lineItems.find(x => x.Product.InteropID.startsWith('MDVIP-Invitation'));
      const bannerLineItem = lineItems
        .find(x => x.Product.InteropID === '4E3DE69E-9BCD-4CC5-8558-79D03B58D8F5' ||
          x.Product.InteropID === '1AB6BD4C-A1BA-40FC-9FB5-79BBB3CEA1F4')
      if (!invitationLineItem && !bannerLineItem) {
        this.cart = orderResponse;
        break;
      }
    }
    this.cartSubject.next(this.cart);
  }

  async addToCart(productID: string, quantity: number, variantID?: string) {
    let order: any;
    if (this.cart) {
      const orderResponse: any = await this.four51Service.getOrderById(this.cart.ID).toPromise();
      order = orderResponse;
    } else {
      order = {
        LineItems: [],
        Type: 'Standard'
      };
    }
    const lineItem: any = {
      Product: {
        InteropID: productID
      },
      Quantity: quantity
    };
    if (variantID) {
      lineItem.Variant = {InteropID: variantID};
    }
    order.LineItems.push(lineItem);
    this.saveOrder(order);
  }

  async removeFromCart(lineItemID) {
    const response: any = await this.four51Service.deleteOrder(this.cart.ID, lineItemID).toPromise();
    if (response) {
      this.cart = response;
    } else {
      this.cart = null;
    }
    this.cartSubject.next(this.cart);
  }

  async saveOrder(order: any) {
    const updatedOrderResponse = await this.four51Service.createOrUpdateOrder(order).toPromise();
    this.cart = updatedOrderResponse.body;
    this.cartSubject.next(this.cart);
    this.four51Service.setCurrentOrder(this.cart.ID).toPromise();
  }

}
