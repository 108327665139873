class Cobrand {
  physicianId: string;
  name: string;
  cobrand: string;
}
const cobrands: Cobrand[] = [
  {
    physicianId: '898',
    name: 'Edwin Anderson',
    cobrand: 'Heritage Medical Associates'
  },
  {
    physicianId: '899',
    name: 'John Thompson',
    cobrand: 'Heritage Medical Associates'
  },
  /*{
    physicianId: '964',
    name: 'Bruce Hollinger',
    cobrand: 'Nashville Medical Group, An Affiliate of Baptist Hospital'
  },
  {
    physicianId: '1458',
    name: 'Karl Dannehl',
    cobrand: 'Piedmont Physicians'
  },
  {
    physicianId: '1467',
    name: 'Wyman Sloan',
    cobrand: 'Piedmont Physicians'
  },
  {
    physicianId: '1582',
    name: 'John Norton',
    cobrand: 'Mountain View Medical Group, P.C.'
  },*/
  {
    physicianId: '1764',
    name: 'James Jones',
    cobrand: 'Heritage Medical Associates'
  },
  {
    physicianId: '1814',
    name: 'Lee Fentriss',
    cobrand: 'Heritage Medical Associates'
  },
  {
    physicianId: '2104',
    name: 'Maurice Greenbaum',
    cobrand: 'North Shore Physicians Group'
  },
  {
    physicianId: '2107',
    name: 'Brenda Minor',
    cobrand: 'North Shore Physicians Group'
  }/*,
  {
    physicianId: '2111',
    name: 'John Szymanski',
    cobrand: 'North Shore Physicians Group'
  },
  {
    physicianId: '984', testing only
    name: 'Linda Groene',
    cobrand: 'Heritage Medical Associates'
  }*/
];

export { Cobrand, cobrands };
